import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';

// https://mdbootstrap.com/snippets/standard/mdbootstrap/2897359

export enum SettingsTypes {
    Banco = 'banco',
    MediosYConceptos = "medios_y_conceptos",
    Proveedores = 'proveedores',
    Cadenas = 'cadenas',
    Clientes = 'clientes',
    LineasNegocio = 'lineas_negocio',
    CategoriasYSubcategorias = "categorias_y_subcategorias",
    Usuarios= 'usuarios'
}

export enum SettingsTypesLabel {
    Banco = 'Bancos',
    MediosYConceptos = "Medios y Conceptos",
    Proveedores = 'Proveedores',
    Cadenas = 'Cadenas',
    Clientes = 'Clientes',
    LineasNegocio = 'Lineas de Negocio',
    CategoriasYSubcategorias = "Categorias y Subcategorias",
    Usuarios = 'Usuarios',
}

interface IProps {
    handleOptionSetting: any;
    selected: any;
}

const LeftMenu = ({ handleOptionSetting, selected }: IProps) => { // 
    const dataAccess = useSettingsStore((state) => (state.auth.jwtDecoded), shallow)
    return(
        <nav
        id="sidebarMenu"
        className="collapse d-lg-block sidebar collapse bg-white">
            <div className="position-sticky" style={{textAlign: "left"}}>
                <div className="list-group list-group-flush mx-3 mt-4">

                    { dataAccess?.access?.bancosSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.Banco && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.Banco)} >
                        <i className="fas fa-university fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.Banco} </span>
                      </button>}

                    { dataAccess?.access?.mediosSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.MediosYConceptos && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.MediosYConceptos)} >
                        <i className="fas fa-tachometer-alt fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.MediosYConceptos} </span>
                      </button>}

                    { dataAccess?.access?.proveedoresSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.Proveedores && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.Proveedores)} >
                        <i className="fas fa-users fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.Proveedores} </span>
                      </button>}

                    { dataAccess?.access?.cadenasSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.Cadenas && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.Cadenas)} >
                        <i className="fas fa-building fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.Cadenas} </span>
                      </button>}

                    { dataAccess?.access?.clientesSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.Clientes && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.Clientes)} >
                        <i className="fas fa-globe fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.Clientes} </span>
                    </button>}

                    { dataAccess?.access?.lineasDeNegocioSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.LineasNegocio && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.LineasNegocio)} >
                        <i className="fas fa-chart-bar fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.LineasNegocio} </span>
                    </button>}

                    { dataAccess?.access?.categoriasSubcategoriasCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.CategoriasYSubcategorias && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.CategoriasYSubcategorias)} >
                        <i className="fas fa-tachometer-alt fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.CategoriasYSubcategorias} </span>
                      </button>}

                    { dataAccess?.access?.usuariosSettingsCk && 
                      <button
                        className={`list-group-item list-group-item-action py-2 ripple ${selected === SettingsTypes.Usuarios && "active"}`}
                        onClick={() => handleOptionSetting(SettingsTypes.Usuarios)} >
                        <i className="fas fa-users fa-fw me-3"></i>
                        <span> {SettingsTypesLabel.Usuarios} </span>
                      </button>}

                    {/* <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple">
                        <i className="fas fa-chart-line fa-fw me-3"></i>
                        <span>Analytics</span>
                    </a>
                    <a href="#"
                    className="list-group-item list-group-item-action py-2 ripple">
                    <i className="fas fa-chart-pie fa-fw me-3"></i>
                    <span>SEO</span>
                    </a>
                    
                    <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple">
                        <i className="fas fa-chart-bar fa-fw me-3"></i>
                        <span>Orders</span>
                    </a>

                    <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple">
                        <i className="fas fa-globe fa-fw me-3"></i>
                        <span>International</span>
                    </a>

                    <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple">
                        <i className="fas fa-lock fa-fw me-3"></i>
                        <span>Partners</span>
                    </a>
                    <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple"
                        ><i className="fas fa-calendar fa-fw me-3"></i>
                        <span>Calendar</span></a>

                    <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple">
                        <i className="fas fa-chart-area fa-fw me-3"></i>
                        <span>Users</span></a>
        
                    <a href="#"
                        className="list-group-item list-group-item-action py-2 ripple">
                        <i className="fas fa-money-bill fa-fw me-3"></i><span>Sales</span></a> */}
                </div>
            </div>
        </nav>     

    );
}

export default LeftMenu; 
