import axios from '../../axiosApi';

export const authUser = async(data: any) => {
  // Enviar una petición POST
  try {
    const response = await axios({
        method: 'POST',
        url: '/signin',
        data,
    });

    return response.data;
  } catch (error) {
    return error
  }
}

export const settings = async(data: any) => {
  // Enviar una petición POST
  const response = await axios({
      method: 'POST',
      url: '/setting',
      data,
  });
  console.log("response > ", response)
  return response;
}

export const settingUser = async(data: any) => {
  // Enviar una petición POST
  const response = await axios({
      method: 'POST',
      url: '/settingUser',
      data,
  });
  console.log("response > ", response)
  return response;
}

export const settingMediosConceptosGet = async(type: string, subtype: string) => {
  const result = await axios({
    method: 'GET',
    url: `/setting-type-subtype/${type}/${subtype}`
  })

  return result?.data || [];
}

export const settingsGet = async(type: any) => {
  const result = await axios({
    method: 'GET',
    url: `/settings/${type}`
  })

  return result?.data || [];
}

export const settingsDelete = async(type: string, id: string) => {
  const result = await axios({
    method: 'DELETE',
    url: `/setting/${type}/${id}`
  })

  return result?.data || [];
}

export const proyectosGet = async(type: any) => {
  console.log("proyectosGet - type: ", type)
  const result = await axios({
    method: 'GET',
    url: `/proyectos/${type}`
  })

  return result?.data || [];
}

export const proyectoSave = async(data: any) => {
  // Enviar una petición POST
  const response = await axios({
      method: 'POST',
      url: '/proyecto',
      data,
  });
  console.log("response > ", response)
  return response;
}

export const transaccionSave = async(data: any) => {
  const response = await axios({
    method: 'POST',
    url: '/transaccion',
    data,
  });

  return response?.data || [];
}

export const transaccionImportSave = async(data: any) => {
  const response = await axios({
    method: 'POST',
    url: '/transaccionImportar',
    data,
  });

  return response?.data || [];
}

export const transaccionesGet = async(type: any) => {
  const result = await axios({
    method: 'GET',
    url: `/transacciones/${type}`
  })

  return result?.data || [];
}

// export const requisicionesGet = async(type: any) => {
//   const result = await axios({
//     method: 'GET',
//     url: `/proyectos/requisicion/${type}`
//   })

//   return result?.data || [];
// }

// export const requisicionSave = async(uidProyecto: string, data: any) => {
//   console.log("API - data: ", data)
//   const response = await axios({
//     method: 'POST',
//     url: `/proyecto/requisicionX/${uidProyecto}`,
//     data,
//   });

//   return response;
// }

export const reCalculateSaldoBanco = async(data: any) => {
  console.log("API - data: ", data)
  const response = await axios({
    method: 'POST',
    url: `/reCalculateSaldoBanco`,
    data,
  });

  return response;
}

export const transaccionDelete = async(data: any, id: string) => {
  const result = await axios({
    method: 'DELETE',
    url: `/transaccion/${id}`,
    data,
  })

  return result?.data || [];
}

export const presupuestosNames = async() => {
  console.log("presupuestos Names!! ")
  const result = await axios({
    method: 'GET',
    url: `/presupuestos/names`
  })

  return result?.data || [];
}

export const presupuestoGet = async(id: any) => {
  console.log("presupuestoGet - id: ", id)
  const result = await axios({
    method: 'GET',
    url: `/presupuesto/${id}`
  })

  return result?.data || [];
}

export const presupuestoSave = async(data: any) => {
  // Enviar una petición POST
  const result = await axios({
      method: 'POST',
      url: '/presupuesto',
      data,
  });
  console.log("result?.data > ", result?.data)
  return result?.data;
}

// https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/example_dynamodb_DeleteItem_section.html
