
import { useEffect, useMemo, useState } from "react";
import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";

import AddItemModal from "../settings/AddItemModal";
import { formatMoney, getStatusColors, removeItemOfObject } from "../general/utils";
import { StyleRow, StyledContainerConcepto } from "../general/StylesUtils";
import DataTableCustom from "../general/DataTableCustom";
import { TableColumn } from "react-data-table-component";
import _isEqual from 'lodash/isEqual';
import AddNewFormRequisicion from "./AddNewFormRequisicion";
import { IPropsRequisicion } from "../../store/settingsStore";
import { ButtonProps } from "mdb-react-ui-kit/dist/types/free/components/Button/types";
import DatosRequisicionesHeader from "./DatosRequisicionesHeader";

type DataRowRequisicion = {
  proveedorLabel: string;
  cantidadRequisicion: string;
  repeticion: string;
  costoRequisicion: string;
  repeticionRequisicion: string;
};

const AddRequisicion = ({ showModal: showModalRequisicion = false, typeAction, setShowModal: setShowModalRequisicion,  handleSave }: IPropsRequisicion) => {
  const [showModal, setShowModal] = useState(false);
  const [typeActionRequisicion, setTypeActionRequisicion] = useState<any>(null)
  const [dataArray, setDataArray] = useState<any[]>([])
  const [totalRequisicionInfo, setTotalRequisicionInfo] = useState("")
  console.log("AddRequisicion-typeAction >> ", typeAction)
  useEffect(() => {
    const requisiciones = typeAction.data?.requisiciones || []

    if (requisiciones.length) {
      setDataArray(requisiciones)
    }

    const {cantidad, repeticion, precioUnitario } = typeAction.data
    const total = formatMoney(Number(cantidad)* Number(repeticion) * Number(precioUnitario))
    setTotalRequisicionInfo(total)

  }, [typeAction.data])

  const cantidadTotal = useMemo(() => {
    const tot = dataArray.reduce((acc, item) => acc + Number(item.cantidadRequisicion), 0);
    const max = typeAction.data.cantidad - tot;

    return max <= 0 ? 0 : max;
  }, [dataArray])

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    setShowModal(true);
    // setTypeAction({ typeName, option, data })
    const totalData = { cantidadTotal, totalUntilNow, totalRequisicionInfo }
    if (!data) {
      setTypeActionRequisicion({...typeAction, option, totalData })
    } else {
      setTypeActionRequisicion({ ...typeAction, option, totalData, data: {...typeAction.data, ...data } })
    }
  }

  const handleDelete = (data: any) => {
    console.log("DELETE > data.cantidadRequisicion: ", data.cantidadRequisicion)

    const newData = dataArray.filter((d) => !_isEqual(d, data))

    setDataArray(newData)
  }

  const updateRequisicionList = (data: any) => {
    return dataArray.map((items) => {
      const newI = items.idRequisicion === data.idRequisicion ? {...data, statusRequisicion: data.statusRequisicion } : items
      console.log("====>>> ", { newI, itemIdR: items.idRequisicion, dataIdR: data.idRequisicion })
      return newI
    } )
  }

  const updateStatusRequisicion = (otherdata: any, data: any) => {
    return dataArray.map((items) => {
      const newI = items.idRequisicion === data.idRequisicion ? {...data, statusRequisicion: otherdata.statusRequisicion } : items
      console.log("====>>> ", { newI, itemIdR: items.idRequisicion, dataIdR: data.idRequisicion })
      return newI
    } )
  }

  const handleSaveRequisicion = (option: string, data: any, otherdata: any) => {
    console.log("---->>> 145 - option: ", option)
    console.log("->>> 145 - data: ", data)
    console.log("->>> 145 - otherdata: ", otherdata)

    if (option === "Editar") {
      // Revisar esta opcion con Detenimiento....
      const newDataArray = updateRequisicionList(removeItemOfObject("uidProyecto", data))
      console.log("newDataArray > ", newDataArray)
      setDataArray(newDataArray)
    } else {
      console.log("[88] ---------------------- ")
      const newDataArray = otherdata ? updateStatusRequisicion(otherdata, typeActionRequisicion?.data) : dataArray
      setDataArray([...newDataArray, data])
    }
  }

  const columns: TableColumn<DataRowRequisicion>[] = [
    {
      name: "Proveedor",
      selector: (row: { proveedorLabel: any; }) => row.proveedorLabel,
      sortable: true
    },
    {
      name: "Cantidad",
      selector: (row: { cantidadRequisicion: any; }) => row.cantidadRequisicion,
      sortable: true
    },
    {
      name: "Repeticion",
      selector: (row: { repeticionRequisicion: any; }) => row.repeticionRequisicion,
      sortable: true
    },
    {
      name: "Costo Unitario",
      selector: (row: { costoRequisicion: any; }) => row.costoRequisicion,
      sortable: true
    },
    {
      name: "Total",
      selector: (row: { cantidadRequisicion: any, repeticionRequisicion: any, costoRequisicion: any; }) => {
        const { cantidadRequisicion, repeticionRequisicion, costoRequisicion} = row

        return formatMoney(cantidadRequisicion * repeticionRequisicion * costoRequisicion)
      },
      sortable: true
    },
    {
      name: "Status",
      cell: (row: any) => {
        const lastStatus = row.statusRequisicion[row.statusRequisicion.length - 1]
        // console.log("++++> STATUS > row-lastStatus: ", lastStatus)
        const statusCustom = lastStatus?.status || "solicitado" // row?.terminado ? "terminado" : row.status
        const colorStyle: ButtonProps["color"] = getStatusColors(statusCustom)

        return (
          <MDBBtn rounded style={{ padding: "5px 12px", fontSize: "10.5px"}} color={colorStyle}>{statusCustom}</MDBBtn>
        )
      },
      sortable: true,
    }, 
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    },
  ];

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("requisicion", "Editar", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handleDelete(data)}>
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  const addButton = () => {
    return (
      <MDBBtn className='ms-2' tag='a' color='secondary' floating  
        disabled={ cantidadTotal === 0 }
        onClick={() => {
          handlItemAction("requisicion", "Nuevo")
        }} >
            <MDBIcon fas icon='plus' size='sm' />
      </MDBBtn>)
  }

  const getAddButton = () => {
    return cantidadTotal === 0
      ? <div className="txt-align-right">
          <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title="La cantidad llego al total de requisiciones">
          { addButton() }
          </MDBTooltip>
        </div>
      : <>{ addButton() }</>
  }

  const totalUntilNow = useMemo(() => {
    // console.log("dataArray >> ", dataArray)
    const sumaTotal = dataArray.reduce((total, item) => {
      const resultado = Number(item.cantidadRequisicion) * Number(item.repeticionRequisicion) * Number(item.costoRequisicion);
      return total + resultado;
    }, 0);
    return formatMoney(sumaTotal) || 0.00
  },[dataArray])

  const contentForm = () => {
    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}}>

        <DatosRequisicionesHeader {...{ cantidadTotal, totalUntilNow, totalRequisicionInfo, data: typeAction.data }} />
        {/* <h5>Datos de Requisiciones</h5>
        <div className="text-start p-3">
          <div><label className="fw-bold">Medio:</label> {typeAction.data?.medioLabel}</div>
          <div><label className="fw-bold">Concepto:</label> {typeAction.data?.conceptoLabel}</div>
          <div><label className="fw-bold">Requisiciones Cantidad:</label> {Number(typeAction.data?.cantidad) - cantidadTotal} / {typeAction.data?.cantidad} </div>
          <div><label className="fw-bold">Costo Total:</label> { totalUntilNow } / { totalRequisicionInfo } </div>
        </div> */}
        <>
          <StyleRow className="d-flex justify-content-end">
            { getAddButton() }
          </StyleRow>  
          <DataTableCustom 
            keyDataTable="table-contactos" 
            columns={columns} 
            data={dataArray} 
          />
          {showModal && <AddNewFormRequisicion cantidad={cantidadTotal} showModal={showModal} setShowModal={setShowModal} typeAction={typeActionRequisicion} handleSave={handleSaveRequisicion} />}
        </>
      </StyledContainerConcepto>
    )
  }

  const handleSaveData = async() => {
    handleSave("Editar", { ...typeAction.data, requisiciones: dataArray } )

    setShowModalRequisicion(!showModalRequisicion)
  }

  return (
    <AddItemModal keyModal="modal-servicio" typeAction={typeAction} showModal={showModalRequisicion} setShowModal={setShowModalRequisicion} handleSave={handleSaveData} >
      { contentForm() }
    </AddItemModal>
    )
}

  export default AddRequisicion;
