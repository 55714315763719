import { useEffect, useRef, useState } from "react"
import { TableColumn } from "react-data-table-component"
import ReactToPrint from 'react-to-print';
import { MDBAccordion, MDBAccordionItem, MDBBtn, MDBContainer, MDBIcon } from "mdb-react-ui-kit"
// import styled from "@emotion/styled"
import { ButtonProps } from "mdb-react-ui-kit/dist/types/free/components/Button/types"
import HeaderContainer from "../general/HeaderContainer"
import SearchContainer from "../general/SearchContainer"
import DataTableCustom from "../general/DataTableCustom"
import { formatMoney, getRequisicionTotal, getStatusColors, getTotal, requisicionStatus } from "../general/utils"
import { ICotization, IRequisicion } from "../../store/settingsStore"
import { proyectoSave, proyectosGet } from "../settings/api"
import AddNewFormRequisicion from "../cotizaciones/AddNewFormRequisicion"
// import { requisicionSave } from "../settings/api";
import TooltipNewCustom from "../general/tooltipNewCustom"
import ComponentToPrint from "./ComponentToPrint";
import AddItemModal from "../settings/AddItemModal";
import AddRequisicion from "../cotizaciones/AddRequisicion";

// const StyledContainerMedio = styled(MDBCol)`
//     border: thin solid #cacaca;
//     border-radius: 5px 5px 0 0;
//     margin-bottom: 5px;
//     padding: 5px;

//     & h5 {
//       margin: 5px;
//     }
// `;

type DataRow = {
  descripcion: string;
  monto: string;
  transaccion: string;
  categoriaLabel: string;
  subcategoriaLabel: string;
  bancoLabel: string;
  updatedAt: number;
};

const ComponentToPrintWrapper = ({ data }: { data: any }) => {
  const componentRef = useRef<HTMLDivElement>(null)
console.log("ComponentToPrintWrapper - data: ", data)
  return (
    <>
      <ReactToPrint
        trigger={() => 
          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px', overflow: "initial" }} onClick={() => console.log("print")} >
            <MDBIcon fas icon='print' size='sm' />
          </MDBBtn>
        }
        content={() => componentRef.current}
      />
      <div className='printTemplate' ref={ componentRef } >
        <ComponentToPrint printData={data}  />
      </div>
      {/* <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px', overflow: "initial" }} onClick={() => console.log("print > ", data)} >
        <MDBIcon fas icon='print' size='sm' />
      </MDBBtn> */}
    </>
  )
}

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  // const [optionsConceptos, setOptionsConceptos] = useState([])
  const [showModalRequisicion, setShowModalRequisicion] = useState(false);
  const [typeActionSolicitar, setTypeActionSolicitar] = useState({})
  const [alert, setAlert] = useState(null)
  const [saving, setSaving] = useState(false)
  const [items, setItems] = useState<any[]>(typeAction.data.items)
  // const [formValue, setFormValue] = useState({
  //   id: null,
  //   medio: '',
  //   concepto: '',
  //   cantidad: '',
  //   repeticion: '',
  //   precioUnitario: '',
  //   precioVenta: '',
  //   porcentaje: '30'
  // });
  console.log("EDIT > typeAction.data: ", typeAction.data)

  const handlItemActionRequisicion = (typeName: string, option: string, data?: any) => {
    console.log(" + typeName: ", typeName)
    console.log(" + option: ", option)
    console.log(" + data: ", data)

    setShowModalRequisicion(true)
    setTypeActionSolicitar({ typeName, option, data })
  }

  const actionsDataTable = (data: any) => {
    return ( 
      <MDBBtn className='ms-2' tag='a' color='secondary' floating  
        onClick={() => {
          handlItemActionRequisicion("Requisicion", "", data)
        }} >
        <MDBIcon fas icon='plus' size='sm' />
      </MDBBtn>
    )
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Medio",
      cell: (row: any) => (<span className="fw-bold">{ row.medioLabel} </span> ),
      sortable: true
    },
    {
      name: "Concepto",
      cell: (row: any) => (<span className="fw-bold">{ row.conceptoLabel } </span> ),
      sortable: true
    },
    {
      name: "Cantidad",
      selector: (row: any) => row.cantidad,
      sortable: true,
    }, 
    {
      name: "Repeticion",
      selector: (row: any) => row.repeticion,
      sortable: true,
    }, 
    {
      name: "P.U. Costo",
      selector: (row: any) => formatMoney(row.precioUnitario),
      sortable: true,
    }, 
    { 
      name: 'Total', 
      cell: (row: any) => {
      const { cantidad, repeticion, precioUnitario } = row
      return ( <span className="fw-bold">{ getTotal(cantidad, repeticion, precioUnitario) } </span> )
    }},
    // { 
    //   name: 'Porcenjate', 
    //   selector: (row: any) => {
    //     const { porcentaje, precioUnitario: costo, precioVenta } = row

    //     return `${ porcentaje }%` 
    // }},
    // { 
    //   name: 'P.U. Venta', 
    //   selector: (row: any) => {
    //     const { precioVenta } = row
    //     return formatMoney(precioVenta)
    // }},
    // { 
    //   name: 'Total Venta', 
    //   cell: (row: any) => {
    //     const { cantidad, repeticion, precioVenta } = row //  precioUnitario, porcentaje
    //     return  (<span className="fw-bold"> { "0" /*getTotal(cantidad, repeticion, precioVenta)*/ } </span> )
    // }},
    { 
      name: 'Costo Real', 
      selector: (row: any) => {
        //formatMoney(row.costoReal) || "-"
        return formatMoney(getRequisicionTotal(row.requisiciones))
    },
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ];

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        
        setShowModal(!showModal)
        handleSave()
        
        // window.location.href = `/cotizaciones`
      },1500 )
    }
  }

  const handleSaveData = async() => {
    console.log("SAVE el proyecto - items: ", items)
    try {
      setSaving(true)
        const { status, name, cadena, uid } = typeAction.data

        const payload = {
          type: status,
          name,
          uidMedio: cadena,
          uid ,
          detalles: { ...typeAction.data, items },
        }
        // console.log("payload:: ", payload)
        await proyectoSave(payload)
        showAlert({
          title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }    
  }
  const handleSaveDataSolicitud = async(option: string, data: any) => {
    console.log("[557] - handleSave-data:: ", data) // === typeAction.data
    if(option === 'Nuevo')
    setItems([...items, data])
  else
    setItems( items.map((items) => {
      console.log("==>> SAVE: ", {itemId: items.id, dataId: data.id })
      return items.id === data.id ? data : items
    } ) )
  }

  const constentForm = () => {
    return (
      <div className="flex-grow-1">
        <DataTableCustom keyDataTable="table-servicios" columns={columns} data={typeAction.data.items} sortFieldId={0} sortAsc={false} />

        {showModalRequisicion && <AddRequisicion showModal={showModalRequisicion} setShowModal={setShowModalRequisicion} typeAction={typeActionSolicitar} handleSave={handleSaveDataSolicitud} /> }
      </div>
    )
  }

  return (
    <AddItemModal
      keyModal="modal-lineadenegocio"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={false}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const Requisiciones = () => {
  const [proyectosGanadosServer, setProyectosGanadosServer] = useState<ICotization[]>([])
  const [proyectosGanadosActivos, setProyectosGanadosActivos] = useState<any[]>([])
  const [showModalRequisicion, setShowModalRequisicion] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [statusData, setStatusData] = useState<any>(null)
  const [typeAction, setTypeAction] = useState({})
  const [proyectosAbiertos, setProyectosAbiertos] = useState<string[]>([]);

   const toggleProyecto = (uid: string) => {
      if (proyectosAbiertos.includes(uid)) {
         setProyectosAbiertos(proyectosAbiertos.filter((id) => id !== uid));
      } else {
         setProyectosAbiertos([...proyectosAbiertos, uid]);
      }
   };

  const getServerData = async() => {
    // 
    const result = await proyectosGet("requisiciones")
    console.log("GET - DATA >> result: ", result)
    setProyectosGanadosServer(result)
    setProyectosGanadosActivos(result)
  }
  
  useEffect(() => {      
    getServerData()
  }, [])

  const handlItemActionRequisicion = (typeName: string, option: string, data?: any) => {
    console.log(" + typeName: ", typeName)
    console.log(" + option: ", option)
    console.log(" + data: ", data)

    setShowModalRequisicion(true)
    setTypeAction({ typeName, option, data })
  }
    
  const actionsDataTable = (data: any) => {
    console.log("======>>> actionsDataTable - data: ", data)
    return (
      <div style={{ display: 'inline', flexDirection: 'row',
        flexWrap: 'nowrap',
        position: 'absolute',
        top: '15px' }} >
  
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px' }}         
          onClick={() => {
          // setMedioParent(medio)
          handlItemActionRequisicion("Requisicion", "", data)
        }} >
          <MDBIcon fas icon='clipboard-list' size='lg' />
        </MDBBtn>

        <ComponentToPrintWrapper data={data} />
      </div>
    )
  }
  
  const handleSave = async (option: any, requisicionData: any) => {
      const { uidProyecto, proyecto, idItem, idRequisicion, statusRequisicion } = requisicionData
      console.log("-----index > option: ", option)
      console.log("index > requisicionData: ", requisicionData)

      //// requisicionesServer?.map(({name, uidProyecto, requisiciones}) => {
      /*const newData = requisicionesServer?.map((data) => {
        if (data.uidProyecto === uidProyecto) {
          const newRequisiciones = data.requisiciones.map((items) => {
            return items.idRequisicion === requisicionData.idRequisicion ? requisicionData : items
          })

          return { ...data, requisiciones: newRequisiciones }
        }

        return data
      })*/

      // old version - separando la requisicion:
      // const newData = {
      //   idRequisicion: requisicionData.idRequisicion,
      //   detalles: {...requisicionData}
      // }
      // console.log("SAVE-newData-Requisiciones: ", newData)
      // requisicionSave(requisicionData.idRequisicion, newData)

      const newItems = proyecto.items.map((item: any) => {
        if (item.id === idItem) {
          const newRequisiciones = item.requisiciones.map((requisicion: any) => {
            const newStatusRequisicion = requisicion.idRequisicion === idRequisicion ? {statusRequisicion} : {}
            return {...requisicion, ...newStatusRequisicion}
          })
          return {...item, requisiciones: newRequisiciones }
        } 
        return item
      })
console.log("+++newItems: ", newItems)
      const payload = {
        type: proyecto.status,
        name: proyecto.name,
        uidMedio: proyecto.cadena,
        uid: proyecto.uid,
        detalles: { ...proyecto, items: newItems },
      }
console.log("payload:: ", payload)
      await proyectoSave(payload)

      getServerData()
  }
    
  const columns: TableColumn<IRequisicion>[] = [
      {
        name: "Proveedor",
        selector: (row: { proveedorLabel: any; }) => row.proveedorLabel,
        sortable: true,
      }, 
      {
        name: "Cantidad",
        selector: (row: { cantidadRequisicion: any; }) => row.cantidadRequisicion,
        sortable: true,
      }, 
      {
        name: "Repeticion",
        selector: (row: { repeticionRequisicion: any; }) => row.repeticionRequisicion,
        sortable: true,
      }, 
      {
        name: "Costo",
        selector: (row: { costoRequisicion: any; }) => formatMoney(row.costoRequisicion),
        sortable: true
      },
      {
        name: "Total",
        selector: (row: { cantidadRequisicion: any, repeticion: any, costoRequisicion: any; }) => {
          const { cantidadRequisicion, repeticion, costoRequisicion} = row
  
          return formatMoney(cantidadRequisicion * repeticion * costoRequisicion)
        },
        sortable: true
      },
      {
        name: "Status",
        cell: (row: any) => {
          const lastStatus = row.statusRequisicion[row.statusRequisicion.length - 1]
          console.log("STATUS > row-lastStatus: ", lastStatus)
          const statusCustom = lastStatus?.status || "solicitar" // row?.terminado ? "terminado" : row.status
          const colorStyle: ButtonProps["color"] = getStatusColors(statusCustom)
  
          return (
            <MDBBtn rounded style={{ padding: "5px 12px"}} color={colorStyle}>{statusCustom}</MDBBtn>
          )
        },
        sortable: true,
      }, 
      {
        name: "",
        button: true,
        cell: (row: any) => actionsDataTable(row),
        right: true
      }
  ];
  const resetData = () => {
    setProyectosGanadosActivos(proyectosGanadosServer)
  }

  const setNewData = (data: any[]) => {
    // console.log("setNewData >> ", data)
    setProyectosGanadosActivos(data)
  }

  const getRequisicionesDisplay = (requisiciones: any[]) => {
    if (!statusData || !requisiciones) return requisiciones || []
// console.log("-------requisiciones: ", requisiciones)
    return requisiciones.filter((item: any) => {
      const statusRequisicion = item.statusRequisicion || [];
      const ultimoStatus = statusRequisicion[statusRequisicion.length - 1];
      return ultimoStatus && ultimoStatus.status === statusData
    })
  }

  const handlItemAction = (typeName:string, type: string, data: any) => {
    setTypeAction({ typeName, type, data })

    setShowModal(true)
  }
  
  // console.log("==>> proyectosGanadosActivos: ", proyectosGanadosActivos)
  // console.log("--STATUSDATA > ", statusData)
  console.log("MODALS > ", { showModal, showModalRequisicion})
  return (
    <MDBContainer>
      <HeaderContainer title={`Requisiciones`} ></HeaderContainer>
      <SearchContainer dataServer={proyectosGanadosServer} optionsSelect={requisicionStatus} setNewData={setNewData} resetData={resetData} textItem="name" setStatusData={(value: string) => {
        // console.log("199 - value: ", value)
        setStatusData(value)}} />

      <TooltipNewCustom />
  
      <MDBAccordion alwaysOpen>
        { proyectosGanadosActivos?.map((proyecto, index) => {
            const { name, uid, items } = proyecto;

            return (
              <MDBAccordionItem key={uid} collapseId={uid} headerTitle={name} isOpen={proyectosAbiertos.includes(uid)}>
                <div className="d-flex justify-content-end">
                  <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("requisicion", "Nuevo", proyecto)}>+ Agregar Requisicion</MDBBtn>
                </div>
                { items.map((data: any) => {
    // console.log("+ data.medioLabel: ", data.medioLabel)
    //   console.log("++ data.requisicones: ", data?.requisiciones)
                    const newRequisiciones = getRequisicionesDisplay(data?.requisiciones)
                    // console.log("newRequisiciones >> ", newRequisiciones)
                    return (
                      newRequisiciones.length > 0 && 
                        <>
                          <div> {`${data.medioLabel} > ${data.conceptoLabel}` } </div>
                          <div>
                            <DataTableCustom keyDataTable="table-requisiciones" columns={columns} data={ newRequisiciones.map((items: any) => ({...items, proyecto, idItem: data.id})) } />
                          </div>
                        </>
                    )
                  })
                }
              </MDBAccordionItem>
            );
        })}
      </MDBAccordion>

      { showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}
      { showModalRequisicion && 
        <AddNewFormRequisicion cantidad={0} displayData={false} showModal={showModalRequisicion} setShowModal={setShowModalRequisicion} typeAction={typeAction} handleSave={handleSave} isUpdateStatus={true} /> }
    </MDBContainer>
  );


  // {proyectosGanadosActivos?.map((proyecto) => {
  //   const {name, uid, items} = proyecto
  //   console.log("--->> proyecto: ", proyecto)
  //   return (      
  //     <MDBRow key={`row-medio-${uid}`} style={{marginBottom: "15px", marginLeft: "20px" }}>
  //       <StyledContainerMedio size='11' style={{ textAlign: "left", marginBottom: "0" }} className="d-flex justify-content-between">
  //         <div><h5> { name } </h5></div>
  //         <div className="d-flex align-items-center">
  //           <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("requisicion", "Nuevo", proyecto)}>+ Agregar</MDBBtn>
  //           <div className="me-3 mt-1">
  //               <MDBIcon fas icon='angle-down' size='lg' onClick={() => {}} />
  //           </div>
  //         </div>
  //       </StyledContainerMedio>
  //       <MDBCol size='11' style={{ backgroundColor: "#cacaca"}}> 
  //       { items.map((data: any) => {
  //         // console.log("+ data.medioLabel: ", data.medioLabel)
  //         //   console.log("++ data.requisicones: ", data?.requisiciones)
  //           const newRequisiciones = getRequisicionesDisplay(data?.requisiciones)
  //           // console.log("newRequisiciones >> ", newRequisiciones)
  //           return (
  //             newRequisiciones.length > 0 && 
  //               <>
  //                 <div> {`${data.medioLabel} > ${data.conceptoLabel}` } </div>
  //                 <div>
  //                   <DataTableCustom keyDataTable="table-requisiciones" columns={columns} data={ newRequisiciones.map((items: any) => ({...items, proyecto, idItem: data.id})) } />
  //                 </div>
  //               </>
  //           )
  //         })
  //       }
        
  //       </MDBCol>
  //     </MDBRow>
  //   )
  // })}
}

export default Requisiciones;
  