import React, { useEffect, useState } from 'react';
import DataTableCustom from './DataTableCustom';
import { MDBBtn, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import styled from '@emotion/styled';
import AddItemModal from '../settings/AddItemModal';

const StyleRow = styled(MDBRow)`
  margin: 0 10px;
`;

interface IPropsForm {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
  dataForm: any[];
}

interface IProps {
  columns: any[];
  headers: any[];
  dataDefault: any[];
  handleSaveContactos: any;
}
let idValue = 1;

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave, dataForm = [] }: IPropsForm) => {
  const [formValue, setFormValue] = useState(typeAction?.data || {})

  const handleSaveData = () => {
    const {option} = typeAction

    handleSave(option,  {...formValue, id: formValue?.id || idValue++})
    setShowModal(!showModal)
  }

  const onChange =(e: any) => {
    const { name, value } = e.target

    setFormValue({ ...formValue, [name]: value });
  }

  const contentForm = () => {
    return (
       dataForm.map((item) => {
        return (
          <MDBInput type='text' label={item?.label} name={item?.name} value={formValue?.[item?.name] || ""} onChange={onChange} required={item?.required} />
        )
      })
    )
  }

  return (
    <AddItemModal keyModal="modal-contacto" typeAction={typeAction} showModal={showModal} setShowModal={setShowModal} handleSave={handleSaveData} centeredDialog >
      { contentForm() }
    </AddItemModal>
  )
}

const DatosContactoDataTable = ({ columns, headers, dataDefault, handleSaveContactos }: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [typeAction, setTypeAction] = useState({})

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    setShowModal(true);
    setTypeAction({ typeName, option, data })
  }

  const handleSave = async(option: string, data: any) => {
    const newData = (option === 'Nuevo') ? 
                    [...dataDefault, data] : 
                    dataDefault.map((items) => items.id === data.id ? data : items )

    handleSaveContactos({name: 'contactos', value: newData})
  }

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("constacto", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }}>
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  return (
    <>
      <StyleRow end>
        <MDBBtn className='ms-2' tag='a' color='secondary' floating  
          onClick={() => {

            handlItemAction("contacto", "Nuevo")
          }} >
          <MDBIcon fas icon='plus' size='sm' />
        </MDBBtn>
      </StyleRow>  
      <DataTableCustom 
        keyDataTable="table-contactos" 
        columns={[
          ...columns, 
          {
            name: "",
            button: true,
            cell: (row: any) => actionsDataTable(row),
            right: true
          }
        ]} 
        data={dataDefault} 
      />
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} dataForm={headers} ></AddNewForm>}
    </>
  );
}

export default DatosContactoDataTable;
