// import { transaccionSave, proyectoSave, authUser } from './../components/settings/api';
import {create, StateCreator} from "zustand"
import { devtools, persist } from 'zustand/middleware';
import { proyectosGet, settingMediosConceptosGet, settingsGet, transaccionesGet, authUser } from "../components/settings/api";

export type StatusInfo = {
  status: string;
  date: string;
}
interface IAuth {
  jwtDecoded?: any;
  jwt?: string;
  error?: boolean;
}

interface IMedios {
  name: string;
  uid: string;
  conceptos?: any[];
  subcategorias?: any[];
}

interface ICategorias {
  name: string;
  uid: string;
  subcategorias?: any[];
}

interface ICustomSetting {
  name: string;
  uid: string;
  detalles: any;
}

interface ICustomCadenaSetting {
  name: string;
  uid: string;
  costos: any;
}

interface IUser {
  name: string; 
  uid: string; 
  userEjecutivo: boolean; 
  userName: string;
}

export interface ICotization {
  cadena: string;
  cliente: string;
  items: any[];
  lineaNegocio: string;
  name: string;
  status: string;
  uid: string | null;
  user: string;
  descripcion: string;
  statusInfo?: StatusInfo[];
  terminado: boolean;
}

export interface ITransaccion {
  bancoLabel: any;
  banco: string;
  comentario: string;
  descripcion: string;
  uid: string | null;
  fecha: string;
  monto: string;
  categoria: string;
  categoriaLabel: any;
  subcategoria: string;
  subcategoriaLabel: any;
  transaccion: string;
  transaccionLabel: any;
  updatedAt: any;
}

export interface IRequisicionesOnProyect {
  name: string;
  uid: string;
  requisiciones?: IRequisicion[];
  // name: string;
  // proveedor: string;
  // proveedorLabel: string;
  // cantidadRequisicion: string;
  // costoRequisicion: string;
  // repeticion: string;
}
export interface IRequisicion {
  idRequisicion?: string;
  name: string;
  proveedor: string;
  proveedorLabel: string;
  medio: string; 
  medioLabel: string;
  concepto: string; 
  conceptoLabel: string; 
  cantidadRequisicion: string;
  costoRequisicion: string;
  repeticion: string;
  repeticionRequisicion: string;
  uidProyecto?: string;
  nameProyecto?: string;
}

interface DatosSelector {
  label: string;
  value: string;
  conceptos?: any[];
  subcategorias?: any[];
}

interface DataState {
    auth: IAuth,
    medios: IMedios[];
    mediosSelect: DatosSelector[];    
    categorias: IMedios[];
    categoriasSelect: DatosSelector[];
    clientesSelect: DatosSelector[];
    cadenasSelect: DatosSelector[];
    lineasNegocioSelect: DatosSelector[];
    proveedoresSelect: DatosSelector[];
    usersEjecutivosSelect: DatosSelector[];
    cadenas: ICustomCadenaSetting[];
    proveedores: ICustomSetting[];
    clientes: ICustomSetting[];
    bancos: ICustomSetting[];
    bancosSelect: DatosSelector[];
    lineasnegocio: ICustomSetting[];
    proyectos: ICotization[];
    transacciones: ITransaccion[];
    requisiciones: IRequisicionesOnProyect[]
    users: IUser[]
    getUserAuthServer: (data: any) => Promise<void>;
    getMediosServer: () => Promise<void>;
    getCadenasServer: () => Promise<void>;
    getProveedoresServer: () => Promise<void>;
    getClientesServer: () => Promise<void>;
    getBancosServer: () => Promise<void>;
    getLineasNegocioServer: () => Promise<void>;
    getCategoriasServer: () => Promise<void>;
    getProyectosServer: () => Promise<void>;
    getTransaccionesServer: () => Promise<void>;
    getRequisicionesServer: () => Promise<void>;
    getUsersServer: () => Promise<void>;
    logout: () => void;
}
export interface IPropsRequisicion {
  cantidad?: number;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any, totalData?: any };
  handleSave: any;
  displayData?: boolean;
  isUpdateStatus?: boolean;
}

// export const useSettingsStore = create<DataState>(
//   (set) => ({
const stateCreatorData: StateCreator<DataState> = (set) => ({  
  auth: {},
  medios: [],
  mediosSelect: [],
  categorias: [],
  categoriasSelect: [],
  clientesSelect: [],
  cadenasSelect: [],
  bancos: [],
  bancosSelect: [],
  lineasNegocioSelect: [],
  proveedoresSelect: [],
  usersEjecutivosSelect: [],
  cadenas: [],
  proveedores: [],
  clientes: [],
  lineasnegocio: [],
  proyectos: [],
  transacciones: [],
  requisiciones: [],
  users: [],
  getUserAuthServer: async(data: any) => {
    const auth = await authUser(data)
    const dataAuth = (auth.jwt) ? {auth} : {auth: { error: true }}

    set(state => ({
      ...state,
      ...dataAuth,
    }))
    
  },
  getMediosServer: async() => {
    const medios = await settingMediosConceptosGet("medio", "concepto")

    const mediosSelect = medios.map(({name, uid, conceptos}: IMedios) => {
      const conceptosSeletc = conceptos?.map(({name, uid}: IMedios) => ({label: name, value: uid}) )

      return ({label: name, value: uid, conceptos: conceptosSeletc })
    })

    set(state => ({
      ...state,
      medios,
      mediosSelect,
    }))
  },
  getCadenasServer: async() => {
    const cadenas = await settingsGet("cadena")

    const cadenasSelect = cadenas.map(({name, uid}: IMedios) => {
      return ({label: name, value: uid })
    })    

    set(state => ({
      ...state,
      cadenas,
      cadenasSelect,
    }))
  },
  getProveedoresServer: async() => {
    const proveedores = await settingsGet("proveedor")
    const proveedoresSelect = proveedores.map(({name, uid}: IMedios) => {
      return ({label: name, value: uid })
    })  

    set(state => ({
      ...state,
      proveedores,
      proveedoresSelect,
    }))
  },
  getUsersServer: async() => {
    const users = await settingsGet("user")
    const usersEjecutivosSelect: { label: string; value: string; }[] = []
    const usersSelect = users.map(({name, uid, userEjecutivo, userName}: IUser) => {
      if (userEjecutivo) {
        usersEjecutivosSelect.push({label: userName, value: uid })
      }
      return ({label: name, value: uid })
    })

    set(state => ({
      ...state,
      users,
      usersSelect,
      usersEjecutivosSelect,
    }))
  },
  getClientesServer: async() => {
    const clientes = await settingsGet("cliente")

    const clientesSelect = clientes.map(({name, uid}: IMedios) => {
      return ({label: name, value: uid })
    })

    set(state => ({
      ...state,
      clientes,
      clientesSelect,
    }))
  },
  getBancosServer: async() => {
    const bancos = await settingsGet("banco")

    const bancosSelect = bancos.map(({name, uid}: IMedios) => {
      return ({label: name, value: uid })
    })  

    set(state => ({
      ...state,
      bancos,
      bancosSelect,
    }))
  },
  getLineasNegocioServer: async() => {
    const lineasnegocio = await settingsGet("lineanegocio")

    const lineasNegocioSelect = lineasnegocio.map(({name, uid}: IMedios) => {
      return ({label: name, value: uid })
    })  

    set(state => ({
      ...state,
      lineasnegocio,
      lineasNegocioSelect,
    }))
  },
  getCategoriasServer: async() => {
    const categorias = await settingMediosConceptosGet("categoria", "subcategoria")

    const categoriasSelect = categorias.map(({name, uid, subcategorias}: ICategorias) => {
      const subcategoriasSelect = subcategorias?.map(({name, uid}: ICategorias) => ({label: name, value: uid}) )

      return ({label: name, value: uid, subcategorias: subcategoriasSelect })
    })

    set(state => ({
      ...state,
      categorias,
      categoriasSelect,
    }))
  },
  getProyectosServer: async() => {
    const proyectos = await proyectosGet("all")

    set(state => ({
      ...state,
      proyectos,
    }))
  },
  getTransaccionesServer: async() => {
    const transacciones = await transaccionesGet("all")

    set(state => ({
      ...state,
      transacciones,
    }))
  },
  getRequisicionesServer: async() => {
    const requisiciones = await proyectosGet("requisiciones")
                        //await requisicionesGet("all")

    set(state => ({
      ...state,
      requisiciones,
    }))
  },
  logout: () => set({ auth: {} }),
})

export const useSettingsStore = create<DataState>()(
  persist(
      devtools((...a) => ({
          ...stateCreatorData(...a),
      })),
      { name: "myStoreData" },
  )
)