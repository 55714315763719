import moment from "moment"
import { proyectoSave } from "../settings/api"

export const updateProyectoIngreso = async(idTransaccion: string, monto: any, proyectoNow: any) => {
    const proyecto = proyectoNow // proyectosGanados.find(({uid}) => uid === formValue.proyecto)
    //console.log("--->> proyecto: ", proyecto)
    if (!proyecto) return
    const pago = { date: moment().valueOf(), monto, idTransaccion }
    const updateProyecto = [ ...[...proyecto?.pagos || [] ], { ...pago } ]

    const payload = {
      type: proyecto.status,
      name: proyecto.name,
      uidMedio: proyecto.cadena,
      uid: proyecto.uid,
      detalles: {...proyecto, pagos: updateProyecto },
    }
    await proyectoSave(payload)
  }
  export const updateProyectoEgreso = async(idTransaccion: string, idRequisicion: string, monto: any, proyectoNow: any) => {
    const proyecto = proyectoNow // proyectosGanados.find(({uid}) => uid === formValue.proyecto)
    if (!proyecto) return
    // Encontrar el item que contiene la requisición con el id especificado
    const itemConRequisicion = proyecto.items.find((item: any) => item.requisiciones && item.requisiciones.some((req:any) => req.idRequisicion === idRequisicion));

    if (itemConRequisicion) {
      // Encontrar la requisición específica dentro del item
      const requisicion = itemConRequisicion.requisiciones.find((req:any) => req.idRequisicion === idRequisicion);
    
      if (requisicion) {
        // Agregar el nuevo estado al array statusRequisicion
        requisicion.statusRequisicion.push({ status: "pagado", date: Date.now(), idTransaccion, monto });
      }
    }

    console.log("UPDATE en SERVER > proyecto: ", proyecto)
    const payload = {
      type: proyecto.status,
      name: proyecto.name,
      uidMedio: proyecto.cadena,
      uid: proyecto.uid,
      detalles: {...proyecto },
    }
    await proyectoSave(payload)
  }
