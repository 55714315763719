import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect } from "react";

export interface IAlertMessage {
  message: string;
  title: string;
  type: string;
  index?: number;
}
interface IProps {
  alert?: IAlertMessage | null,
  setAlert?: (d: any) => void;
  offAutoClose?: boolean;
}

const AlertMessage = ({ alert, setAlert, offAutoClose = false }: IProps) => {
  useEffect(() => {
    if (!offAutoClose && alert && setAlert) {
      setTimeout(() => {
        setAlert(null)
      },2800 )
    }
  }, [offAutoClose, alert, setAlert])

  const close = () => { 
    setAlert && setAlert(null)
  }

  return (
    alert ? (<div className={`alert alert-${alert?.type} alert-dismissible fade show d-flex align-items-start mt-3 `} role="alert">
      <strong> { alert?.title }</strong>: { alert?.message }

      <MDBBtn className='btn-close' color='none' onClick={close}></MDBBtn>
    </div>) :
    null
  )
}

export default AlertMessage;
