import React, { useState } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBSpinner } from 'mdb-react-ui-kit'; // MDBContainer, MDBModalFooter
import { settingsDelete } from './api';

interface IProps {
  keyModal: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, data?: any }
  handleSubmitDelete: any;
  centeredDialog?: boolean;
  customCallDelete?: boolean;
}



const ConfirmDeleteModal = ( {
  keyModal, 
  showModal = false, 
  setShowModal, 
  typeAction: { typeName="", data }, 
  handleSubmitDelete, 
  centeredDialog, 
  customCallDelete = false,
}: IProps ) => {
console.log("Inside Delete-Modal - typeAction: ", { typeName, data })
  const [saving, setSaving] = useState(false)

  const toggleShow = () => setShowModal(!showModal);

  const handleSubmit = async() => {
    setSaving(true)
    try {
       !customCallDelete && await settingsDelete(typeName, data.uid)
        handleSubmitDelete()
        setShowModal(false)
    } catch (e) {
        console.log("DeleteModal - e: ", e)    
        setSaving(false)
    }


  }

  return (
      <MDBModal staticBackdrop show={showModal} setShow={setShowModal} tabIndex='-1' >
        <MDBModalDialog scrollable centered={centeredDialog}>
          <MDBModalContent key={keyModal} style={{border: "thin solid #3b71ca" }}>
            <MDBModalHeader>
              <MDBModalTitle>Atencion!</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={(toggleShow)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <h5>
                Esta seguro Eliminar:<br />{`${typeName} - ${data.name || ""}`}?
              </h5>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleShow}>
              <span className='px-2'>Cancelar</span>
              </MDBBtn>
              <MDBBtn onClick={handleSubmit} color='danger' >
              {
                  saving 
                    ? <><MDBSpinner size='sm' role='status' tag='span' className='me-1' /> Eliminando... </>
                    : <span className='px-4'>Eliminar</span>
                }
              </MDBBtn>
            </MDBModalFooter> 
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

  )
}

export default ConfirmDeleteModal;
