import styled from "@emotion/styled";
import { MDBRow, MDBValidation } from "mdb-react-ui-kit";

export const StyledContainerConcepto = styled(MDBValidation)`
  & > div {
    margin: 8px 0;
    padding: 0;
  }
  & > h5 {
    margin-top: 10px;
  }
`;

export const StyleRow = styled(MDBRow)`
  margin: 0 10px;
`;

export const StyledStatusRow = styled.div`
  display: flex; /* Para que las columnas se alineen horizontalmente */
  justify-content: space-between; /* Espacio entre las columnas */
  padding: 8px; /* Espaciado interno */
  border-bottom: 1px solid #ccc; /* Línea divisoria entre filas */

  & > .bold {
    font-weight: 600;
  }
  & > .margin {
    margin-left: 10px;
  }
`

