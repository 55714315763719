import { useEffect, useState } from 'react';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { StyledContainerConcepto } from "../general/StylesUtils";
import { reCalculateSaldoBanco, settings } from './api';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import AddItemModal from './AddItemModal';
import DataTableCustom from '../general/DataTableCustom';
import Loading from '../general/loading';
import { parseMoneda, validateIsMoneda, validateRequiredData } from '../general/utils';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { TableColumn } from 'react-data-table-component';
import { NumericFormat } from 'react-number-format';
import NumericFormatCustom from '../general/NumericFormatCustom';

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

type DataRow = {
  name: string;
  saldoInicial: string;
  ingresos: string;
  egresos: string;
};

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [formValue, setFormValue] = useState({
    uid: null,
    name: '',
    saldoInicial: '',
    ingresos: 0.00,
    egresos: 0.00,
  })

  const [_, setRequiredStatus] = useState({
    name: false,
    saldoInicial: false,
  })
  
  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

    setFormValue({...typeAction.data})
    setIsValidateFine(true)
    setRequiredStatus({
      name: true,
      saldoInicial: true,
    })
  }, [typeAction.data])


  const handleSaveData = async() => {
    try {
      setSaving(true)
      let message = "Datos Guardados..."
      const { uid, name, saldoInicial } = formValue

      const payload = {
          type: "banco",
          name,
          ...{ uid },
          detalles: { ...formValue, saldoInicial: parseMoneda(saldoInicial) },
        }

      if (typeAction.option === "recalcular") {
        message = "Recalculando Saldos..."
        await reCalculateSaldoBanco(payload)
      } else {
        await settings(payload)
      }
      

      showAlert({
        title: "Mensaje", message, type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target

    if (name === "saldoInicial" && !validateIsMoneda(value)) return

    setFormValue({ ...formValue, [name]: value });
  }

  const onChangeFormatNumber = (e: any, name: string) => {
    const { value } = e

    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const constentForm = () => {
    const { name, saldoInicial, ingresos, egresos } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='name' value={name} onChange={onChange} onBlur={isValidate} required disabled={ typeAction.option === "recalcular" } />
        {/* <MDBInput label="Saldo inicial" type='text' name="saldoInicial" value={saldoInicial} onChange={onChange} onBlur={isValidate} required disabled={ typeAction.option === "recalcular" } /> */}
        <NumericFormatCustom label="Saldo inicial" name="saldoInicial" value={saldoInicial} handleOnChange={onChangeFormatNumber} handleOnBlur={isValidate} required disabled={typeAction.option === "recalcular"} />
        { typeAction.option === "recalcular" && <> 
          <NumericFormatCustom name="ingresos" value={ingresos} disabled={true} />
          <NumericFormatCustom name="egresos" value={egresos} disabled={true} />
        </>}
      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal 
      keyModal="modal-banco"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValidateFine}
      saving={saving}
      labelSaveButton={ typeAction.option === "recalcular" ? "ReCalcular" : "Guardar" }
    >
      { constentForm() }
    </AddItemModal>
  )
}

const BancoSettings = () => {
  const bancosServer = useSettingsStore((state) => (state.bancos), shallow)
  const {getBancosServer} = useSettingsStore()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [typeAction, setTypeAction] = useState({})
  const [negocios, setNegocios] = useState<any[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    setNegocios(bancosServer)
  }, [bancosServer])

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Banco",
      selector: (row: { name: any; }) => row.name,
      sortable: true
    },
    {
      name: "Sando Inicial",
      // selector: (row: { saldoInicial: any; }) => row.saldoInicial,
      cell: (row: { saldoInicial: any; }) => {
        return (
          <NumericFormat displayType="text" value={row.saldoInicial} prefix="$" thousandSeparator="," decimalScale={2}  />
        )
      },
      sortable: true
    },
    {
      name: "Ingresos",
      // selector: (row: { ingresos: any; }) => row.ingresos,
      cell: (row: { ingresos: any; }) => {
        return (
          <NumericFormat displayType="text" value={row.ingresos} prefix="$" thousandSeparator="," decimalScale={2}  />
        )
      },
      sortable: true
    },
    {
      name: "Egresos",
      // selector: (row: { egresos: any; }) => row.egresos,
      cell: (row: { egresos: any; }) => {
        return (
          <NumericFormat displayType="text" value={row.egresos} prefix="$" thousandSeparator="," decimalScale={2}  />
        )
      },
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ];

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("banco", "recalcular", data)} >
          <MDBIcon fas icon='sync' size='sm' />
        </MDBBtn>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("banco", "Edit", data)} >
          <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("banco", "Delete", data)} >
          <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }

    setTypeAction({ typeName, option, data })
  }

  const handleSave = async() => {
    setLoading(true)
    getBancosServer()
  }

  return (
    <MDBContainer>
      <h4> Bancos </h4>
      { (loading) ? 
          <Loading />
          :
          <>
            <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
              <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("banco", "Nuevo")} >+ Agregar Banco</MDBBtn>
            </div>
      
          <DataTableCustom keyDataTable="table-bancos" columns={columns} data={negocios} />
          </>
      }
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default BancoSettings;