import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import AlertMessage from "./alertMessage";
import { ReactNode } from "react";

interface IProps {
    title: string;
    handleClose?: any;
    handleSave?: any;
    disabledSaveButton?: boolean;
    alert?: any;
    children?: ReactNode;
}

const HeaderContainer = ({ title, handleClose, handleSave, disabledSaveButton = false, alert, children }: IProps) => {
    return (
      <MDBContainer className="mt-4">
        <MDBRow className='mb-3'>
          <MDBCol size='6'>
            <h4 className="text-start" >{ title }</h4>
          </MDBCol>
          { handleClose && handleSave &&  
          <MDBCol size='6'>
            <div className="d-flex flex-row-reverse">
              <div className="p-2"><MDBBtn color='secondary' onClick={handleClose} > Cerrar </MDBBtn></div>
              <div className="p-2"><MDBBtn onClick={handleSave} disabled={disabledSaveButton} > Guardar </MDBBtn></div>        
              { children }
            </div>
          </MDBCol>}
          <MDBCol size='12'>
            {alert && <AlertMessage alert={alert}/> } 
          </MDBCol>
        </MDBRow>
        <hr />
      </MDBContainer>    
    );
}

export default HeaderContainer;