import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { StyledContainerConcepto } from "../general/StylesUtils";
import { settings } from './api';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import AddItemModal from './AddItemModal';
import DataTableCustom from '../general/DataTableCustom';
import Loading from '../general/loading';
import { validateRequiredData } from '../general/utils';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { TableColumn } from 'react-data-table-component';

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

type DataRow = {
  name: string;
};

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [formValue, setFormValue] = useState({
    uid: null,
    name: '',
  })

  const [_, setRequiredStatus] = useState({
    name: false,
  })
  
  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

    setFormValue({...typeAction.data})
    setIsValidateFine(true)
    setRequiredStatus({
      name: true,
    })
  }, [typeAction.data])

  // const isValidate = (e: { target: any; }) => {
  //   const data = e.target
  //   const { name, value, required } = data

  //   if(!required) return
   
  //   setRequiredStatus((data: any) => {
  //     const newData = {
  //       ...data,
  //       [name]: !!value.trim(),
  //     }

  //     const validate = Object.values(newData).find((value) => !value )

  //     setIsValidateFine(validate === undefined)
  //     return newData
  //   })
  // }

  const handleSaveData = async() => {
    try {
      setSaving(true)
      const { uid, name } = formValue

      const payload = {
          type: "lineanegocio",
          name,
          ...{ uid },
          detalles: { ...formValue },
        }

      await settings(payload)

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target

    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const constentForm = () => {
    const { name } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='name' value={name} onChange={onChange} onBlur={isValidate} required />
      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal 
      keyModal="modal-lineadenegocio"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValidateFine}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const LineasNegocioSettings = () => {
  const lineasNegocioServer = useSettingsStore((state) => (state.lineasnegocio), shallow)
  const {getLineasNegocioServer} = useSettingsStore()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [typeAction, setTypeAction] = useState({})
  const [negocios, setNegocios] = useState<any[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    setNegocios(lineasNegocioServer)
  }, [lineasNegocioServer])

  // const headers = [
  //   { label: 'Linea de Negocio', name: 'name'},
  // ];

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Linea de Negocio",
      selector: row => row.name,
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ]

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("negocio", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("lineanegocio", "Delete", data)} >
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }

    setTypeAction({ typeName, option, data })
  }

  const handleSave = async() => {
    setLoading(true)
    getLineasNegocioServer()
  }

  return (
    <MDBContainer>
      <h4> Lineas de Negocio </h4>
      { (loading) ? 
          <Loading />
          :
          <>
            <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
              <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("negocio", "Nuevo")} >+ Agregar Linea de Negocio</MDBBtn>
            </div>
      
          <DataTableCustom keyDataTable="table-lineadenegocio" columns={columns} data={negocios} />
          </>
      }
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default LineasNegocioSettings;