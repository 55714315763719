import { TableColumn } from "react-data-table-component";
import { MDBBtn, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { shallow } from 'zustand/shallow';
import ReactToPrint from 'react-to-print';
import { ButtonProps } from 'mdb-react-ui-kit/dist/types/free/components/Button/types';
import HeaderContainer from '../general/HeaderContainer';
import DataTableCustom from '../general/DataTableCustom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ICotization, useSettingsStore } from '../../store/settingsStore';
import { formatMoney, getStatusColors, optionsStatus, unixTimestampToDate } from '../general/utils';
import ComponentToPrint from './ComponentToPrint';
import SearchContainer from '../general/SearchContainer';
import { StyledStatusRow } from '../general/StylesUtils';
import TooltipNewCustom from '../general/tooltipNewCustom';
import moment from 'moment';
import ConfirmCopyModal from '../settings/ConfirmCopyModal';
import { proyectoSave } from '../settings/api';

const ComponentToPrintWrapper = ({ data }: { data: any }) => {
  const componentRef = useRef<HTMLDivElement>(null)
console.log("ComponentToPrintWrapper - data: ", data)
  return (
    <>
      <ReactToPrint
        trigger={() => 
          <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px', overflow: "initial" }} onClick={() => console.log("print")} >
            <MDBIcon fas icon='print' size='sm' />
          </MDBBtn>
        }
        content={() => componentRef.current}
      />
      <div className='printTemplate' ref={ componentRef } >
        <ComponentToPrint printData={data}  />
      </div>
    </>
  )
}

type DataRow = {
  name: string;
  totalCosto: string;
  totalVenta: string;
  status: string;
  updatedAt: number,
};

const Cotizaciones = () => {
  const proyectosServer = useSettingsStore((state) => (state.proyectos), shallow)
  const { getProyectosServer } = useSettingsStore()
  const [cotizaciones, setCotizaciones] = useState<ICotization[]>([])
  const [cotizacionesActives, setCotizacionesActivos] = useState<ICotization[]>([])
  const [typeActionCopy, setTypeActionCopy] = useState({typeName: "", data: {uid: "", cadena: "", items: [] }})
  const [showCopyModal, setShowCopyModal] = useState(false)

  const getData = useCallback(async() => {
    getProyectosServer()
  }, [getProyectosServer])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    const newCotizacionesList = proyectosServer.filter((items) => {
      return items.status !== 'perdido' 
    })
    setCotizacionesActivos(newCotizacionesList)
    setCotizaciones(newCotizacionesList)
  }, [proyectosServer])

  const handlItemActionEdit = (data?: any) => {
    window.sessionStorage.setItem("cotizacion", JSON.stringify(data));
    window.location.href = `/cotizacion/edit/${data.uid}`
  }

  const handelCopyItemAction = (data?: any) => {
    console.log("copy-data: ", data)

    setShowCopyModal(true)
    setTypeActionCopy({ typeName: data.name, data })
  }

  const contentStatusInfo = (statusInfo: any[] | undefined) => {
    return (
      <>
        <StyledStatusRow key="status-head">
          <div className="bold">Status</div>
          <div className="bold">Fecha</div>
        </StyledStatusRow>
        {statusInfo && statusInfo.map((info, index) => (
        <StyledStatusRow key={index}>
          <div>{info.status}</div>
          <div className="margin">{ unixTimestampToDate(info.date) }</div>
        </StyledStatusRow>
      ))}
      </>
    )
  }

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Nombre Proyecto",
      selector: (row: { name: any; }) => row.name,
      sortable: true,
      // maxWidth: "none",
    },
    {
      name: "Total Costo",
      selector: (row: { totalCosto: any; }) => formatMoney(row.totalCosto),
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Total Venta",
      selector: (row: { totalVenta: any; }) => formatMoney(row.totalVenta),
      sortable: true,
      maxWidth: "100px",
    }, 
    {
      name: "Fecha de Cambio",
      selector: (row: { updatedAt: any; }) => moment(row.updatedAt * 1000).format("DD/MM/YYYY"), //(row.updatedAt),
      sortable: true,
      maxWidth: "130px",
    },
    {
      name: "Status",
      cell: (row: any) => {
        const statusCustom = row?.terminado ? "terminado" : row.status
        const colorStyle: ButtonProps["color"] = getStatusColors(statusCustom)

        return (
          <TooltipNewCustom
            buttonContent={<MDBBtn rounded style={{ padding: "5px 12px"}} color={colorStyle}>{statusCustom}</MDBBtn> } 
            tooltipContent={ contentStatusInfo(row.statusInfo) }
          />
        )
      },
      sortable: true,
      maxWidth: "120px",
    }, 
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true,
      minWidth: "150px",
    }
  ];

  const actionsDataTable = (data: any) => {
    return (
      <div style={{ display: 'inline', flexDirection: 'row',
        flexWrap: 'nowrap',
        position: 'absolute',
        top: '15px' }} >
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px', overflow: 'initial' }} onClick={() => handlItemActionEdit(data)} >
          <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px', overflow: 'initial' }} onClick={() => handelCopyItemAction(data)} >
          <MDBIcon fas icon='clone' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998', width: '30px', height: '30px' }} onClick={() => console.log("Eliminar!!")} >
          <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>

        <ComponentToPrintWrapper data={data} />

      </div>
    )
  }

  const resetData = () => {
    setCotizaciones(cotizacionesActives)
  }

  const setNewData = (data: any[]) => {
    console.log("setNewData >> ", data)
    setCotizaciones(data)
  }

  const handleSaveCopy = async(newName: string) => {
    console.log("++newName > ", newName)
    const status = "espera"
    const { data } = typeActionCopy
    const {items} = data
    console.log("++typeActionCopy: ", typeActionCopy)
    const {uid, ...restAll} = data

    const newData = {
      ...restAll,
      name: newName,
      statusInfo: [ {date: moment().format("X"), status} ],
      status,
      items: items.map((item: any) => {
          const { requisiciones, ...rest } = item;
          return rest;
      })
    };
  
    console.log("newData >> ", newData);
    const payload = {
      type: status,
      name: newName,
      uidMedio: newData?.cadena || "",
      // ...{ uid },
      detalles: newData,
    }
    console.log("payload:: ", payload)
    await proyectoSave(payload)

    getData()
  }

  return (
      <MDBContainer>
        <HeaderContainer title={`Cotizaciones`} ></HeaderContainer>
        {/* <MDBRow style={{padding: "10px 20px"}}>
          <MDBCol md='8' className="px-2">
            <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' />}>
              <input className='form-control' type='text' placeholder='Buscar por Nombre de Proyecto' value={textSearch} onChange={onChange} />
            </MDBInputGroup>
          </MDBCol>
          <MDBCol md='4' className="px-2">
            <SelectCustom options={[ { value: '', label: 'Todos' }, ...optionsStatus]} onChangeSelect={onChangeSelect} name='status' defaultValue={statusSearch} />
          </MDBCol>
        </MDBRow> */}
        <SearchContainer dataServer={proyectosServer} optionsSelect={optionsStatus} setNewData={setNewData} resetData={resetData} textItem="name" selectItem="status" />

        <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
          <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => window.location.href = `/cotizacion/new`} >+ Agregar Cotizacion</MDBBtn>   
        </div> 
        <DataTableCustom keyDataTable="table-servicios" columns={columns} data={cotizaciones} sortFieldId={4} sortAsc={false} />


        {/* <div style={{border: "solid thin black"}}>
          HERE!!
          <ComponentToPrint printData={ cotizaciones.length ? cotizaciones[0] : {}}  />
        </div> 
        // solo se ocupa algunos ajustes en ComponentToPrint para verlo en html
        */}


        {showCopyModal && <ConfirmCopyModal keyModal="modal-proveedor-delete" showModal={showCopyModal} setShowModal={setShowCopyModal} typeAction={typeActionCopy} handleSubmitAction={handleSaveCopy} />}
      </MDBContainer>
    );
}

export default Cotizaciones;
