import { useState } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBSpinner } from 'mdb-react-ui-kit'; // MDBContainer, MDBModalFooter

interface IProps {
  keyModal: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, data?: any }
  handleSubmitAction: any;
  // centeredDialog?: boolean;
  // customCallDelete?: boolean;
}



// eslint-disable-next-line import/no-anonymous-default-export
export default ( {
  keyModal, 
  showModal = false, 
  setShowModal, 
  typeAction: { typeName="", data }, 
  handleSubmitAction, 
  // centeredDialog, 
//   customCallDelete = false,
}: IProps ) => {
console.log("Inside Copy-Modal - typeAction: ", { typeName, data })
  const [saving, setSaving] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const toggleShow = () => setShowModal(!showModal);

  const handleSubmit = async() => {
    if (!inputValue.trim()) {
        return
    }
    setSaving(true)
    try {
    //    !customCallDelete && await settingsDelete(typeName, data.uid)
        await handleSubmitAction(inputValue)
        setShowModal(false)
    } catch (e) {
        console.log("DeleteModal - e: ", e)    
        setSaving(false)
    }
  }

  const onChange = (e: any) => {
    console.log("onChange - e: ", e)
    const { value } = e.target
    setInputValue(value)
  }

  return (
      <MDBModal staticBackdrop show={showModal} setShow={setShowModal} tabIndex='-1' >
        <MDBModalDialog scrollable>
          <MDBModalContent key={keyModal} style={{border: "thin solid #3b71ca" }}>
            <MDBModalHeader>
              <MDBModalTitle>Hacer una copia: {typeName} </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={(toggleShow)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* <h5>
                Esta seguro Eliminar:<br />{`${typeName} - ${data.name || ""}`}?
              </h5> */}
              <input className='form-control' type='text' placeholder='Nuevo Nombre' value={inputValue} onChange={onChange} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleShow}>
              <span className='px-2'>Cancelar</span>
              </MDBBtn>
              <MDBBtn onClick={handleSubmit} >
              {
                  saving 
                    ? <><MDBSpinner size='sm' role='status' tag='span' className='me-1' /> Guardando... </>
                    : <span className='px-4'>Guardar</span>
                }
              </MDBBtn>
            </MDBModalFooter> 
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

  )
}
