import React, { useState } from 'react';
import styled from '@emotion/styled';
import IconDropDonMenu from '../IconDropDownMenu';
import Settings from '../settings';
import { MDBCardImage } from 'mdb-react-ui-kit';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';

const StyledNavBar = styled.nav`
  background-color: blue;
  `;

const StyledContainerDiv = styled.div`
`

export default () => {
    const dataAccess = useSettingsStore((state) => (state.auth.jwtDecoded), shallow)
    const {logout} = useSettingsStore()
    const [showModal, setShowModal] = useState(false);
    const handleShowSettings = () => {
        console.log("Click here settings!!")
        setShowModal(true);
    }

    return(
        <>
        <StyledNavBar className="navbar navbar-expand-lg navbar-light bg-light" >
            <StyledContainerDiv className='container-fluid'>
                {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
                <div className="d-flex flex-row align-items-center">
                <MDBCardImage src="../../../images/sensorial-logo.jpeg" width="50px" />
                <h5>Sensorial Media</h5>
                </div>
                {/* </div> */}
                <StyledContainerDiv className="d-flex align-items-center">
                    <label className='me-4'>Bienvenido <label className='fw-bold'>{dataAccess?.userName}</label> </label>
                    { dataAccess?.access?.settingsCk &&
                      <a className="text-reset me-3" href="#" onClick={handleShowSettings}>
                        <i className="fas fa-cog"></i>
                      </a>
                    }
                    {/* {<IconDropDonMenu iconInfo={{name: 'fa-cog', hiddenArrow: true  }} /> } */}
                    <IconDropDonMenu 
                        iconInfo={{ name: 'fa-user', hiddenArrow: false }} 
                        liOptions={[ 
                            {liLabel: 'My Perfil', liOnClick: () => { } }, 
                            {liLabel: 'Salir', liOnClick: () => logout()}, 
                        ]}
                    /> 
                </StyledContainerDiv>
            </StyledContainerDiv>
            <Settings showModal={showModal} setShowModal={setShowModal} ></Settings>
        </StyledNavBar>
        
        <Settings showModal={showModal} setShowModal={setShowModal} ></Settings>
        </>
    );
};