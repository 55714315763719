import { MDBCol, MDBContainer, MDBIcon, MDBInputGroup, MDBRow } from "mdb-react-ui-kit";
import SelectCustom from "./SelectCustom";
import { useState } from "react";

interface IProps {
  dataServer: any[];
  optionsSelect: any[];
  setNewData: (data: any[]) => void;
  resetData: () => void;
  textItem: string;
  selectItem?: string;
  setStatusData?: (value: string) => void;
}

const SearchContainer = ({dataServer, optionsSelect, setNewData, resetData, textItem, selectItem, setStatusData }: IProps) => {
  const [statusSearch, setStatusSearch] = useState("")
  const [textSearch, setTextSearch] = useState("")
  // console.log("setStatusData: ", setStatusData)

  const getCotizacionesSearch = ({ textSearch, statusSearch }: any) => {
    if(!textSearch && !statusSearch) {
      resetData()
      return
    }
console.log("statusSearch >> ", statusSearch)
    const newCotizacionesList = dataServer.filter((items) => {
      const isLastState = statusSearch === "terminado" && items.terminado 
      const status      = statusSearch && selectItem ? (items?.[selectItem] === statusSearch && !items.terminado ) : true
      const text        = textSearch ? items?.[textItem].toLowerCase().includes(textSearch) : true

      return (status || isLastState) && text
    })

    setNewData( newCotizacionesList || [])
  }

  const onChange = (e: any) => {
    const { value } = e.target

    setTextSearch(value.toLowerCase())

    getCotizacionesSearch({ textSearch: value.toLowerCase(), statusSearch: statusSearch })
  }

  const onChangeSelect = (data: any) => {
    const {value} = data

    setStatusSearch(value)
    console.log("STATUS-value: ", value)
    setStatusData && setStatusData(value.toLowerCase())
    getCotizacionesSearch({ textSearch, statusSearch: value })
  }

  return (
    <MDBContainer>
      <MDBRow style={{padding: "10px 20px"}}>
        <MDBCol md='8' className="px-2">
          <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' />}>
            <input className='form-control' type='text' placeholder='Buscar por Descripcion' value={textSearch} onChange={onChange} />
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md='4' className="px-2">
          <SelectCustom options={[ { value: '', label: 'Todos' }, ...optionsSelect]} onChangeSelect={onChangeSelect} name='status' defaultValue={statusSearch} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>  
  )
}

export default SearchContainer
