import { MDBCol, MDBContainer, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { shallow } from "zustand/shallow";
import { useSettingsStore } from "../../store/settingsStore";
import { useEffect, useState } from "react";
import moment, { months } from "moment"
import 'moment/locale/es'
import { formatMoney, formatPhone } from "../general/utils";
import styled from "@emotion/styled";

const StyledComponent = styled.div`
    padding: 0; 
    margin: 0px;
    font-size: 14px !important;
    & p {
        margin: 0;
        font-size: 14px !important;
    }
    & h6 {
        font-size: 14px !important;
    }
`

moment.locale('es')

interface IProps {
    printData: any;
}
const ComponentToPrint = ({ printData }: IProps) => {
    const clientesServer = useSettingsStore((state) => (state.clientes), shallow)
    const [client, setClient] = useState<any>(null)
    const user = { name: "Maria Jimenez", correo: "maria.jiminez@a.com", telefono: "6623296572" } // useState()
    const today = moment()

    const headers = [
        { 
            label: "Descripcion", 
            name: "conceptoLabel",
            customItem: (row: { medioLabel: any, conceptoLabel: any, descripcion: any }) => `${row.medioLabel}-${row.conceptoLabel}${ row.descripcion ? "-" + row.descripcion :"" }`,
        },
        { label: "Cantidad", name: "cantidad" },
        { label: "Repeticion", name: "repeticion" },
        { 
            label: "Precion unitario", 
            name: "precioUnitario",
            customItem: (row: { precioUnitario: any, porcentaje: any }) => formatMoney(Number(row.precioUnitario) * (Number(row.porcentaje)/100 + 1)) ,
        },
        { 
            label: "Total", 
            name: "total",
            customItem: (row: any) => {
                const { precioUnitario, porcentaje, cantidad, repeticion } = row
                const precio = Number(precioUnitario) * (Number(porcentaje)/100 + 1)

                return formatMoney(cantidad * repeticion * precio)
            } ,
        },
    ]

    useEffect(() => {
        const clientData = clientesServer.find(({uid}) => uid === printData?.cliente)
        setClient(clientData )
    }, [clientesServer])

    const showProducts = () => {
        return (
            <MDBTable>
                <MDBTableHead key="tb-products">
                    <tr>
                    { headers.map(({label, name}) => {
                        return <th key={`tb-products-th-${name}`} scope='col'>{ label }</th>
                    }) }
                    <th></th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                    printData?.items.map((item: any, index: number) => {
                        return (
                        <tr key={`products-tr-${index}`} >
                            { headers.map(({name, customItem}) => {
                            return <td key={`products-td-${name}`} >{ customItem ? customItem(item) : item[name] }</td>
                            })}
                    
                        </tr>
                        )
                    })
                    }
                </MDBTableBody>
            </MDBTable> 
        )
    }

    return (
        <StyledComponent >
            <MDBRow style={{ backgroundColor: ' #445c74', color: 'white', margin: 0, padding: "25px" }} >
                <MDBCol md='12' className="px-2" style={{ textAlign: 'start', margin: "10px 0" }}>
        
                <h5 className="fw-bold"> { printData?.name}</h5>
                </MDBCol>
                <MDBCol md='6' className="px-2" style={{ textAlign: 'start', margin: "10px 0" }}>
                    <p className="fw-bolder">No de Proveedor { client?.noInterno || "" }</p>
                    <p className="fw-normal">Medios Digitales Enfocados SAPI de CV </p>
                </MDBCol>
                <MDBCol md='6' className="px-2" style={{ margin: "10px 0", textAlign: 'right' }}>
                    <p>Id-Referencia: { (printData?.uid).substring(0, 10) }</p>
                    <p>Fecha creado: { today.format("LL") }</p>
                    <p>Fecha expira: { today.add(3, "months").format("LL") }</p>
                    <p>Creado por: {user?.name || ""}</p>
                    <p>{user?.correo || ""}</p>
                    <p>+52 {formatPhone(user?.telefono) || ""}</p>
                </MDBCol>
            </MDBRow>
            <MDBRow style={{ textAlign: 'start', border: "thin solid black", margin: "40px 20px", padding: "25px" }} >
                <MDBCol >
                    <h6 className="fw-bold">Comentarios:</h6> 
                    <p>  { printData?.descripcion } </p>
                </MDBCol>
            </MDBRow>
            <MDBRow style={{ margin: "25px 20px" }}>
                <MDBCol style={{ textAlign: 'start' }} > 
                    <h6 className="fw-bold">Productos y Servicios</h6>
                </MDBCol>
                { showProducts() }
                <MDBCol style={{ textAlign: 'right', margin: "0 70px" }}>
                    <p><h6> Subtotal: { formatMoney(printData?.totalVenta) } </h6></p>
                    <p><h6 className="fw-bold"> Total: { formatMoney(printData?.totalVenta) } </h6></p>
                </MDBCol>
            </MDBRow>
            <MDBRow style={{ margin: "20px 20px", textAlign: 'start' }}>
                <MDBCol>
                    <h6 className="fw-bold" >Condiciones de Compra</h6>
                    <p>- Precios expresado en moneda nacional y antes de IVA (16%)</p>
                    <p>- Tiempo de entrega 7 días hábiles contando a partir del día de aprobación.</p>
                    <p>- Se requiere orden de aprobación.</p>
                </MDBCol>
            </MDBRow>
            <MDBRow style={{ margin: "0 20px", textAlign: 'start' }}>
                <MDBCol style={{ marginTop: "20px" }}>
                  <h6 className="fw-bold" >Contacto</h6>
                  <p>{user?.name || ""}</p>
                  <p>{user?.correo || ""}</p>
                  <p>+52 {formatPhone(user?.telefono) || ""}</p>
                  <p>Medios Digitales Enfocados SAPI de CV </p>
                  <p>Blvd Gustavo Díaz Ordaz #12415 </p>
                  <p>Col El Paraiso </p>
                  <p>Tijuana, Baja California 22106 </p>
                  <p>México</p>
                </MDBCol>
            </MDBRow>         
        </StyledComponent>
    )
}

export default ComponentToPrint;
