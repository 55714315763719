import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import { SidebarData } from './SidebarData'
import styled from '@emotion/styled';

const StyledSidebar = styled(CDBSidebar)`
  height: calc(100vh - 60px);
`;

const StyledSidebarFooter = styled(CDBSidebarFooter)`
  padding-bottom: 20px;
  text-align: center;
`;

const Sidebar = () => {
  return (

      <StyledSidebar textColor="#fff" backgroundColor="#333" className={''} breakpoint={0} toggled={false} minWidth={'50px'} maxWidth={'180px'}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            Menu
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
          {SidebarData().map((item, index) => {
            return (
              <NavLink key={`navLink-${index}`} to={item.path} >
                <CDBSidebarMenuItem icon={item.icon}>{ item.title }</CDBSidebarMenuItem>
              </NavLink>
            )
          })}

            {/* <NavLink to="/cotizaciones" >
              <CDBSidebarMenuItem icon="columns">Cotizaciones</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/tables" >
              <CDBSidebarMenuItem icon="table">Tables</CDBSidebarMenuItem>
            </NavLink>
            <NavLink  to="/profile">
              <CDBSidebarMenuItem icon="user">Profile page</CDBSidebarMenuItem>
            </NavLink>
            <NavLink  to="/analytics">
              <CDBSidebarMenuItem icon="chart-line">Analytics</CDBSidebarMenuItem>
            </NavLink>

            <NavLink to="/hero404" target="_blank">
              <CDBSidebarMenuItem icon="exclamation-circle">404 page</CDBSidebarMenuItem>
            </NavLink> */}
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <StyledSidebarFooter>
          ERP Sensorial Media
        </StyledSidebarFooter>
      </StyledSidebar>
    
  );
};

export default Sidebar;