import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { StyledContainerConcepto } from "../general/StylesUtils";
// import SelectCustom from "../general/SelectCustom";
import { useMemo, useState } from "react";
import AddItemModal from "../settings/AddItemModal";

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

// const getDefaultData = (data: any) => {
//   return {origen: data?.year || null, destino: null}
// }

const ClonarPresupuesto = ({ showModal, setShowModal, typeAction, handleSave }: IProps ) => {
  const [inputValue, setInputValue] = useState('');
  const [alert, setAlert] = useState(null)
  // const [years, setYears] = useState(getDefaultData(typeAction?.data))
  // const currentYear = new Date().getFullYear();

  const handleSaveData = () => {
    console.log("handleSaveData");
    handleSave(inputValue)
    setShowModal(false)
  }

  const isValid = useMemo(() =>{
    return inputValue.length > 0;
  }, [inputValue])

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const containerForm = () => {
    return (
      <StyledContainerConcepto>
        <MDBRow style={{padding: "10px"}}>
          <MDBCol md='12' className="px-4">
            <label>Nombre del Presupuesto</label>
            <input type="text" className="form-control" value={inputValue} onChange={handleInputChange} />
          </MDBCol>
        </MDBRow>
      </StyledContainerConcepto>
    )
  }
  return (
    <AddItemModal
      keyModal="modal-clonarPresupuesto"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValid}
      saving={false}
    >
      { containerForm() }
    </AddItemModal>
  );
}

export default ClonarPresupuesto;
