import { useEffect, useState } from 'react';
import { MDBBtn, MDBCheckbox, MDBContainer, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import AddItemModal from './AddItemModal';
import DataTableCustom from '../general/DataTableCustom';
import { StyledContainerConcepto } from "../general/StylesUtils";
import { settingUser } from './api';
import { TableColumn } from 'react-data-table-component';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import Loading from '../general/loading';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { validateRequiredData } from '../general/utils';

type DataRow = {
  userName: string;
  correo: string;
  telefono: string;
};

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [saving, setSaving] = useState(false)  
  const [, setAlert] = useState(null)
  const [disabledContrasena, setDisabledContrasena] = useState(typeAction.option === "Edit")
  const [contrasenaError, setContrasenaError] =useState("")

  const settingsItems = ["bancosSettingsCk", "mediosSettingsCk", "proveedoresSettingsCk", "cadenasSettingsCk", "clientesSettingsCk", "lineasDeNegocioSettingsCk", "categoriasSubcategoriasCk", "usuariosSettingsCk"]
  const operacionesItems = ["cotizacionCk", "transaccionCk", "requisicionCk"]

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  const [formValue, setFormValue] = useState({
    uid: null,
    userName: '',
    userEjecutivo: false,
    telefono: '',
    correo: '',
    contrasena: '',
    permisos: {
      bancosSettingsCk: false,
      mediosSettingsCk: false,
      proveedoresSettingsCk: false,
      cadenasSettingsCk: false,
      clientesSettingsCk: false,
      lineasDeNegocioSettingsCk: false,
      categoriasSubcategoriasCk: false,
      usuariosSettingsCk: false,
      cotizacionCk: false,
      transaccionCk: false,
      requisicionCk: false,
      presupuestoCk: false,
    },
  });

  const [, setRequiredStatus] = useState({
    userName: false,
    telefono: false,
    correo: false,
  })

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

    setFormValue({...typeAction.data})
    setIsValidateFine(true)
    setRequiredStatus({
      userName: true,
      telefono: true,
      correo: true,
    })
  }, [typeAction.data])

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const reviewSettingPermisos = (permisos: any) => {
    return settingsItems.some(item => permisos[item]);
  }

  const handleSaveData = async() => {
    const { uid, contrasena } = formValue
    if (!contrasena.trim()) {
      setContrasenaError("Error: Contrasena invalida")
      return
    }

    try {
      setSaving(true)
      setIsValidateFine(false)
      const settingsCk = reviewSettingPermisos(formValue.permisos)

      const permisos = {
        ...formValue.permisos,
        settingsCk,
      }

      const payload = {
        type: "user",
        name: formValue.correo,
        ...{ uid },
        detalles: { ...formValue, permisos },
      }

      await settingUser(payload)

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    if (name === "contrasena") {
      setContrasenaError("")
    }

    setFormValue({ ...formValue, [name]: value });
  }

  const onChangePassword = (e: any) => {
    const { value } = e.target
    if (value.length > 15) return

    onChange(e)
  }

  const handlePermisoschange = (e: any) => {
    const { name, checked } = e.target

    const updatePermisos = { ...formValue.permisos, [name]: checked }

    setFormValue({ ...formValue, permisos: updatePermisos})
  }

  const setSettingsData = (value: boolean, items: any) => {
    const newPermisos = items.reduce((acc: any, item: any) => {
      acc[item] = value;
      return acc;
    }, {});

    const updatePermisos = {
      ...formValue.permisos,
      ...newPermisos,
    }

    setFormValue({ ...formValue, permisos: updatePermisos})
  }

  const constentForm = () => {
    const { userName, telefono, correo, contrasena, permisos, userEjecutivo } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='userName' value={userName} onChange={onChange} onBlur={isValidate} required />
        <MDBContainer className=" p-0 text-start">
          <MDBCheckbox name='userEjecutivo' value='' checked={userEjecutivo} label='Activar Usuario Ejecutivo' onChange={() => {
            onChange({ target: { name: "userEjecutivo", value: !userEjecutivo } })
          }} />
        </MDBContainer>
        <MDBInput label="Telefono" type='tel' name='telefono' value={telefono} onChange={onChange} onBlur={isValidate} required />
        <MDBInput label="Correo" type='text' name='correo' value={correo} onChange={onChange} onBlur={isValidate} required />
        <MDBInput 
          label="Contrasena" 
          type={disabledContrasena ? 'password': 'text'} 
          name='contrasena' 
          value={contrasena} 
          onChange={onChangePassword} 
          required 
          disabled={disabledContrasena} />
          <label className='text-start fs-6'>* Contrasena: Maximo 15 caracteres</label>
          <label className='text-start' style={{color: 'red'}}  >{ contrasenaError }</label>
        {typeAction.option === "Edit" && 
          <MDBContainer className=" p-0 text-start">
            <MDBCheckbox name='updateContrasena' value='' label='Actualizar contrasena' onChange={() => {
              onChange({ target: { name: "contrasena", value: disabledContrasena ? "" : typeAction.data.contrasena } })
              setDisabledContrasena(!disabledContrasena)
            }} />
          </MDBContainer>  
        }

        <h5>Permisos</h5>
        <hr />
        <MDBContainer className=" p-3 text-start">
          <div className="d-flex flex-column mb-3 text-start">
            <div className="p-3">
              Settings
                <MDBBtn outline rounded className='mx-1' color='success' size='sm' onClick={() => setSettingsData(true, settingsItems)}>Todos</MDBBtn> / 
                <MDBBtn outline rounded className='mx-1' color='danger' size='sm' onClick={() => setSettingsData(false, settingsItems)}>Ninguno</MDBBtn>
            </div>
            <MDBCheckbox name='bancosSettingsCk' checked={permisos.bancosSettingsCk} label='Bancos' onChange={handlePermisoschange} />
            <MDBCheckbox name='mediosSettingsCk' checked={permisos.mediosSettingsCk} label='Medios y Conceptos' onChange={handlePermisoschange} />
            <MDBCheckbox name='proveedoresSettingsCk' checked={permisos.proveedoresSettingsCk} label='Proveedores' onChange={handlePermisoschange} />
            <MDBCheckbox name='cadenasSettingsCk' checked={permisos.cadenasSettingsCk} label='Cadenas' onChange={handlePermisoschange} />
            <MDBCheckbox name='clientesSettingsCk' checked={permisos.clientesSettingsCk} label='Clientes' onChange={handlePermisoschange} />
            <MDBCheckbox name='lineasDeNegocioSettingsCk' checked={permisos.lineasDeNegocioSettingsCk} label='Lineas de Negocio' onChange={handlePermisoschange} />
            <MDBCheckbox name='categoriasSubcategoriasCk' checked={permisos.categoriasSubcategoriasCk} label='Categorias y Subcategorias' onChange={handlePermisoschange} />
            <MDBCheckbox name='usuariosSettingsCk' checked={permisos.usuariosSettingsCk} label='Usuarios' onChange={handlePermisoschange} />
            <div className="p-3">
              Operaciones 
              <MDBBtn outline rounded className='mx-1' color='success' size='sm' onClick={() => setSettingsData(true, operacionesItems)}>Todos</MDBBtn> / 
              <MDBBtn outline rounded className='mx-1' color='danger' size='sm' onClick={() => setSettingsData(false, operacionesItems)}>Ninguno</MDBBtn>
           </div>
            <MDBCheckbox name='cotizacionCk' checked={permisos.cotizacionCk} label='Cotizaciones' onChange={handlePermisoschange} />
            <MDBCheckbox name='transaccionCk' checked={permisos.transaccionCk} label='Transacciones' onChange={handlePermisoschange} />
            <MDBCheckbox name='requisicionCk' checked={permisos.requisicionCk} label='Requisiciones' onChange={handlePermisoschange} />
            <MDBCheckbox name='presupuestoCk' checked={permisos.presupuestoCk} label='Presupuesto' onChange={handlePermisoschange} />
          </div>
        </MDBContainer>
        
      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal 
      keyModal="modal-usuario" 
      typeAction={typeAction} 
      showModal={showModal} 
      setShowModal={setShowModal} 
      handleSave={handleSaveData} 
      saving={saving} 
      disabledSaveButton={!isValidateFine}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const UsuariosSettings = () => {
  const usersServer = useSettingsStore((state) => (state.users), shallow)
  const {getUsersServer} = useSettingsStore()
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [typeAction, setTypeAction] = useState({})
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUsersServer()
  }, [getUsersServer])

  useEffect(() => {
    setLoading(false)
    setUsers(usersServer)
  }, [usersServer])

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Usuario",
      selector: row => row.userName,
      sortable: true
    },
    {
      name: "Telefono",
      selector: row => row.telefono,
      sortable: true
    },
    {
      name: "Correo",
      selector: row => row.correo,
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ]  

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("usuario", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("user", "Delete", data)}>
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }
    setTypeAction({ typeName, option, data })
  }

  const handleSave = async() => {
    setLoading(true)
    getUsersServer()
  }

  return (
    <MDBContainer>
      <h4> Usuarios </h4>
      <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
        <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("usuario", "Nuevo")} >+ Agregar Usuario</MDBBtn>
      </div>
      { (loading)
        ? <Loading />
        : <>
            <DataTableCustom keyDataTable="table-usuarios" columns={columns} data={users} />
          </>
      }
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default UsuariosSettings;
