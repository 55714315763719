import { shallow } from "zustand/shallow"
import { useSettingsStore } from "../../store/settingsStore"


export const SidebarData=() => {
  const dataAccess = useSettingsStore((state) => (state.auth.jwtDecoded), shallow)

  return [
    ...(dataAccess?.access?.cotizacionCk
      ? [
          {
            title: 'Cotizaciones',
            path: '/cotizaciones',
            icon: 'tasks',
          },
        ]
      : []),
    ...(dataAccess?.access?.transaccionCk
      ? [
          {
            title: 'Transaccion',
            path: '/transacciones',
            icon: 'exchange-alt',
          },
        ]
      : []),
      ...(dataAccess?.access?.requisicionCk
        ? [
            {
              title: 'Requisicion',
              path: '/requisiciones',
              icon: 'sticky-note',
            },
          ]
        : []),
        ...(dataAccess?.access?.presupuestoCk
          ? [
              {
                title: 'Presupuesto',
                path: '/presupuestos',
                icon: 'wallet',
              },
            ]
          : []),
  ]

  /*
  return [
    // {
    //     title: 'Home',
    //     path: '/',
    //     icon: <FaIcons.FaHome />
    // },
    {
        title: 'Cotizaciones',
        path: '/cotizaciones',
        icon: 'tasks', // <FaIcons.FaUsers />
    },
    {
        title: 'Transaccion',
        path: '/transacciones',
        icon: 'exchange-alt', // <FaIcons.FaTasks />
    },
    {
        title: 'Requisicion',
        path: '/requisiciones',
        icon: 'sticky-note', // <FaIcons.FaTasks />
        // https://www.devwares.com/docs/contrast/react/navigation/sidebar/
    },
    // {
    //     title: 'option2',
    //     path: '/option2',
    //     icon: 'users', // <FaIcons.FaTasks />
    // },
    // {
    //     title: 'option3',
    //     path: '/option3',
    //     icon: 'users', // <FaIcons.FaTasks />
    // },
    // {
    //     title: 'Chats',
    //     path: '/chats',
    //     icon: <FaIcons.FaRocketchat />
    // },
    // {
    //     title: 'Analytics',
    //     path: '/analytics',
    //     icon: <FaIcons.FaRegChartBar />
    // }
  ]
  */
}
