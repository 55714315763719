import { useEffect, useMemo, useState } from "react";
import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTextArea } from "mdb-react-ui-kit";
import { shallow } from "zustand/shallow";
import AddItemModal from "../settings/AddItemModal";
import SelectCustom from "../general/SelectCustom";
// import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import AlertMessage from "../general/alertMessage";
import { IPropsRequisicion, useSettingsStore } from "../../store/settingsStore";
import { formatMoney, getStatusColorRequisicion, getTotal, getUniqueId } from "../general/utils";
import { StyledContainerConcepto } from "../general/StylesUtils";
import moment from "moment";
import styled from "@emotion/styled";
import DatosRequisicionesHeader from "./DatosRequisicionesHeader";

const StyledMDBCol = styled(MDBCol)`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper[data-placement^=bottom] {
    position: fixed !important;
    top: 100px !important;
    left: 200px !important;
    padding-top: 0px !important;
    transform: translate(411px, 75px) !important;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    transform: translate(50px, 0px) !important;
  }
`

const getInicialData = (data: any) => {
  const formValue = {
    idRequisicion: null,
    proveedor: '',
    proveedorLabel: '',
    cantidadRequisicion: '',
    repeticionRequisicion: '',
    repeticion: '',
    costoRequisicion: '',
    fecha: new Date(),
  }

  if (data?.id || data?.idRequisicion) {
    const { proveedor, proveedorLabel, fecha, cantidadRequisicion, repeticionRequisicion, repeticion, costoRequisicion, costoReal, idRequisicion = null } = data

      return ({ 
      ...formValue, 
      idRequisicion,
      proveedor,
      proveedorLabel,
      cantidadRequisicion,
      repeticionRequisicion: repeticionRequisicion || repeticion,
      repeticion, 
      costoRequisicion: costoRequisicion || costoReal,
      fecha: fecha ? new Date(parseInt(fecha)*1000) : new Date(),
    })
  }

  return formValue
}

const AddNewFormRequisicion = ({ displayData = true, cantidad=0, showModal = false, setShowModal, typeAction, handleSave, isUpdateStatus = false }: IPropsRequisicion) => {
  const optionsProveedores = useSettingsStore((state) => (state.proveedoresSelect), shallow)
  const [alert, setAlert] = useState<any>(null)
  const [status, setStatus] = useState({})
  const [fechaPago, setFechaPago] = useState(new Date())
  const [descripcion, setDescription] = useState("")
  const maxCantidad = typeAction.option === "Editar" ? cantidad + Number(typeAction.data.cantidadRequisicion) : cantidad
  const [formValue, setFormValue] = useState(getInicialData(typeAction?.data));
  console.log("==> cantidad: ", cantidad)
  console.log( "+++>>>> typeAction-DATA: ", typeAction?.data)
  console.log("++++>>>> displayData: ", displayData)

  const lastStatus = useMemo(() => {
    if (!typeAction.data.statusRequisicion) return ""

    const statusRequisicion = typeAction.data.statusRequisicion
    return statusRequisicion[statusRequisicion.length-1]
  }, [typeAction])

  const saveButtonActive = useMemo(() => {
    console.log("lastStatus::: ", lastStatus)
    lastStatus.status && setStatus(lastStatus.status)
    lastStatus.texto && setDescription(lastStatus.texto)
    lastStatus.date && setFechaPago(lastStatus.date)
    return lastStatus?.status && ["proceso_pago", "pagado", "cancelado"].includes(lastStatus?.status) ; // "rechazado", 
  },[lastStatus])


  console.log("SaveButtonActive >> ", saveButtonActive)
    
  const handleSaveData = async() => {
    const { proveedor, proveedorLabel, costoRequisicion, cantidadRequisicion, repeticionRequisicion, fecha, idRequisicion } = formValue
    console.log("- 55 -> handleSaveData>typeAction: ", typeAction) 
    const lastStatusText = lastStatus?.status || ""
    const idSave = typeAction.option === "Nuevo" || lastStatusText === "rechazado"
      ? `${typeAction.data.id}-${getUniqueId()}` : idRequisicion
    console.log("+ id: ", idSave)
    let statusRequisicion = {}
    let option = typeAction.option

    console.log("--displayData: ", displayData)
    console.log("++>> status >> ", status)
    console.log("++>> fechaPago: ", moment(fechaPago).valueOf() )
    console.log("+> typeAction?.data.uidProyecto: ", typeAction?.data.uidProyecto)
    if (!displayData) {
      const datePago = status === "proceso_pago" ? { date: moment(fechaPago).valueOf()} : {}
      
      statusRequisicion = { status, texto: descripcion, ...datePago }
    }
    let doUpdate = null
    console.log("lastStatusText::::: ", lastStatusText)
    if (lastStatusText === "rechazado") {
      option = "Nuevo"
      doUpdate = {
        idRequisicion,
        statusRequisicion: [...(typeAction?.data?.statusRequisicion || []), {status: "cancelado", descripcion: `new: ${idSave}`}],
      }
      console.log("handleSave > doUpdate: ", doUpdate)
      // await handleSave(typeAction.option, doUpdate )
    }

    const requisicionData = isUpdateStatus ?
    // ( Object.keys(status).length === 0 ) || lastStatusText === "rechazado" 
    {
      idRequisicion: idSave,
      idItem: typeAction.data.idItem,
      proyecto: typeAction.data.proyecto,
      statusRequisicion: [...(typeAction?.data?.statusRequisicion || []), statusRequisicion],
    } :
    {
      uidProyecto: typeAction?.data.uidProyecto,
      idRequisicion: idSave,
      fecha: moment(fecha).format("X"),
      proveedor,
      proveedorLabel,
      medio: typeAction?.data.medio,
      medioLabel: typeAction?.data.medioLabel,
      concepto: typeAction?.data.concepto,
      conceptoLabel: typeAction?.data.conceptoLabel,
      cantidadRequisicion,
      repeticionRequisicion,
      repeticion: typeAction.data.repeticion,
      costoRequisicion,
      statusRequisicion: [{status: "solicitar"}], //TODO-Agregar fecha update
    } 

    console.log("REQUISICION-SAVE-requisicionData > ", requisicionData)
    console.log("REQUISICION-SAVE-OPTION > ", option)
    await handleSave(option, requisicionData, doUpdate )
    setShowModal(!showModal)
  }
    
  const onChange = (e: any) => {
    const { name, value } = e.target
    const { precioUnitario }= typeAction.data
    
    console.log("name >> ", name)

    if (name === "repeticionRequisicion") {
      if (Number(value) > Number(typeAction.data.repeticion) ) {
        return
      }
    } else if (name === "cantidadRequisicion") {
      if (Number(value) > maxCantidad) {
        console.log("HERE > maxCantidad: ", maxCantidad)
        setFormValue({ ...formValue, [name]: maxCantidad })
        return
      }
    } else if(name === "costoReal" && Number(value) > Number(precioUnitario) ) {
      setAlert({
        title: "Alerta", message: `El P.U. Costo no puede ser mayor ${(formatMoney(precioUnitario))}`, type: "danger",
      })
      return
    }
    
    setFormValue({ ...formValue, [name]: value })
  }
    
  const onChangeSelect = (data: any) => {
    const {name, value, label} = data

    console.log("++ onChangeSelect > data: ", data)
    setFormValue({ ...formValue, [name]: value, [`${name}Label`]: label})
  }
    
  const setStartDate = (date: any) => {
    console.log("--> setStartDate - date: ", date)
    setFormValue({...formValue, fecha: date})
  }
  const handleOnChange = (e: any) => {
    const { value } = e.target
    setDescription(value);
  }

console.log("===>> status > ", status)
  const displayNextStatus = () => {
    return (
      <MDBCol md='12' className="txt-align-left">
        <MDBRow className="mt-4">
          <MDBCol md='3' className="txt-align-left text-wrap">
            Siguiente Status: 
          </MDBCol>
          <MDBCol md='4' className="txt-align-left">
            <MDBBtn 
              rounded 
              style={{ padding: "8px 18px", display: "flex", alignItems: "center" }} 
              color="danger"
              disabled={saveButtonActive}
              onClick={() => {
                const value = status === "rechazado" ? "" : "rechazado"
                setStatus(value)
              }}
            >
              { status === "rechazado" && <MDBIcon fas icon='check' size='2x' className="me-2" />}
              <span>Rechazado</span>
            </MDBBtn>
          </MDBCol>
          <MDBCol md='4' className="txt-align-left">
          <MDBBtn 
            rounded style={{ padding: "8px 18px", display: "flex", alignItems: "center" }} 
            color="warning"
            disabled={saveButtonActive}
            onClick={() => {
              const value = status === "proceso_pago" ? "" : "proceso_pago"
              setStatus(value)
            }}
          >
            { status === "proceso_pago" && <MDBIcon fas icon='check' size='2x' className="me-2" />}
            Proceso de Pago
          </MDBBtn>
          </MDBCol>
        </MDBRow>
        { status === "proceso_pago" &&
          <MDBRow className="mt-4">
            <MDBCol md='3'>
              Fecha de Pago:
            </MDBCol> 
            <StyledMDBCol md='3'>
              {/* <ReactDatePicker className="form-control active" withPortal selected={fechaPago} onChange={(data: any) => setFechaPago(data)} /> */}
              <DatePicker 
                className="form-control active" 
                selected={fechaPago} 
                onChange={(data: any) => setFechaPago(data)} 
                customInput={
                  <input
                    readOnly
                    onClick={(e) => e.preventDefault() }
                    placeholder="Selecciona una fecha"
                  />
                }
              />
            </StyledMDBCol> 
          </MDBRow>
        }
        <MDBRow className="mt-4">
          <MDBCol md='12' className="txt-align-left">
            <MDBTextArea 
                label='Comentarios' 
                name='descripcion' 
                value={descripcion} 
                onChange={handleOnChange} rows={3} 
             />
          
          </MDBCol>
        </MDBRow>
      </MDBCol>
      
    )
  }

  const getStatusColor = () => {
    const { colorStyle, statusCustom, lastStatus } = getStatusColorRequisicion(typeAction.data.statusRequisicion)

    return (
      <>
        <MDBBtn rounded style={{ padding: "5px 10px", fontSize: "11px" }} color={colorStyle} onClick={() => { console.log("click en status!!") }} >{statusCustom}</MDBBtn> 
        { lastStatus.status === "rechazado" && <MDBCol md='12' className="txt-align-left"> Comentario: { lastStatus.texto } </MDBCol>}
      </>
    )
  }

  const isDisabled = useMemo(()=> {
    console.log("===> isDisabled - lastStatus: ", lastStatus)
    return ["cancelado"].includes(lastStatus.status)
  },[lastStatus])
    
  const contentForm = () => {
    const { proveedor, cantidadRequisicion, repeticionRequisicion, costoRequisicion, fecha } = formValue
    // console.log("++formValue > ", formValue)
console.log("typeAction.data.statusRequisicion > ", typeAction.data.statusRequisicion)
    return (
      <StyledContainerConcepto className="row" style={{minHeight: "250px"}} isValidated>
        <div>
          <DatosRequisicionesHeader {...{ ...typeAction?.totalData, data: typeAction.data }} />
        </div>
        <MDBRow>
          <MDBCol md='6'>
            <SelectCustom isDisabled={!displayData || isDisabled } options={optionsProveedores} onChangeSelect={onChangeSelect} name='proveedor' defaultValue={proveedor} required />
          </MDBCol> 
          <StyledMDBCol md='6'>
            {/* <ReactDatePicker disabled={!displayData} className="form-control active" withPortal selected={fecha} onChange={setStartDate} /> */}
            <DatePicker 
                className="form-control active" 
                disabled={!displayData || isDisabled}
                selected={fecha} 
                onChange={setStartDate}
                customInput={
                  <input
                    readOnly
                    onClick={(e) => e.preventDefault() }
                    placeholder="Selecciona una fecha"
                  />
                }
              />
          </StyledMDBCol> 
        </MDBRow>
        
        <h5>Datos</h5>
        <MDBRow>
        <MDBCol md='3'>
          <MDBInput disabled={!displayData || isDisabled || lastStatus.status === "rechazado" } label="Cantidad" type='number' name='cantidadRequisicion' value={cantidadRequisicion} onChange={onChange} />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput disabled={!displayData || isDisabled || lastStatus.status === "rechazado" } label="Repeticion" type='number' name='repeticionRequisicion' value={repeticionRequisicion} onChange={onChange} />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput disabled={!displayData || isDisabled} label="Costo Requisicion" type='text' name='costoRequisicion' value={(costoRequisicion)} onChange={onChange} required />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput label="Total" type='text' name='total' value={ getTotal(cantidadRequisicion, repeticionRequisicion, costoRequisicion) } disabled />
        </MDBCol>
        <MDBCol md='12'>
          <AlertMessage alert={alert} setAlert={setAlert}/>
        </MDBCol>
        </MDBRow>
        <MDBRow>
          { displayData ?
          <>
          <MDBCol md='6' className="txt-align-left">
          <span className="fw-bold">Cantidad Maxima: </span>{maxCantidad}
          </MDBCol>
          { typeAction.data?.statusRequisicion && typeAction.data?.statusRequisicion.length 
            && <MDBCol md='6' className="txt-align-right"> 
                <span className="fw-bold">Status Actual: </span>
                {
                  getStatusColor()
                }
                
              </MDBCol> 
          }
          </>:
          displayNextStatus()
          }
        </MDBRow>
      </StyledContainerConcepto>
    )
  }
    
  return (
    <AddItemModal 
      keyModal="modal-servicio" 
      disabledSaveButton={saveButtonActive}
      typeAction={typeAction} 
      showModal={showModal} 
      setShowModal={setShowModal} 
      handleSave={handleSaveData} >
      { contentForm() }
    </AddItemModal>
  )
}
 export default AddNewFormRequisicion;