import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

type MenuProps = {
  showMenu: boolean
}

interface ILi {
  liLabel?: string;
  // b: number;
  liOnClick: () => void;
}

interface IProps {
  iconInfo: { name: string, hiddenArrow: boolean };
  liOptions?: ILi[];
}

// const defaultProps = {
//     liOptions: [],
// }

const StyledContainerRight = styled.div`
`;
const StyledAvatarDropDown = styled.div`
`;

const StyledAction = styled.a``;
const StyledUl = styled.ul<MenuProps>`
    display: ${({ showMenu }) => showMenu ? 'block' : 'none' } !important;
    right: 0;

`;
const StyledLi = styled.li`
  cursor: pointer;

`;

const IconDropDonMenu = ({iconInfo, liOptions = []}: IProps ) => { // & typeof defaultProps
  const [display, setDisplay] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleMouseLeave = () => {
      setDisplay(false);
    };

    const handleMouseEnter = () => {
      setDisplay(true);
    };

    const menuElement = menuRef.current as HTMLElement | null;

    if (menuElement) {
      menuElement.addEventListener('mouseenter', handleMouseEnter);
      menuElement.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        menuElement.removeEventListener('mouseenter', handleMouseEnter);
        menuElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return (
    <StyledContainerRight className="d-flex aStyledLign-items-center">
      <StyledAvatarDropDown className="dropdown">
        <StyledAction
          className={`dropdown-toggle d-flex aStyledLign-items-center ${ iconInfo.hiddenArrow && 'hidden-arrow' }`}
          href='#'
          id="navbarDropdownMenuAvatar"
          role="button"
          data-mdb-toggle="dropdown"
          aria-expanded="false"
          onMouseOver={() => setDisplay(!display) } 
          ref={menuRef}
        >
          {/* <StyledImage
              src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
              className="rounded-circle"
              height="25"
              alt="Black and White Portrait of a Man"
              loading="lazy"
          /> */}
            <i className={`fas ${iconInfo.name}`}></i>
            </StyledAction>
            <StyledUl
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuAvatar"
              showMenu={display} 
              ref={menuRef}
              >
                { liOptions.map( ({ liLabel, liOnClick }) => {
                  return (
                    <StyledLi key={`li-${liLabel}`}  >
                      <StyledAction 
                          className="dropdown-item" 
                          onClick={() => {
                              liOnClick()
                              setDisplay(false) 
                          }}>{liLabel }</StyledAction> 
                    </StyledLi>
                  )})
                }
                  
                {/* <StyledLi>
                    <StyledAction className="dropdown-item" href="#" onClick={() => setDisplay(false) } >Logout</StyledAction>
                </StyledLi> */}
        </StyledUl>
      </StyledAvatarDropDown>
    </StyledContainerRight>
  );
}

export default IconDropDonMenu;
