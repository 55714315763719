import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBTextArea, MDBTooltip, MDBValidation } from "mdb-react-ui-kit";
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderContainer from "../general/HeaderContainer";
import { optionsCiudades } from "../general/ciudades";
import SelectCustom from "../general/SelectCustom";
import { StyledContainerConcepto, StyledStatusRow } from "../general/StylesUtils";
import AddItemModal from "../settings/AddItemModal";
import { CotizacionHeader, roundValue, formatMoney, getPrecioVenta, getTotal, getUniqueId, number2decimals, optionsAction, optionsPorcentajes, optionsStatus, validateRequiredData, unixTimestampToDate, calcularPorcentajeAumento, getPorcentaje, getRequisicionTotal } from "../general/utils";
import DataTableCustom from "../general/DataTableCustom";
import styled from "@emotion/styled";
import { StatusInfo, useSettingsStore } from "../../store/settingsStore";
import { shallow } from "zustand/shallow";
import { proyectoSave } from "../settings/api";

import "react-datepicker/dist/react-datepicker.css";
import AlertMessage from "../general/alertMessage";
import { TableColumn } from "react-data-table-component";
import AddRequisicion from "./AddRequisicion";
import moment from "moment";
import ToolTipCustom from "../general/ToolTipCustom";
import TooltipNewCustom from "../general/tooltipNewCustom";

const StyledRectangule = styled.div`
  border: thin solid lightskyblue;
  padding: 20px;
  border-radius: 5px;
  margin: 5px;
`

const StyledCircleStatus = styled.label`
  min-width: 25px;
  min-height: 25px;
  margin-right: 5px;
`

const StyleInputStepper = styled(MDBInput)`
  padding-right: 10px !important;
`

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
  optionsMedios: any[];
  optionsConceptosCostos: any;
}

type DataRow = {
  medioLabel: string;
  conceptoLabel: string;
  cantidad: string;
  repeticion: string;
  precioUnitario: string;
  costoReal: string;
  status: string;
};

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave, optionsMedios = [], optionsConceptosCostos ={} }: IProps) => {
  const [optionsConceptos, setOptionsConceptos] = useState([])
  const [formValue, setFormValue] = useState({
    id: null,
    medio: '',
    concepto: '',
    descripcion: '',
    cantidad: '',
    repeticion: '',
    precioUnitario: '',
    precioVenta: '',
    porcentaje: '30'
  });
  const cantidadRef = useRef<HTMLInputElement>(null);
  const repeticionRef = useRef<HTMLInputElement>(null);
  const puCostoRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, nextRef: React.RefObject<HTMLInputElement>) => {
    if (event.key === 'Tab' && nextRef.current) {
      console.log("NEXT-REF!! - nextRef.current: ", nextRef.current)
      event.preventDefault(); // Evitar el comportamiento predeterminado del tabulador
      setTimeout(() => {
        console.log("focus!!")
        nextRef.current?.focus();
      }, 200);
    }
  };
  console.log("EDIT > typeAction.data: ", typeAction.data)

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }
    const porcentaje = getPorcentaje(typeAction.data.porcentaje)
    setFormValue({...typeAction.data, porcentaje })

    const conceptosNew = optionsMedios.find(({ value }) => value === typeAction.data.medio )

    setOptionsConceptos(conceptosNew?.conceptos)


  }, [typeAction.data])

  useEffect(() => {
    if(formValue.concepto && optionsConceptos.length) {
      // console.log("Set Value > ")
    }
  }, [optionsConceptos, formValue.concepto])

  const newPorcentaje = useMemo(() => {
    return formValue.porcentaje === "-1" ? roundValue(calcularPorcentajeAumento(Number(formValue.precioUnitario), Number(formValue.precioVenta))) : formValue.porcentaje
  }, [formValue.porcentaje, formValue.precioUnitario, formValue.precioVenta])

  const handleSaveData = () => {
    const {option} = typeAction //  porcentaje === "-1" ? roundValue(calcularPorcentajeAumento(costo, precioVenta)) : 
    // const newPorcentaje = formValue.porcentaje === "-1" ? roundValue(calcularPorcentajeAumento(Number(formValue.precioUnitario), Number(formValue.precioVenta))) : formValue.porcentaje
    
    handleSave(option, {
      ...formValue, 
      porcentaje: newPorcentaje,
      ...{ id: formValue.id ? formValue.id : getUniqueId() } 
    })
    setShowModal(!showModal)
  }

  const getPrecioVentaObject = (porcentaje: string, value: string) => {
    let newConceptoValue ={}
    if(porcentaje === "-1") {
      newConceptoValue = { precioVenta: value }
    } else {
      const porcentajeValue = Number(porcentaje)/100
      const precioVenta = roundValue(getPrecioVenta(value, porcentajeValue))
      console.log("[113]", {porcentajeValue, precioVenta})
      newConceptoValue = { precioVenta }
    }

    return newConceptoValue
  }

  const onChange = (e: any) => {
    console.log("onChange - e: ", e)
    const { name, value, type } = e.target
    let newConceptoValue = {}

    if (name === "precioUnitario") {
      newConceptoValue = getPrecioVentaObject(formValue.porcentaje, value)
    }

    if (value.length > 0 && type === "number" && parseFloat(value) <= 0) return
    
    setFormValue({ ...formValue, [name]: value, ...newConceptoValue })
  }

  const onChangeSelect = (data: any) => {
    const {name, value, label} = data
    let newConceptoValue = {}

    if (name === "medio") {
      newConceptoValue = { concepto: '', [`${name}Label`]: label }
      setOptionsConceptos(data?.dataObject?.conceptos)
    } else if (name === "concepto") {
      newConceptoValue = { precioUnitario: optionsConceptosCostos?.[data.value] || 0, [`${name}Label`]: label }
    } else if (name === "porcentaje") {
      newConceptoValue = getPrecioVentaObject(value, formValue.precioUnitario)
    }

    setFormValue({ ...formValue, [name]: value, ...newConceptoValue})
  }

  const contentForm = () => {
    const { medio, concepto, cantidad, repeticion, precioUnitario, porcentaje, precioVenta, descripcion } = formValue

    return (
      <StyledContainerConcepto className="row"  isValidated>       
        <MDBRow>
          <MDBCol md='6'>
            <SelectCustom options={optionsMedios} onChangeSelect={onChangeSelect} name='medio' defaultValue={medio} required />
          </MDBCol> 
          <MDBCol md='6'>
            <SelectCustom options={optionsConceptos} onChangeSelect={onChangeSelect} name='concepto' defaultValue={concepto} required />
          </MDBCol>
          
        </MDBRow>
        <MDBRow>
          <MDBCol md='12'>
            <MDBTextArea 
              label='Detalles y descripcion' 
              name='descripcion' 
              value={descripcion} 
              onChange={onChange} rows={2} 
               />
          </MDBCol>
        </MDBRow>
        
        <h5>Costo Presupuesto</h5>
        <MDBRow>
        <MDBCol md='3'>
          <StyleInputStepper tabIndex={0} label="Cantidad" type='number' name='cantidad' value={cantidad} onChange={onChange} required ref={cantidadRef}
            onKeyDown={(e) => handleKeyDown(e, repeticionRef)} />
        </MDBCol>
        <MDBCol md='3'>
          <StyleInputStepper tabIndex={0} label="Repeticion" type='number' name='repeticion' value={repeticion} onChange={onChange} required ref={repeticionRef} onKeyDown={(e) => handleKeyDown(e, puCostoRef)} />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput tabIndex={0} label="P.U. Costo" type='text' name='precioUnitario' value={(precioUnitario)} onChange={onChange} required ref={puCostoRef} />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput tabIndex={-1} label="Total" type='text' name='total' value={ getTotal(cantidad, repeticion, precioUnitario) } onChange={onChange} required />
        </MDBCol>
        </MDBRow>

        <MDBRow className='align-items-center'>
          <MDBCol md='3'>
            <SelectCustom options={optionsPorcentajes} onChangeSelect={onChangeSelect} name='porcentaje' hiddeLabel defaultValue={porcentaje} required />
          </MDBCol>
          <MDBCol size="auto">
            <span id='textExample2' className='form-text'>
              Markup { formValue.porcentaje === "-1" && ` > ${ newPorcentaje }%` }
            </span>
          </MDBCol>
          <MDBCol size="auto" className="justify-content-end text-danger">
            { (Number(precioVenta) < Number(precioUnitario)) &&
              <>
                <MDBIcon fas icon='exclamation-circle mx-2' size='sm' />
                <span>Warning: Costo de Venta es menor al Costo Presupuesto</span>
              </>
            }
          </MDBCol>
        </MDBRow>

        <h5>Costo a la Venta</h5>
        <MDBRow>
        <MDBCol md='3'>
          <StyleInputStepper tabIndex={-1} label="Cantidad" type='number' name='cantidad' value={cantidad} onChange={onChange} disabled />
        </MDBCol>
        <MDBCol md='3'>
          <StyleInputStepper tabIndex={-1} label="Repeticion" type='number' name='repeticion' value={repeticion} onChange={onChange} disabled />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput tabIndex={porcentaje !== '-1' ? -1 : 0} label="P.U. Venta" type='text' name='precioVenta' value={ (precioVenta) } onChange={onChange} disabled={ porcentaje !== '-1'} />
        </MDBCol>
        <MDBCol md='3'>
          <MDBInput tabIndex={-1} label="Total" type='text' name='total' value={ getTotal(cantidad, repeticion, precioVenta) } onChange={onChange} disabled />
        </MDBCol>
        </MDBRow>
      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal keyModal="modal-servicio" typeAction={typeAction} showModal={showModal} setShowModal={setShowModal} handleSave={handleSaveData} >
      { contentForm() }
    </AddItemModal>
  )
}

const Cotizacion = () => {
  const proyectosServer = useSettingsStore((state) => (state.proyectos), shallow)
  const cadenasServer = useSettingsStore((state) => (state.cadenas), shallow)
  const optionsClientes = useSettingsStore((state) => (state.clientesSelect), shallow)
  const optionsCadenas = useSettingsStore((state) => (state.cadenasSelect), shallow)
  const optionsLineasNegocio = useSettingsStore((state) => (state.lineasNegocioSelect), shallow)
  const optionsMediosServer = useSettingsStore((state) => (state.mediosSelect), shallow)
  const optionsEjecutivos = useSettingsStore((state) => (state.usersEjecutivosSelect), shallow)
  const { option: optionsUrl, id: idUrl } = useParams() || "new";
  const [showModal, setShowModal] = useState(false);
  const [showModalRequisicion, setShowModalRequisicion] = useState(false);
  const [typeAction, setTypeAction] = useState({})
  const [items, setItems] = useState<any[]>([])
  const [optionsMedios, setOptionsMedios] = useState<any[]>([])
  const [optionsConceptosCostos, setOptionsConceptosCostos] = useState({})
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)
  const [nombreProyectoAlias, setNombreProyectoAlias] = useState({lineaNegocio: "", cliente: ""})
  const [statusInfo, setStatusInfo] = useState<StatusInfo[]>([])
  const [formValue, setFormValue] = useState({
    uid: '',
    name: '',
    status: '',
    user: '',
    lineaNegocio: '',
    cadena: '',
    cliente: '',
    descripcion: '',
    terminado: false,
  });

  const [requiredStatus, setRequiredStatus] = useState({
    name: false,
    status: false,
    user: false,
    lineaNegocio: false,
    cadena: false,
    cliente: false,
  })

  const headers = [
    { label: 'Medio', name: 'medioLabel', required: true},
    { label: 'Concepto', name: 'conceptoLabel', required: true},
    { label: 'Cantidad', name: 'cantidad'},
    { label: 'Repeticion', name: 'repeticion'},
    { label: 'P.U. Costo', name: 'precioUnitario', 
      cell: (item: any) => {
        const { precioUnitario } = item
        return (<span className="text-danger"> == { formatMoney(precioUnitario) } </span>) 
    }},
    { label: 'Total', name:"totalCosto", customItem: (item: any) => {
      const { cantidad, repeticion, precioUnitario } = item
      return getTotal(cantidad, repeticion, precioUnitario)
    }},
    { label: 'Porcentaje', name: 'porcentaje', customItem: (item: any) => {
      const { porcentaje } = item
      return `${ porcentaje }%`
    }},
    { label: 'P.U. Venta', name: 'precioVenta', customItem: (item: any) => {
        const {precioVenta} = item // precioUnitario, porcentaje
        // const porcentajeValue = Number(porcentaje)/100
        // const puVenta = getPrecioVenta(precioUnitario, porcentajeValue)
        return formatMoney(precioVenta)
    }},
    { label: 'Total Venta', name: 'totalVenta',customItem: (item: any) => {
      const { cantidad, repeticion, precioVenta} = item // , precioUnitario, porcentaje
      // const porcentajeValue = Number(porcentaje)/100
      // const puVenta = getPrecioVenta(precioUnitario, porcentajeValue)
      return <span className="fw-bold"> { getTotal(cantidad, repeticion, precioVenta)}  </span>
    }}
  ];
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Medio",
      cell: (row: { medioLabel: any; }) => (<span className="fw-bold">{ row.medioLabel} </span> ),
      sortable: true
    },
    {
      name: "Concepto",
      cell: (row: { conceptoLabel: any; }) => (<span className="fw-bold">{ row.conceptoLabel } </span> ),
      sortable: true
    },
    {
      name: "Cantidad",
      selector: (row: { cantidad: any; }) => row.cantidad,
      sortable: true,
    }, 
    {
      name: "Repeticion",
      selector: (row: { repeticion: any; }) => row.repeticion,
      sortable: true,
    }, 
    {
      name: "P.U. Costo",
      cell: (row: any) => {
        const { precioUnitario, precioVenta } = row;
        //console.log("P.U. Costo:: ", { precioUnitario, precioVenta } )
        return <span className={(Number(precioVenta) < Number(precioUnitario) ) ? "text-danger" : "text-dark"}>{ formatMoney(precioUnitario) }</span> },
      sortable: true,
    }, 
    { 
      name: 'Total', 
      cell: (row: any) => {
      const { cantidad, repeticion, precioUnitario } = row
      return ( <span className="fw-bold">{ getTotal(cantidad, repeticion, precioUnitario) } </span> )
    }},
    { 
      name: 'Porcenjate', 
      cell: (row: any) => {
        const { porcentaje, precioUnitario, precioVenta } = row

        return <span className={(Number(precioVenta) < Number(precioUnitario) ) ? "text-danger" : "text-dark"}>
            { `${ porcentaje }%` }
            { (Number(precioVenta) < Number(precioUnitario) ) && <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title={"Warning: Costo de Venta es menor al Costo Presupuesto"}> <MDBIcon fas icon='exclamation-circle mx-2' size='sm' /> </MDBTooltip> }
          </span>
    }},
    { 
      name: 'P.U. Venta', 
      cell: (row: any) => {
        const { precioUnitario, precioVenta } = row // precioUnitario, porcentaje
        // const porcentajeValue = Number(porcentaje)/100
        // const puVenta = getPrecioVenta(precioUnitario, porcentajeValue)
        return <span className={(Number(precioVenta) < Number(precioUnitario) ) ? "text-danger" : "text-dark"}>{ formatMoney(precioVenta) }</span> 
    }},
    { 
      name: 'Total Venta', 
      cell: (row: any) => {
        const { cantidad, repeticion, precioVenta } = row //  precioUnitario, porcentaje
        // const porcentajeValue = Number(porcentaje)/100
        // const puVenta = getPrecioVenta(precioUnitario, porcentajeValue)
        return  (<span className="fw-bold"> {getTotal(cantidad, repeticion, precioVenta) } </span> )
    }},
    { 
      name: 'Costo Real', 
      selector: (row: any) => {
        // formatMoney(row.costoReal) || "-"
        return row.requisiciones ? formatMoney(getRequisicionTotal(row.requisiciones)) : "0.00"
      },
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ];

  useEffect(() => {
    if(optionsUrl === "edit") {
      // console.log("EDIT > proyectosServer: ", proyectosServer)
      const data = proyectosServer.find(({uid}) => uid === idUrl )
  
      // console.log("EDIT > data: ", data)
      setStatusInfo(data?.statusInfo || [])
      setItems(data?.items || [])
      setFormValue({
        uid: data?.uid || "",
        name: data?.name || "",
        status: data?.status || "",
        user: data?.user || "",
        lineaNegocio: data?.lineaNegocio || "",
        cadena: data?.cadena || "",
        cliente: data?.cliente || "",
        descripcion: data?.descripcion || "",
        terminado: data?.terminado || false,
      })

      setIsValidateFine(true)
      setRequiredStatus({
        name: true,
        status: true,
        user: true,
        lineaNegocio: true,
        cadena: true,
        cliente: true,
      })
      setMediosYConseptosDataToItems(data?.cadena || "")
    }
  }, [optionsUrl, idUrl, proyectosServer])

  const actionsDataTable = (data: any) => {
    return (
       formValue.status !== "ganado" ? 
      <>
        <MDBBtn tag='a' color='none' disabled className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("Servicio", "Edit", data)} >
          <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' disabled className='m-1' style={{ color: '#3b5998' }} onClick={() => handleItemDelete(data.id)} >
          <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
      : 
      <MDBBtn className='ms-2' tag='a' color='secondary' floating  
        onClick={() => {
          // setMedioParent(medio)
          handlItemActionRequisicion("Requisicion", "", data)
        }} >
        <MDBIcon fas icon='plus' size='sm' />
      </MDBBtn>
    )
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    setFormValue({ ...formValue, [name]: value });
  }

  const getProyName = useCallback((name: string, label: string) => {
    let alias = ""
    let proyName = ""

    if (name === CotizacionHeader.LineaNegocio) {
      alias = `${label.substring(0,3)}-`
      proyName = `${ alias }${ nombreProyectoAlias?.cliente || ""}`
    }

    if (name === CotizacionHeader.Cliente) {
      alias = `${label.trim()}-`
      proyName = `${ nombreProyectoAlias?.lineaNegocio || ""}${ alias }`
    }

    setNombreProyectoAlias({ ...nombreProyectoAlias, [name]: alias })
    return proyName
  },[nombreProyectoAlias, setNombreProyectoAlias])

  const setMediosYConseptosDataToItems = (value: string) => {
    const cadenaCostosData = (cadenasServer.find((items) => items.uid === value ))?.costos
    let costosConceptos: any[] = []

    const mediosNew = optionsMediosServer.filter(({value}) => {
      const vv = cadenaCostosData?.find((items: { medio: string; }) =>  items.medio === value)

      vv && costosConceptos.push([vv.concepto, vv.precioBase])
      return vv
    })

    setOptionsMedios(mediosNew)
    setOptionsConceptosCostos( Object.fromEntries(costosConceptos) || {})
  }

  const onChangeSelect = (data: any) => {
    const { required, name, value, label } = data

    if(name === "cadena") {
      setMediosYConseptosDataToItems(value)
    }
    // console.log("[428] statusInfo > ", statusInfo)
    let obj = statusInfo
    if (name === "status") {
      if(statusInfo.length && statusInfo[statusInfo.length - 1]?.date === "") {
        obj[obj.length - 1] = { status: value, date: "" }
      } else {
        obj = [...statusInfo, {status: value, date: ""}]
      }
      setStatusInfo(obj)
    }

    let proyName = ([CotizacionHeader.LineaNegocio, CotizacionHeader.Cliente].includes(name))  ? { name: getProyName(name, label) } : {}
    
    setFormValue({ ...formValue, [name]: value, ...proyName })
    isValidate({ target: { name, value, required }})
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    console.log(" + typeName: ", typeName)
    console.log(" + option: ", option)
    console.log(" + data: ", data)

    setShowModal(true);
    setTypeAction({ typeName, option, data })
  }

  const handlItemActionRequisicion = (typeName: string, option: string, data?: any) => {
    console.log(" + typeName: ", typeName)
    console.log(" + option: ", option)
    console.log(" + data: ", data)

    setShowModalRequisicion(true)
    setTypeAction({ typeName, option, data })
  }

  const handleItemDelete = (id: any) => {
    const newItems = items.filter((item) => item.id !== id)

    setItems(newItems)
  }

  const handleSave = async(option: string, data: any) => {
    console.log("[557] - handleSave-data:: ", data)
    if(option === 'Nuevo')
      setItems([...items, data])
    else
      setItems( items.map((items) => {
        console.log("==>> SAVE: ", {itemId: items.id, dataId: data.id })
        return items.id === data.id ? data : items
      } ) )
  }

  const handleClose = () => {
    window.location.href = "/cotizaciones"
  }

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        // setShowModal(!showModal)
        // handleSave()
        window.location.href = `/cotizaciones`
      },1500 )
    }
  }
// console.log("==>> items: ", items)
  const handleSubmit = async() => {
    try {
      setSaving(true)
      const { uid, name, status, cadena } = formValue

      if (status === "perdido" && !formValue?.descripcion.trim()) {
        setFormValue({ ...formValue, descripcion: "" });
        return
      }

      const statusInfoReview = [...statusInfo];
      const statusInfoReviewLast = statusInfoReview[statusInfoReview.length - 1];

      if (statusInfoReview.length && !statusInfoReviewLast?.date) {
        statusInfoReviewLast.date = moment().format("X");
        statusInfoReview[statusInfoReview.length - 1] = statusInfoReviewLast;
      }

      const payload = {
        type: status,
        name,
        uidMedio: cadena,
        ...{ uid },
        detalles: { ...formValue, items, totalCosto: getTotalCosto, totalVenta: getTotalVenta, statusInfo: statusInfoReview },
      }
// console.log("payload:: ", payload)
      await proyectoSave(payload)

       showAlert({
         title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const contentStatusInfo = () => {
    return (
      <>
        <StyledStatusRow key="status-head">
          <div className="bold">Status</div>
          <div className="bold">Fecha</div>
        </StyledStatusRow>
        {statusInfo.map((info, index) => (
        <StyledStatusRow key={index}>
          <div>{info.status}</div>
          <div className="margin">{ unixTimestampToDate(info.date) }</div>
        </StyledStatusRow>
      ))}
      </>
    )
  }

  const formDataComponenets = () => {
    const { name, status, user, lineaNegocio, cadena, cliente } = formValue;
    return (
      <MDBContainer>
        <MDBRow className='mb-3' >
        <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
          <MDBCol md='3' className="px-2">
            <SelectCustom options={optionsLineasNegocio} onChangeSelect={onChangeSelect} name='lineaNegocio' label='Linea de Negocio' defaultValue={lineaNegocio} required />
          </MDBCol>
          <MDBCol md='3' className="px-2">
            <SelectCustom options={optionsClientes} onChangeSelect={onChangeSelect} name='cliente' defaultValue={cliente} required />
          </MDBCol>    
          <MDBCol md='6' className="px-2">
            <MDBInput wrapperClass='mb-4' label='Nombre del Proyecto' name="name" value={name} onChange={onChange} onBlur={isValidate} required/>
          </MDBCol>
          <MDBCol md='4' className="px-2">
            <SelectCustom options={optionsStatus} onChangeSelect={onChangeSelect} name='status' defaultValue={status} isDisabled={status === "ganado"} required />
            <TooltipNewCustom
              buttonContent={<a href="#">Ver historial de status</a> } 
              tooltipContent={ contentStatusInfo() }
            />
          </MDBCol>
          <MDBCol md='4' className="px-2">
            <SelectCustom options={optionsEjecutivos} onChangeSelect={onChangeSelect} name='user' label='Ejecutivo' defaultValue={user} required />
          </MDBCol>

          <MDBCol md='4' className="px-2">
            <SelectCustom options={optionsCadenas} onChangeSelect={onChangeSelect} name='cadena' defaultValue={cadena} required />
          </MDBCol>
          
          </StyledContainerConcepto>
        </MDBRow>
        <MDBRow className='mt-5'>
          <div className="d-flex justify-content-end">
          <MDBBtn 
            outline rounded 
            className='mx-2' color='secondary' 
            {...(!cadena && {disabled: true})}
            onClick={() => handlItemAction("servicio", "Nuevo")}  >+ Agregar Servicio</MDBBtn>
        </div>
        </MDBRow>
      </MDBContainer>
    )
  }

  const getTotalVenta = useMemo(() => {
      if (!items.length) return 0.00
      const total = items.map(item => {
        const tot = Number(getTotal(item.cantidad, item.repeticion, item.precioVenta, false))
        return tot
      }).reduce((prev, curr) => prev + curr, 0);

      return Number(total)
  }, [items])

  const getTotalCosto = useMemo(() => {
    if (!items.length) return 0.00
    const total = items.map(item => Number(getTotal(item.cantidad, item.repeticion, item.precioUnitario, false))).reduce((prev, curr) => prev + curr, 0);;

    return Number(total)
  }, [items])

  const getGanancia = useMemo(() => {
    const totalVenta = getTotalVenta
    const totalCosto = getTotalCosto

    if (!totalCosto) return 0

    return (((totalVenta - totalCosto) / totalVenta) * 100 )|| 0 //(((totalVenta - totalCosto) / totalCosto) * 100 )|| 0
  }, [getTotalVenta, getTotalCosto])

  const getStatusColor = useMemo(() => {
    if (getGanancia >= 40) return 'success'
    if (getGanancia >= 30) return 'warning'
    if (getGanancia > 15) return 'info'
    if (getGanancia > 0) return 'danger'
    return 'secondary'
  }, [getGanancia])

  const handleTerminar = () => {
    onChange( { target: { name: "terminado", value: true} } )
    
  }

  return (
    <>
    { // optionsAction.includes(optionsUrl) && 
    
        <MDBContainer>
          <MDBValidation isValidated>
          <HeaderContainer 
            title={`Crear Cotizacion`} 
            handleClose={handleClose} 
            handleSave={handleSubmit} 
            alert={alert}
            disabledSaveButton={!isValidateFine || !items.length || formValue.terminado} >
              <div className="p-2">
                { formValue.status === "ganado" &&
                <MDBBtn color='danger' onClick={handleTerminar} disabled={formValue.terminado} > 
                { formValue.terminado && <MDBIcon fas icon='check-circle' size='sm'  className="me-1" /> }
                <span>Terminar</span> 
                </MDBBtn>}
              </div>
            </HeaderContainer>

          { formDataComponenets() }

          <DataTableCustom keyDataTable="table-servicios" columns={columns} data={items} />

          <MDBRow className='mb-3'>
            <MDBCol md='4' className="px-2">
              <StyledRectangule>
              { `Costo Presupuesto Total: ${formatMoney(getTotalCosto)} ` }
              </StyledRectangule>
            </MDBCol>
            <MDBCol md='4' className="px-2">
              <StyledRectangule>
                { `Costo a la Venta Total: ${formatMoney(getTotalVenta)} ` }
              </StyledRectangule>
            </MDBCol>
            <MDBCol md='4' className="px-2">
              <StyledRectangule className=" d-flex align-items-center justify-content-center">
                <StyledCircleStatus className={`square rounded-circle bg-${getStatusColor}`} />
                { `Utilidad: ${number2decimals(getGanancia)} %` }
              </StyledRectangule>
            </MDBCol>
          </MDBRow>
          <MDBRow className='mt-4 px-2' style={{textAlign: 'left'}}>
            { formValue.status === "perdido" 
              ? <h5>Indicar los motivos por los cuales cambio al status 'Perdido':</h5> 
              : <h5>Comentarios que aparecerán en cotización:</h5> }
          </MDBRow>
          <MDBRow className='mt-1 pb-4 px-2'>
            <MDBTextArea 
              label='Comentarios' 
              name='descripcion' 
              value={formValue.descripcion} 
              onChange={onChange} rows={3} 
              required={formValue.status === "perdido"} />
          </MDBRow>

          {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} optionsMedios={optionsMedios} optionsConceptosCostos={optionsConceptosCostos} ></AddNewForm>}

          {showModalRequisicion && <AddRequisicion showModal={showModalRequisicion} setShowModal={setShowModalRequisicion} typeAction={typeAction} handleSave={handleSave} /> }
          </MDBValidation>
        </MDBContainer>
    }
    </>
  );
}

export default Cotizacion;
