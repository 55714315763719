import React, { useEffect, useState } from 'react';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput, MDBValidationItem } from 'mdb-react-ui-kit';
import { StyledContainerConcepto } from "../general/StylesUtils";
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import { settings } from './api';
import { validateRequiredData } from '../general/utils';
import AddItemModal from './AddItemModal';
import DataTableCustom from '../general/DataTableCustom';
import DatosContactoDataTable from '../general/DatosContactoDataTable';
import DatosCostosDataTable from '../general/DatosCostosDataTable';
import Loading from '../general/loading';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { TableColumn } from 'react-data-table-component';

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}
type DataRow = {
  name: string;
  rfc: string;
  numeroCuenta: string;
  banco: string;
};

type DataRowCostos = {
  medioLabel: string;
  conceptoLabel: string;
  precioBase: string;
};

type DataRowContacto = {
  contacto: string;
  telefono: string;
  correo: string;
  puesto: string;
};

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {

  const [formValue, setFormValue] = useState({
    uid: null,
    name: '',
    razonSocial: '',
    rfc: '',
    cfdi: '',
    numeroCuenta: '',
    banco: '',
    costos: [], 
    contactos: [],
  });

  const [_, setRequiredStatus] = useState({
    name: false,
    costos: false,
  })

  const [isDatosFiscalesRequired, setIsDatosFiscalesRequired] = useState(false)
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  const headersCostos = [
    { label: 'Medio', name: 'medioLabel', required: true },
    { label: 'Concepto', name: 'conceptoLabel', required: true },
    { label: 'Precio Base', name: 'precioBase', required: true },
  ];

  const columnsCostos: TableColumn<DataRowCostos>[] = [
      {
        name: "Medio",
        selector: (row: { medioLabel: any; }) => row.medioLabel,
        sortable: true
      },
      {
        name: "Concepto",
        selector: (row: { conceptoLabel: any; }) => row.conceptoLabel,
        sortable: true
      },
      {
        name: "Precio Base",
        selector: (row: { precioBase: any; }) => row.precioBase,
        sortable: true
      },
  ];

  const headers = [
    { label: 'Contacto', name: 'contacto', required: true},
    { label: 'Telefono', name: 'telefono', required: true},
    { label: 'Correo', name: 'correo'},
    { label: 'Puesto o Roll', name: 'puesto'},
  ];

  const columnsContacto: TableColumn<DataRowContacto>[] = [
    {
      name: "Contacto",
      selector: (row: { contacto: any; }) => row.contacto,
      sortable: true
    },
    {
      name: "Telefono",
      selector: (row: { telefono: any; }) => row.telefono,
      sortable: true
    },
    {
      name: "Correo",
      selector: (row: { correo: any; }) => row.correo,
      sortable: true
    },
    {
      name: "Puesto o Roll",
      selector: (row: { puesto: any; }) => row.puesto,
      sortable: true
    },
];

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

    setFormValue({...typeAction.data})
    setIsValidateFine(true)
    setRequiredStatus({
      name: true,
      costos: true,
    })
  }, [typeAction.data])

  const handleSaveData = async() => {
    try {
      setSaving(true)
      setIsValidateFine(false)
      const { uid, name } = formValue

      const payload = {
          type: "cadena",
          name,
          ...{ uid },
          detalles: { ...formValue },
        }

      await settings(payload)

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    
    if(['razonSocial', 'rfc', 'cfdi'].includes(name)) setIsDatosFiscalesRequired(value)

    setFormValue({ ...formValue, [name]: value });
  };

  const onChangeSelect = (data: any) => {
    const { name, value } = data
    console.log("DATA >>> ", data)

    isValidate({ target: { name, value, required: true }} )
    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const constentForm = () => {
    const { name, razonSocial, rfc, numeroCuenta, banco, costos, contactos } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='name' value={name} onChange={onChange} onBlur={isValidate} required />

        <h5>Datos Costos</h5>
        <DatosCostosDataTable columns={columnsCostos} headers={headersCostos} dataDefault={costos} handleSaveContactos={onChangeSelect} />
 
        <h5>Datos de Contacto</h5>
        <DatosContactoDataTable columns={columnsContacto} headers={headers} dataDefault={contactos} handleSaveContactos={onChangeSelect} />

        <h5>Datos de Facturacion</h5>
        <MDBInput label="Razon Social" type='text' name='razonSocial' value={razonSocial} onChange={onChange} { ...{required: isDatosFiscalesRequired } } />
        <MDBInput label="RFC" type='text' name='rfc' value={rfc} onChange={onChange}  { ...{required: isDatosFiscalesRequired } } />
        <MDBValidationItem feedback='Adjuntar archivo CFDI' { ...{invalid: isDatosFiscalesRequired } } >
          <input type='file' name='cfdi' className='form-control' onChange={onChange} { ...{required: isDatosFiscalesRequired } } title='Archivo CFDI' />
        </MDBValidationItem>
        
        <h5>Datos de Pago</h5>
        <MDBInput label="Numero de cuenta" type='text' name='numeroCuenta' value={numeroCuenta} onChange={onChange} />
        <MDBInput label="Banco" type='text' name='banco' value={banco} onChange={onChange} />

      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal 
      keyModal="modal-cadena"
      alert={alert} 
      typeAction={typeAction} 
      showModal={showModal} 
      setShowModal={setShowModal} 
      handleSave={handleSaveData} 
      disabledSaveButton={!isValidateFine} 
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const CadenasSetting = () => {
  const cadenasServer = useSettingsStore((state) => (state.cadenas), shallow)
  const {getCadenasServer} = useSettingsStore()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [typeAction, setTypeAction] = useState({})
  const [cadenas, setCadenas] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false)
    setCadenas(cadenasServer)
  }, [cadenasServer])


  const columns: TableColumn<DataRow>[] = [
    {
      name: "Cadena",
      selector: (row: { name: any; }) => row.name,
      sortable: true
    },
    {
      name: "RFC",
      selector: (row: { rfc: any; }) => row.rfc,
      sortable: true
    },
    {
      name: "Numero de Cuenta",
      selector: (row: { numeroCuenta: any; }) => row.numeroCuenta,
      sortable: true
    },
    {
      name: "Banco",
      selector: (row: { banco: any; }) => row.banco,
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ];

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("cadena", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("cadena", "Delete", data)} >
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }
  

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }

    setTypeAction({ typeName, option, data })
  }

  const handleSave = async() => {
    setLoading(true)
    getCadenasServer()
  }

  return (
    <MDBContainer>
      <h4> Cadenas </h4>
      { (loading) ? 
          <Loading />
          :
          <>
            <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
              <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("cadena", "Nuevo")} >+ Agregar Cadena</MDBBtn>
            </div>
        
          <DataTableCustom keyDataTable="table-cadenas" columns={columns} data={cadenas} />
        </>
      }
      
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default CadenasSetting;