import { MDBSpinner } from "mdb-react-ui-kit";

const Loading = () => {
    return (
      <MDBSpinner className='me-2 mt-4' style={{ width: '3rem', height: '3rem' }}>
        <span className='visually-hidden'>Loading...</span>
      </MDBSpinner>
    )
}
export default Loading;
