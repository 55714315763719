import { useEffect, useState } from 'react';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput, MDBTextArea, MDBValidationItem } from 'mdb-react-ui-kit';
import { optionsCiudades } from '../general/ciudades';
import { StyledContainerConcepto } from "../general/StylesUtils";
import { useSettingsStore } from "../../store/settingsStore";
import { settings } from './api';
import AddItemModal from './AddItemModal';
import SelectCustom from '../general/SelectCustom';
import DataTableCustom from '../general/DataTableCustom';
import DatosContactoDataTable from '../general/DatosContactoDataTable';
import shallow from "zustand/shallow";
import Loading from '../general/loading';
import { validateRequiredData } from '../general/utils';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { TableColumn } from 'react-data-table-component';

// interface IOptions {
//   value: string; 
//   id: number;
// }
// interface IData {
//    conceptos?: IOptions[]
// }
// type IAllData = IOptions & IData;

type DataRowContacto = {
  contacto: string;
  telefono: string;
  correo: string;
  puesto: string;
};

type DataRow = {
  name: string;
  ciudad: string;
  numeroCuenta: string;
  banco: string;
};

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const optionsMedios = useSettingsStore((state) => (state.mediosSelect), shallow)
  const [optionsConceptos, setOptionsConceptos] = useState<any[]>([])

  const [formValue, setFormValue] = useState({
    uid: null,
    name: '',
    ciudad: '',
    medio: '',
    concepto: '',
    razonSocial: '',
    rfc: '',
    cfdi: '',
    detalles: '',
    numeroCuenta: '',
    banco: '',
    contactos: [],
  });

  const [_, setRequiredStatus] = useState({
    name: false,
    ciudad: false,
    medio: false,
    concepto: false,
    razonSocial: false,
    rfc: false,
    numeroCuenta: false,
    banco: false,
  })

  const [isDatosFiscalesRequired, setIsDatosFiscalesRequired] = useState(false);
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)

  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  const headers = [
    { label: 'Contacto', name: 'contacto', required: true},
    { label: 'Telefono', name: 'telefono', required: true},
    { label: 'Correo', name: 'correo'},
    { label: 'Puesto o Roll', name: 'puesto'},
  ];
 
  const columns: TableColumn<DataRowContacto>[] = [
    {
      name: "Concepto",
      selector: row => row.contacto,
      sortable: true
    },
    {
      name: "Telefono",
      selector: row => row.telefono,
      sortable: true
    },
    {
      name: "Correo",
      selector: row => row.correo,
      sortable: true
    },
    {
      name: "Puesto o Roll",
      selector: row => row.puesto,
      sortable: true
    },
  ]

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

    setFormValue({...typeAction.data})
    setIsValidateFine(true)
    setRequiredStatus({
      name: true,
      ciudad: true,
      medio: true,
      concepto: true,
      razonSocial: true,
      rfc: true,
      numeroCuenta: true,
      banco: true,
    })

    if (typeAction.data?.concepto) {
      const dataObject = optionsMedios.find(({value}) => value === typeAction.data?.medio)

      setOptionsConceptos(dataObject?.conceptos || [])
    }
  }, [typeAction.data])

  // const isValidate = (e: { target: any; }) => {
  //   const data = e.target
  //   const { name, value, required } = data

  //   if(!required) return
   
  //   setRequiredStatus((data: any) => {
  //     const newData = {
  //       ...data,
  //       [name]: !!value.trim(),
  //     }

  //     const validate = Object.values(newData).find((value) => !value )

  //     setIsValidateFine(validate === undefined)
  //     return newData
  //   })
  // }
  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const handleSaveData = async() => {
    try {
      setSaving(true)
      setIsValidateFine(false)
      const { uid, name } = formValue

      const payload = {
          type: "proveedor",
          name,
          ...{ uid },
          detalles: { ...formValue },
        }

      await settings(payload)

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    
    if(['razonSocial', 'rfc', 'cfdi'].includes(name)) setIsDatosFiscalesRequired(value)

    setFormValue({ ...formValue, [name]: value });
  };

  const onChangeSelect = (data: any) => {
    const {required, name, value, dataObject} = data
    if (name === "medio") {
      setOptionsConceptos(dataObject?.conceptos)
    }
    setFormValue({ ...formValue, [name]: value });

    isValidate({ target: { name, value, required }})
  }

  const constentForm = () => {
    const { name, ciudad, medio, concepto, razonSocial, rfc, detalles, numeroCuenta, banco, contactos } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='name' value={name} onChange={onChange} onBlur={isValidate} required />

        <SelectCustom options={optionsCiudades} onChangeSelect={onChangeSelect} name='ciudad' defaultValue={ciudad} required />
        
        <SelectCustom options={optionsMedios} onChangeSelect={onChangeSelect} name='medio' defaultValue={medio} required />

        <SelectCustom options={optionsConceptos} onChangeSelect={onChangeSelect} name='concepto' defaultValue={concepto} required />

        
        <h5>Datos de Contacto</h5>
        <DatosContactoDataTable columns={columns} headers={headers} dataDefault={contactos} handleSaveContactos={onChangeSelect} />

        <h5>Datos de Facturacion</h5>
        <MDBInput label="Razon Social" type='text' name='razonSocial' value={razonSocial} onChange={onChange} required onBlur={isValidate} />
        <MDBInput label="RFC" type='text' name='rfc' value={rfc} onChange={onChange} required onBlur={isValidate} />
        <MDBValidationItem feedback='Adjuntar archivo CFDI' { ...{invalid: isDatosFiscalesRequired } } >
          <input type='file' name='cfdi' className='form-control' onChange={onChange} { ...{required: isDatosFiscalesRequired } } title='Archivo CFDI' />
        </MDBValidationItem>
        <MDBTextArea label='Detalles del proveedor' name='detalles' value={detalles} onChange={onChange} rows={4} />
        
        <h5>Datos de Pago</h5>
        <MDBInput label="Numero de cuenta" type='text' name='numeroCuenta' value={numeroCuenta} onChange={onChange} required onBlur={isValidate} />
        <MDBInput label="Banco" type='text' name='banco' value={banco} onChange={onChange} required onBlur={isValidate} />

      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal
      keyModal="modal-proveedor"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValidateFine}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const ProveedoresSetting = () => {
  const proveedoresServer = useSettingsStore((state) => (state.proveedores), shallow)
  const {getProveedoresServer} = useSettingsStore()
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [typeAction, setTypeAction] = useState({})
  const [proveedores, setProveedores] = useState<any[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    setProveedores(proveedoresServer)
  }, [proveedoresServer])

  const headers = [
    { label: 'Proveedor', name: 'name'},
    { label: 'Ciudad', name: 'ciudad'},
    { label: 'Numero de Cuenta', name: 'numeroCuenta'},
    { label: 'Banco', name: 'banco'},
  ];

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Proveedor",
      selector: row => row.name,
      sortable: true
    },
    {
      name: "Ciudad",
      selector: row => row.ciudad,
      sortable: true
    },
    {
      name: "Numero de Cuenta",
      selector: row => row.numeroCuenta,
      sortable: true
    },
    {
      name: "Banco",
      selector: row => row.banco,
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ]

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("proveedor", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("proveedor", "Delete", data)} >
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }
  
  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }
    
    setTypeAction({ typeName, option, data })
  }

  // const getData = (objectData: IAllData[], data: IAllData) => {
  //   return objectData.map((items) => data.id === items.id ? data : items )
  // }

  const handleSave = async() => {
    setLoading(true)
    getProveedoresServer()
  }

  return (
    <MDBContainer className='h-100'>
      <h4> Proveedores </h4>
      { (loading)
        ? <Loading />
        : <>
            <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
              <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("proveedor", "Nuevo")} >+ Agregar Proveedor</MDBBtn>
            </div>
            <DataTableCustom keyDataTable="table-proveedores" columns={columns} data={proveedores} />
          </>
      }

      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default ProveedoresSetting;
