import { useEffect, useState } from 'react';
import {MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import {NumericFormat} from 'react-number-format';

import DataTableCustom from '../general/DataTableCustom';
import HeaderContainer from '../general/HeaderContainer';
import SelectCustom from '../general/SelectCustom';
import { TableColumn } from 'react-data-table-component';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import { formatMoney } from '../general/utils';
import { flatten } from 'ramda';
import ClonarPresupuesto from './ClonarPrespuesto';
import { presupuestoGet, presupuestoSave, presupuestosNames } from '../settings/api';
import AlertMessage from '../general/alertMessage';

interface Budget {
  [key: string]: any ;
}

const defaultMonths = {months: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }}

const ContainerTotal = ({totalData, label}: any) => {
  return (
    <div className="txt-align-right d-flex flex-row justify-content-end align-items-center " >
      <div style={{ minWidth: "100px",fontSize: '0.8rem', textAlign: 'center' }}> {label} </div>
      <div className='d-flex justify-content-between' style={{ fontSize: '0.8rem'}}>
        {totalData.map((item: any, i: number) => {
          return <div key={i} className='flex-grow-1 text-center' style={{ minWidth: '108px', width: '7.5%', color: item < 0 ? 'red' : 'black' }}>{formatMoney(item)}</div>
        }) }
      </div>
    </div>
  )
} 
const TotalTable = ({totalData, label}: any) => {
  return ( 
  <div className='square rounded-6 border border-info mt-4 mb-5 py-3 w-auto'>
      <ContainerTotal totalData={totalData} label={label} />
    </div>
  )
}

const defaultName = { value: "0", label: "2024" }
const defaultTotales = {ingresos: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], egresos: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }

const Presupuestos = () => {
  const lineasNegocioServer = useSettingsStore((state) => (state.lineasnegocio), shallow);
  const categoriasServer = useSettingsStore((state) => (state.categorias), shallow);
  const [showModal, setShowModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [categorias, setCategorias] = useState<any[]>([]);
  const [lineasNegocio, setLineasNegocio] = useState<any[]>([]);
  const [totalData, setTotalData] = useState<Budget>(defaultTotales); 
  const [utilidadPerdida, setUtilidadPerdida] = useState<number[]>(Array(12).fill(0));
  const [acumulados, setAcumulados] = useState<number[]>(Array(12).fill(0));
  const [typeAction, setTypeAction] = useState<{typeName?: string, option?: string, data?: any }>({});
  const [optionNamePresupuestos, setOptionNamePresupuestos] = useState<any[]>([]);
  const [alert, setAlert] = useState<any>(null)
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const getNames = async() => {
      const names = await presupuestosNames()
      const namesDropDown = names.map(({name, uid}: any) => {
        return {label: name, value: uid}
      })
      // setNamePresupuestosServer(names)
      setOptionNamePresupuestos(namesDropDown.length ? namesDropDown : [defaultName])
    }

    getNames()
  }, [])

  const getDefaultData = (categoriasServer: any, lineasNegocioServer: any) => {
    const lineasNegocio = lineasNegocioServer.map(({ uid, name }: any) => {
      return { uid, name, ...defaultMonths }
    } )
    
    const categorias = categoriasServer.map(({ uid, name, subcategorias }: any) => {
      const subcategoriasWithInputs = subcategorias?.map(({type, name, uid, uidCategoria}: any) => {
        return {// ...subcategoria, 
          type,
          name,
          uid,
          uidCategoria,
          ...defaultMonths,
        } 
      }) || [];
      return [{ uid, name, ...defaultMonths }, ...subcategoriasWithInputs ] // subcategorias: subcategoriasWithInputs
    } )

    
    setCategorias( categorias.length ? flatten(categorias) : [] )
    setLineasNegocio(lineasNegocio)
  }

  useEffect(() => {
    getDefaultData(categoriasServer, lineasNegocioServer)
  }, [categoriasServer, lineasNegocioServer])

  useEffect(() => {
    const newUtilidadPerdida = totalData.ingresos.map((ingreso: number, index: number) => ingreso - totalData.egresos[index])
    setUtilidadPerdida(newUtilidadPerdida)
  }, [totalData]);

  useEffect(() => {
    const newAcumulado = utilidadPerdida.reduce((acc, value, i) => {
      const sum = i === 0 ? value : acc[i - 1] + value;
      return [...acc, sum];
    }, [] as number[]);
    setAcumulados(newAcumulado);
  }, [utilidadPerdida]);

  const resetLoading = () => {
    setTimeout(() => {
      setLoading(false); // Una vez que los datos se han cargado, establecemos loading en false
    }, 3000);
  }
 
  const handleYearChange = async(data: any ) => {
    setLoading(true)
    const { value, label } = data

    setSelectedYear({value, label});

    if (value === "0") {
      getDefaultData(categoriasServer, lineasNegocioServer)
      resetLoading()
    } else {
      const presupuesto = await presupuestoGet(value)

      setLineasNegocio(presupuesto.ingresos)
      setCategorias(presupuesto.egresos)
      setTotalData(presupuesto.total)
      setLoading(false)
    }
  }

  const handleInputChange = (type: string, values: {floatValue: number}, row: Budget, month: number) => {
    if (loading) return
    const data = (type === "ingresos") ? lineasNegocio : categorias;

    const numericValue = values.floatValue;
    let catTotal = 0;
    let indexCategoria = 0;
  
    const newData = data.map((item, index) => {
      if (type === "egresos" && !item.uidCategoria && item.uid === row.uidCategoria) indexCategoria = index
      if (type === "egresos" && item.uidCategoria === row.uidCategoria ) {
        catTotal = catTotal + (item.uid === row.uid ? numericValue : item.months[month])
      }
      if (item.uid === row.uid) {
        // Actualizar 'row' con el nuevo valor
        row = { ...item, months: { ...item.months, [month]: numericValue } };
        return row;
      }
      return item;
    });
    if (type === "ingresos") setLineasNegocio(newData);
    else {
      newData[indexCategoria] = {...newData[indexCategoria], months: { ...newData[indexCategoria].months, [month]: catTotal } }

      setCategorias(newData);
    }

    const totalMes = totalData[type]
    const total = newData.reduce((sum, currentItem) => sum + (((type === "egresos" && !currentItem.uidCategoria) ? 0 : currentItem.months[month]) || 0), 0);

    totalMes[month] = total

    setTotalData({ ...totalData, [type]: totalMes });
  }

  const columnsIngresos: TableColumn<Budget>[] = [
    {
      name: 'Ingresos',
      selector: row => row.name, //row.Ingresos,
    },
    ...Array.from({length: 12}, (_, month) => {
      const monthLabel = new Date(2024, month).toLocaleString('default', { month: 'long' });
      return {
        name: monthLabel,
        cell: (row: Budget) => {
          return (
          <NumericFormat 
          value={row.months[month]} 
          onValueChange={(values: any) => handleInputChange("ingresos", values, row, month)}
          thousandSeparator={true}
          valueIsNumericString={true}
          allowNegative={false}
          customInput={MDBInput}
          prefix="$"
          style={{ fontSize: '0.8rem'}}
        />
        )}
      };
    })
  ];

  const columnsEgresos: TableColumn<Budget>[] = [
    {
      name: 'Egresos',
      cell: row => {
        return (<div className={ row.uidCategoria ? "" : "fw-bold" } >{row.name}</div>)}, // row.Egresos,
      wrap: true,
    },
    ...Array.from({length: 12}, (_, month) => {
      const monthLabel = new Date(2024, month).toLocaleString('default', { month: 'long' });
      return {
        name: monthLabel,
        cell: (row: Budget) => {
          // Si la fila corresponde a una subcategoría, incluir un input
          if (row.uidCategoria) {
            return (
              <NumericFormat 
                value={row.months[month]} 
                onValueChange={(values: any) => handleInputChange("egresos",values, row, month)}
                thousandSeparator={true}
                valueIsNumericString={true}
                allowNegative={false}
                customInput={MDBInput}
                prefix="$"
                style={{ fontSize: '0.8rem'}}
              />
            );
          }
          // Si la fila no corresponde a una subcategoría, mostrar el valor
          else {
            return <div className="fw-bold"><NumericFormat value={row.months[month]} displayType="text" prefix="$" thousandSeparator={true} /></div>
          }
        }
      };
    })
  ];

  const handleSaveData = async(inputName: string) => {
    if (typeAction.typeName === "nuevo") {
      setLoading(true)
      setOptionNamePresupuestos([{label: inputName, value: "0"}, ...optionNamePresupuestos])
      setSelectedYear({label: inputName, value: "0"})
      getDefaultData(categoriasServer, lineasNegocioServer)
      setTotalData(defaultTotales)
      resetLoading()
    } else {
      savePresupuesto(inputName, true)
    }
  }

  //? modal handler actions
  const handlItemAction = (typeName: string, option: string) => {
    setTypeAction({ typeName, option, data: { year: typeName === "nuevo" ? null : selectedYear.label } });

    setShowModal(true);
  }

  const savePresupuesto = async(name: string, isClone: boolean) => {
    setAlert({
      title: "Mensaje", message: "Guardando Informacion...", type: "success",
   })

    const payload = {
      name,
      type: "presupuesto",
      ...( !isClone && selectedYear.value !== "0" && { uid: selectedYear.value } ),
      detalles: {
        name: selectedYear.label, 
        ingresos: lineasNegocio,
        egresos: categorias,
        total: totalData,
      }
    }
    // console.log("payload > ", payload)
    const response = await presupuestoSave(payload)
    setAlert(null)

    if (isClone) {
      //console.log("AFTER clone > response: ", response)
      if (response?.success) {
        // console.log("selectedYear >> ", selectedYear)
        setOptionNamePresupuestos([...optionNamePresupuestos, { label: name, value: response.id }])
      }
    } else if (selectedYear.value === "0") {
      const newOptions = optionNamePresupuestos.map(values => values.value === "0" ? {...values, value: response.id } : values )
      // console.log("newOptions >> ", newOptions)
      setSelectedYear({label: name, value: response.id})
      setOptionNamePresupuestos(newOptions)
    }
  }

  const getTablesInformation = () => {
    return (
      <div style={{ overflowX: 'auto' }}>
        <div className="d-flex align-items-start flex-column mb-3">
          <div >
            <h2>INGRESOS</h2>
            <DataTableCustom keyDataTable="ingresos" columns={columnsIngresos} data={lineasNegocio} />

            <TotalTable totalData={totalData.ingresos} label="Total Ingresos" />
          </div>
          <div >
            <h2>EGRESOS</h2>
            <DataTableCustom keyDataTable="egresos" columns={columnsEgresos} data={categorias} />

            <TotalTable totalData={totalData.egresos} label="Total Egresos" />
          </div>
          <div >
            <div className='square rounded-6 border border-info mt-4 mb-5 py-3 w-auto'>
              <ContainerTotal totalData={utilidadPerdida} label="Utilidad / Perdida" />
              <hr />
              <ContainerTotal totalData={acumulados} label="Acumulado" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <MDBContainer fluid>
      <HeaderContainer title={`Presupuesto`} ></HeaderContainer>
      <MDBRow style={{padding: "10px 10px"}}>
        <MDBCol md='4' className="px-4 mb-2">
          <div className='d-flex' >
            <div className="p-2 w-100">
              <SelectCustom 
                options={optionNamePresupuestos}
                defaultValue={selectedYear?.value || ""}
                onChangeSelect={handleYearChange} 
                name={'Fecha Anual'}      
              />
            </div>
            <MDBBtn rounded className='mx-2 flex-shrink-1' color='info' onClick={() => handlItemAction("nuevo", "presupuesto")} >+</MDBBtn> 
          </div>
          
        </MDBCol>
        <MDBCol md='8' className='text-end'>
          <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => savePresupuesto(selectedYear.label, false)} >Guardar </MDBBtn>
          <MDBBtn rounded className='mx-2' color='info' onClick={() => handlItemAction("clonar", "presupuesto")} >Clonar</MDBBtn> 
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
        {alert && <div className='w-100'><AlertMessage alert={alert}/></div> }
        </MDBCol>
      </MDBRow>
      
      
      { !selectedYear 
      ? <></>
      : <MDBRow>
          <MDBCol md='12'>
          { loading 
            ? <span>Cargando Datos...</span>
            : getTablesInformation()
          }
          </MDBCol>
        </MDBRow>
      }
      {showModal && <ClonarPresupuesto showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSaveData} />}
    </MDBContainer>
  );

}

export default Presupuestos;
