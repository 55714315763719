import React, { useEffect, useState } from 'react';
import DataTableCustom from './DataTableCustom';
import { MDBBtn, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import styled from '@emotion/styled';
import AddItemModal from '../settings/AddItemModal';
import SelectCustom from './SelectCustom';
import { useSettingsStore } from '../../store/settingsStore';
import { StyleRow, StyledContainerConcepto } from "../general/StylesUtils";
import { shallow } from 'zustand/shallow';
import { TableColumn } from 'react-data-table-component';

const StyledInputCosto = styled(MDBInput)`
  margin: 0 !important;
`

interface IPropsForm {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
  dataForm: any[];
}

interface IProps {
  columns: any[];
  headers: any[];
  dataDefault: any[];
  handleSaveContactos: any;
}

type DataRowCostos = {
  medioLabel: string;
  conceptoLabel: string;
  precioBase: string;
}

const removeItem = (row: any, data: any) => {
    console.log("+ REMOVE - row: ", row)
    console.log("+ infoCadena: ", data)
    const newInfo = data.filter((item: any) => {
      return item.id !== row.id
    }).map((item: any, index: number) => ({...item, id: ++index }) )

    console.log("newInfo > ", newInfo)
    return newInfo
}

const AddNewFormImportar = ({showModal = false, setShowModal, typeAction, handleSave, dataForm = [] }: IPropsForm) => {
  const cadenasServer = useSettingsStore((state) => (state.cadenas), shallow)
  const optionsCadenas = useSettingsStore((state) => (state.cadenasSelect), shallow)
  const [cadena, setCadena] = useState("")
  const [infoCadena, setInfoCadena] = useState<any[]>([])
console.log("infoCadena > ", infoCadena)
  const onChangeSelect = (data: any) => {
    console.log("onChangeSelect-data: ", data)

    console.log("cadenasServer: ", cadenasServer)

    const infoCadenaData = cadenasServer.find((item) => item.uid === data.value)
    const infoCostos = infoCadenaData?.costos.map((item: any, index: number) => ({...item, id: ++index }) )
    console.log("infoCadena: ", infoCostos)
    setInfoCadena(infoCostos)
  }
  const columnsCostos: TableColumn<DataRowCostos>[] = [
    {
      name: "Medio",
      selector: (row: { medioLabel: any; }) => {
        return row.medioLabel
      },
      sortable: true
    },
    {
      name: "Concepto",
      selector: (row: { conceptoLabel: any; }) => row.conceptoLabel,
      sortable: true
    },
    // {
    //   name: "Precio Base",
    //   button: true,
    //   cell: (row: { precioBase: any; }) => {
    //     //row.precioBase
    //     return 
    //     <MDBInput label="Costo" type='text' name='precioBase' value={row.precioBase} onChange={(onChange)} required />
    //   },
    // },
  ];

  const handleBlur = ({ value }: any, row: any) => {
    if (value.endsWith('.')) {
      const newValue = value.slice(0, -1)
      onChange({value: newValue}, row);
    }
  }
  const onChange = (target: any, row: any) => {
    const { value } = target
    const cloneInfo = [...infoCadena]
    if (/^(?:\d*\.\d{0,2}|\d+)$/.test(value) || value === '') {
      cloneInfo[Number(row?.id) - 1] = {...row, precioBase: value}

      setInfoCadena([...cloneInfo])
    }
  }

  const removeItemAction = (row: any) => {
    const newInfo = removeItem(row, infoCadena)

    console.log("newInfo > ", newInfo)
    setInfoCadena(newInfo)
  }
  const inputDataTable = (row: any) => {
    return (
      <StyledInputCosto 
        size='sm'
        type='text' 
        name='precioBase' 
        pattern="^(?:\d*\.\d{0,2}|\d+)$"
        value={row.precioBase} 
        onBlur={(e: any) => handleBlur(e.target, row)}
        onChange={((e: any) => onChange(e.target, row))} />
    )
  }

  const actionsDataTable = (row: any) => {
    return (
      <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => removeItemAction(row)} >
      <MDBIcon fas icon='times' size='sm' />
      </MDBBtn>
    )
  }

  const contentForm = () => {
    // const { cadena } = formValue
    return (
      <StyledContainerConcepto className="row" style={{ padding: "20px" }} isValidated>
        <SelectCustom options={optionsCadenas} onChangeSelect={onChangeSelect} name='cadena' defaultValue={cadena} required />

        <DataTableCustom 
        keyDataTable="table-contactos" 
        columns={[
          ...columnsCostos, 
          {
            name: "",
            button: true,
            cell: (row: any) => inputDataTable(row),
            right: true
          },
          {
            name: "",
            button: true,
            cell: (row: any) => actionsDataTable(row),
            right: true
          }
        ]} 
        data={infoCadena} 
      />

      </StyledContainerConcepto>

    )
  }

  const handleSaveData = () => {
    handleSave(infoCadena)

    setShowModal(!showModal)
  }

  return (
    <AddItemModal keyModal="modal-costos" typeAction={typeAction} showModal={showModal} setShowModal={setShowModal} handleSave={handleSaveData} centeredDialog >
      { contentForm() }
    </AddItemModal>
  )
}

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave, dataForm = [] }: IPropsForm) => {
  const [formValue, setFormValue] = useState(typeAction?.data || { medio: "", concepto: "", precioBase: "" } )
  const [optionsConceptos, setOptionsConceptos] = useState([])
  const optionsMedios = useSettingsStore((state) => (state.mediosSelect), shallow)

  const handleSaveData = () => {
    const {option} = typeAction

    console.log("typeAction >> ", typeAction)

    handleSave(option,  {...formValue, id: formValue?.id || null})
    setShowModal(!showModal)
  }

  const onChange =(e: any) => {
    const { name, value } = e.target

    setFormValue({ ...formValue, [name]: value });
  }
  const onChangeSelect = (data: any) => {

    const { name, value, label, dataObject } = data

    if (name === "medio") {
      setOptionsConceptos(dataObject?.conceptos)
    }

    console.log("---> 48 - onChangeSelect > data: ", data)
    setFormValue({ ...formValue, [name]: value, [`${name}Label`]: label });
  }

  const contentForm = () => {
    const { medio, concepto, precioBase } = formValue
    return (
      <StyledContainerConcepto className="row" style={{ padding: "20px" }} isValidated>
        <SelectCustom options={optionsMedios} onChangeSelect={onChangeSelect} name='medio' defaultValue={medio} required />

        <SelectCustom options={optionsConceptos} onChangeSelect={onChangeSelect} name='concepto' defaultValue={concepto} required />

        <MDBInput label="Costo" type='text' name='precioBase' value={precioBase} onChange={onChange} required />
      </StyledContainerConcepto>

    )
  }

  return (
    <AddItemModal keyModal="modal-costos" typeAction={typeAction} showModal={showModal} setShowModal={setShowModal} handleSave={handleSaveData} centeredDialog >
      { contentForm() }
    </AddItemModal>
  )
}

const DatosCostosDataTable = ({ columns, headers, dataDefault, handleSaveContactos }: IProps) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalImportar, setShowModalImportar] = useState(false)
  const [typeAction, setTypeAction] = useState({})

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    setShowModal(true);
    setTypeAction({ typeName, option, data })
  }

  const handlItemActionImportar = (typeName: string, option: string) => {
    setShowModalImportar(true);
    setTypeAction({ typeName, option })
  }

  const handleSaveImport = (newData: any) => {
    console.log("SAVE-import - newData: ", newData)
    handleSaveContactos({name: 'costos', value: newData})
  }

  const handleSave = (option: string, data: any) => {
    const newData = (option === 'Nuevo') ? 
                    [...dataDefault, {...data, id: dataDefault.length + 1 }] : 
                    dataDefault.map((items) => items.id === data.id ? data : items )

    handleSaveContactos({name: 'costos', value: newData})
  }

  const handleRemoveRowCosto= (row: any) => {
    const newInfo = removeItem(row, dataDefault)
    handleSaveContactos({name: 'costos', value: newInfo})
    console.log("Eliminar > row: ", row)
    console.log("Eliminar > dataDefault: ", dataDefault)
  }

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("costos", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handleRemoveRowCosto(data)}>
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  return (
    <>
      <StyleRow className="d-flex justify-content-end">
        <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
          <MDBBtn outline rounded className='mx-2' color='secondary' 
            onClick={() => {
              handlItemActionImportar("importar", "Nuevo")
            }} >
            Importar de Cadena
          </MDBBtn>

          <MDBBtn className='ms-2' tag='a' color='secondary' floating  
            onClick={() => {
              handlItemAction("costos", "Nuevo")
            }} >
            <MDBIcon fas icon='plus' size='sm' />
          </MDBBtn>
        </div>
      </StyleRow>  
      <DataTableCustom 
        keyDataTable="table-contactos" 
        columns={[
          ...columns, 
          {
            name: "",
            button: true,
            cell: (row: any) => actionsDataTable(row),
            right: true
          }
        ]} 
        data={dataDefault} 
      />
      
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} dataForm={headers} />}
      {showModalImportar && <AddNewFormImportar showModal={showModalImportar} setShowModal={setShowModalImportar} typeAction={typeAction} handleSave={handleSaveImport} dataForm={headers} />}
    
    </>
  );
}

export default DatosCostosDataTable;
