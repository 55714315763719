import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit';
import AddItemModal from './AddItemModal';
import DataTableCustom from '../general/DataTableCustom';
import { settings } from './api';
import AlertMessage from '../general/alertMessage';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import Loading from '../general/loading';
import { validateRequiredData } from '../general/utils';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { TableColumn } from 'react-data-table-component';

const StyledContainerMedio = styled(MDBCol)`
    border: thin solid #cacaca;
    border-radius: 5px 5px 0 0;
    margin-bottom: 5px;
    padding: 5px;

    & h5 {
      margin: 5px;
    }
`;

type DataRow = {
  name: string;
};

interface IOptions {
  name: string; 
  uid: string;
}

interface IData {
   conceptos?: any[]
}

type IAllData = IOptions & IData;

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any, parentMedio?: any } // id?: number, value?: string
  handleSave: any;
}

// let idValue = 1;

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  // const [data, setData] = useState("")
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [formValue, setFormValue] = useState({
    uid: null,
    name: '',
  })

  const [_, setRequiredStatus] = useState({
    name: false,
  })
  
  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }
  // useEffect(() => {

  //   setData(typeAction.value || "")
  // }, [typeAction.value])
  useEffect(() => {
    // console.log("typeAction > typeAction: ", typeAction)
    if (!typeAction?.data) {
      return;
    }
    const { uid, name } = typeAction?.data
    setFormValue({uid, name})
    setIsValidateFine(true)
    setRequiredStatus({
      name: true,
    })
  }, [typeAction.data])

  const handleSaveData = async () => {
    try {      
      setSaving(true)
      const { uid, name } = formValue
      const {typeName, data } = typeAction 

      const payload = {
          type: typeName,
          name,
          ...{ uid }, 
          ...(data?.dataParent && {uidMedio: data?.dataParent.uid })
        }

      await settings(payload)

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target

    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }
// console.log("formValue > ", formValue)
  const constentForm = () => {
    const { name } = formValue

    return (
      <div className="row" style={{padding: "20px"}}>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='name' value={name} onChange={onChange} onBlur={isValidate} required />
        
        <AlertMessage alert={alert}/>
      </div>
    )
  }

  return (
    <AddItemModal 
      keyModal="modal-medio" 
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValidateFine}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const MediosYConceptosSetting = () => {
  const mediosServer = useSettingsStore((state) => (state.medios), shallow)
  const {getMediosServer} = useSettingsStore()

  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [typeAction, setTypeAction] = useState({})
  const [medios, setMedios] = useState<IAllData[]>([])
  const [loading, setLoading] = useState(true)

  const headers = [
    { label: 'Concepto', name: 'name'},
  ];

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Concepto",
      selector: row => row.name,
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ]

  useEffect(() => {
    setLoading(false)
    setMedios(mediosServer)
  }, [mediosServer])

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => {
          handlItemAction("concepto", "Edit", {uid: data.uid, name: data.name, dataParent: { uid: data.uidMedio}})
        }} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("concepto", "Delete", { uid: data.uid, name: data.name })} >
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }
    setTypeAction({ typeName, option, data })
  }

  // const getData = (objectData: IAllData[], data: IAllData) => {
  //   return objectData.map((items) => data.uid === items.uid ? data : items )
  // }

  const handleSave = () => {
    setLoading(true)
    getMediosServer()
  }

  // const handleSave = async(typeName: string, option: string, data: IOptions) => {

    // // concepto
    // if ((typeName === "concepto")) {
    //   console.log("save concepto!! - medioParent: ", medioParent)
    //   const c = medioParent?.conceptos || []
    //   const newC = (option === "Nuevo") ? [...c, data] : getData(c, data)
    //   medioParent && setMedios(getData(medios, {...medioParent, conceptos: newC}) )

    //   return
    // }
    // // medios
    // const newMedios = (option === "Nuevo") ? [...medios, { ...data }] : getData(medios, data)

    // setMedios(newMedios);
  // }

  return (
    <MDBContainer>
      <h4> Medios y Conceptos </h4>
      { (loading) ? 
          <Loading />
          :
          <>
            <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
              <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("medio", "Nuevo")} >+ Agregar Medio</MDBBtn>
            </div>
            
            {medios?.map((medio) => {
              const {name, uid, conceptos} = medio

              return (
              <MDBRow key={`row-medio-${uid}`} style={{marginBottom: "15px" }}>
                <StyledContainerMedio size='11' style={{ textAlign: "left", marginBottom: "0" }} className="d-flex justify-content-between">
                  <div><h5> { name } </h5></div>
                  <div >
                    <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("medio", "Edit", {uid, name})} >
                      <MDBIcon fas icon='edit' size='sm' />
                    </MDBBtn>
                    <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }}  onClick={() => handlItemAction("medio", "Delete", {uid, name})}>
                      <MDBIcon fas icon='trash' size='sm' />
                    </MDBBtn>
                    <MDBBtn className='ms-2' tag='a' color='secondary' floating  
                      onClick={() => {
                        // setMedioParent(medio)
                        handlItemAction("concepto", "Nuevo", { dataParent: medio })
                      }} >
                      <MDBIcon fas icon='plus' size='sm' />
                    </MDBBtn>
                  </div>
                </StyledContainerMedio>
                <MDBCol size='11' style={{ backgroundColor: "#cacaca"}}> 
                  <DataTableCustom keyDataTable="table-usuarios" columns={columns} data={conceptos || []} />
                </MDBCol>
                
              </MDBRow>
              )}
            )}
          </>
      }
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm> }

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default MediosYConceptosSetting;
