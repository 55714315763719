import { ButtonProps } from "mdb-react-ui-kit/dist/types/free/components/Button/types";
import moment from "moment";

export const colorRed = "#dc4c64";

export const optionsAction = ["edit", "new"];

export const convertToArrayObject = (arrayData: any, columns: any) => {
  return !arrayData
    ? []
    : arrayData.map((item: any, row: any) => {
        return item.reduce(
          (data: any, value: any, index: any) => ({
            ...data,
            id: row,
            [columns[index]?.id || index]: columns[index]?.disabledCopy
              ? ""
              : columns[index]?.format
              ? columns[index].format(value)
              : value,
          }),
          {},
        )
      })
}

export const convertMoneyToNumber = (value: string) => {
  if (typeof value === 'string') { //  && value.includes("$")
    return parseFloat(value.replace(/[\\$,]/g, ''));
  }
  // Si la cadena no contiene el símbolo "$", retornar NaN o cualquier otro valor que consideres apropiado
  return 0; // o return 0, o return undefined, según lo que prefieras
}

export const getColorTransaccion = (type: string) => {
  switch (type) {
    case "ingresos": return "success"
    case "egresos": return "danger"
    default: return "info"
  }
}

export const optionsStatus = [
  { value: 'espera', label: 'En espera' },
  { value: 'negociando', label: 'Negociando' },
  { value: 'perdido', label: 'Perdido' },
  { value: 'ganado', label: 'Ganado' },
  { value: 'terminado', label: 'Terminado' },
]

export const getStatusColors = (value: string) => {
  switch(value) {
    // case 'solicitado':
    case 'pagado':
    case 'espera': return 'info'
    case 'proceso_pago':
    case 'negociando': return 'warning'
    case 'rechazado':
    case 'perdido': return 'danger'
    case 'cancelado':
    case 'terminado': return 'secondary'
    default: return 'success'
  }
}

export const requisicionStatus = [
  { value: 'solicitar', label: 'Solicitar' },
  { value: 'rechazado', label: 'Rechazado' },
  { value: 'cancelado', label: 'Cancelado' },
  { value: 'proceso_pago', label: 'Proceso Pago' },
  { value: 'pagado', label: 'Pagado' },
]

export const CotizacionHeader = {
  LineaNegocio: 'lineaNegocio',
  Cliente: 'cliente',
  // Left: 'left',
  // Right: 'right',
} as const

export const optionsPorcentajes = [
    { value: '-1', label: 'Personalizar'},
    { value: '0', label: '0%' },
    { value: '5', label: '5%' },
    { value: '10', label: '10%' },
    { value: '20', label: '20%' },
    { value: '30', label: '30%' },
    { value: '40', label: '40%' },
    { value: '50', label: '50%' },
    { value: '60', label: '60%' },
    { value: '70', label: '70%' },
    { value: '80', label: '80%' },
  ]

export const isValidFormData = () => {

} 

export const getTotal = (cantidad: string, repeticion: string, precioUnitario: any, activeteFomat: boolean = true) => {
  const pu = (typeof precioUnitario === 'string' || precioUnitario instanceof String) ? Number(precioUnitario) : precioUnitario
  if(!cantidad || !repeticion || !precioUnitario) return 0.00

  const total = Number(cantidad) * Number(repeticion) * pu

  return activeteFomat ? formatMoney(total) : total
}

function currencyFormatter(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency: "USD",
  }) 
  return formatter.format(value)
}

export const formatMoney = (value: any) => {
  if (!value) return "$0.00"

  const numberValue = (typeof value === 'string' || value instanceof String) ? Number(value) : value
  const formatToParts = currencyFormatter(
    // currency: ,
    numberValue,
  )

  return formatToParts
}

export const roundValue = (value: any) => {
  const numberValue = (typeof value === 'string' || value instanceof String) ? Number(value) : value
  // console.log(" ++ redondearValue - value: ", value)
  // console.log(" ++ redondearValue - round: ", Math.round(numberValue))
  return (Math.round(numberValue)); // formatMoney
}

export const formatPhone = (phone: any) => {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (phone.length == 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return null;
}

export const validateRequiredData = (e: { target: any; }, setRequiredStatus: any, setIsValidateFine: any) => {
  const data = e.target
  const { name, value, required } = data

  if(!required) return
 
  setRequiredStatus((data: any) => {
    const isValue = Array.isArray(value) ? value.length  : value.trim()
    const newData = {
      ...data,
      [name]: !!isValue,
    }

    const validate = Object.values(newData).find((value) => !value )

    setIsValidateFine(validate === undefined)
    return newData
  })
}

export const getPrecioVenta = (precioUnitario: string, porcentajeValue: number) => {
  // const ganancia = porcentajeValue * Number(precioUnitario) // return Number(precioUnitario) + ganancia // 

  return Number(precioUnitario) / (1 - porcentajeValue)
}

export const number2decimals = (value: number) => parseFloat(String(value)).toFixed(2);

export const getUniqueId = () => {
  return Math.random().toString(16).slice(2)
}

// export const isMoneda = (data: string) => {
//   const patronMoneda = /^\s*\$?[+-]?(\d{1,3}(\,?\d{3})*|\d+)([\.,]\d{2})?\s*$/;
//   return patronMoneda.test(data);
// }

export const unixTimestampToDate = (timestamp: string) => {
  if (!timestamp) return ""

  const date = new Date(Number(timestamp) * 1000); // Multiplicamos por 1000 para convertir segundos en milisegundos

  // Formatea la fecha en "dia/mes/año"
  return moment(date).format('DD/MM/YYYY');
}

export const calcularPorcentajeAumento = (costo: number, venta: number) => {
  // const diferencia = venta - costo;
  // console.log("===>> diferencia: ", diferencia)
  // const porcentajeAumento = (diferencia / costo) * 100;
  // console.log("===>> porcentajeAumento: ", porcentajeAumento)
  // return porcentajeAumento;

  return (1 - (costo / venta) ) * 100
}

export const getPorcentaje = (value: any) => {
  const newValue = typeof value === 'number' ? value.toString() : value
  const porcentajes = ["0", "10", "20", "30", "40", "50", "60", "70", "80"]

  return porcentajes.includes(newValue) ? newValue : "-1"
}

export const getCosto = ({cantidadRequisicion, costoRequisicion, repeticion}: any) => {
  return Number(cantidadRequisicion) * Number(repeticion) * Number(costoRequisicion)
}

export const getStatusColorRequisicion = (statusRequisicion: any[]) => {
  const lastStatus = statusRequisicion[statusRequisicion.length - 1]
  console.log("STATUS > row-lastStatus: ", lastStatus)
  const statusCustom = lastStatus?.status || "solicitar" // row?.terminado ? "terminado" : row.status
  const colorStyle: ButtonProps["color"] = getStatusColors(statusCustom)

  return { statusCustom, colorStyle, lastStatus }
}

export const removeItemOfObject = (key: string, data: any) => {
  if (data.hasOwnProperty(key)) {
    delete data.uidProyecto;
  }
  
  return data
}

export const validateIsMoneda = (value: string) => {
  const regex = /^(\d+(\.\d{0,2})?)?$/;

  return regex.test(value)
}

export const parseMoneda = (value: string) => {
  if (!value.trim()) {
    return "0.00";
  }

  // Parsear el valor a un número
  const numberValue = parseFloat(value);

  // Si no es un número válido, devolver "0.00"
  if (isNaN(numberValue)) {
    return "0.00";
  }

  // Acompletar el número a dos decimales
  const formattedValue = numberValue.toFixed(2);

  return formattedValue;
}

export const getRequisicionTotal = (requisiciones: any) => {
  if (!requisiciones) return 0

  const totalesRequisiciones = requisiciones.map((requisicion: any) => {
    const repeticion = parseInt(requisicion.repeticionRequisicion);
    const costo = parseFloat(requisicion.costoRequisicion);
    const cantidad = parseInt(requisicion.cantidadRequisicion);

    // Verificar si los valores son numéricos antes de hacer el cálculo
    if (!isNaN(repeticion) && !isNaN(costo) && !isNaN(cantidad)) {
        return repeticion * costo * cantidad;
    } else {
        // Manejar el caso donde los valores no son numéricos
        return 0;
    }
  });

  const totalGeneral = totalesRequisiciones.reduce((total: any, subtotal: any) => total + subtotal, 0);
  return totalGeneral
}

export const getCategorias = (transaccion: string, optionsCategoriasServer: any[]) => {
  console.log("getCategorias >>> transaccion: ", transaccion )
  const labelCampana = transaccion === "ingresos" 
   ? "PAGO DE CAMPANA"
   : "COSTO DE CAMPANA" 
  
 const options = [ {label: labelCampana, value: "1", subcategorias: [{label: "CAMPANA", value: "1"}]}, ...optionsCategoriasServer ]  
console.log("****>> optionsCategoriasServer-options: ", options)
  return options
}
