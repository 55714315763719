import React, { useState } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit'; // MDBContainer, MDBModalFooter
import LeftMenu, { SettingsTypes } from './LeftMenu';
import MediosYConceptosSetting from './MediosYConceptosSetting';
import ProveedoresSetting from './ProveedoresSettings';
import CadenasSetting from './CadenasSettings';
import ClientesSettings from './ClientesSettings';
import BancoSettings from './BancoSettings';
import LineasNegocioSettings from './LineasNegocioSettings';
import UsuariosSettings from './UsuariosSettings';
import CategoriasYSubcategoriasSetting from './CategoriasYSubcategoriasSetting';

interface IProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const Settings = ({showModal = false, setShowModal}: IProps) => {
  const [optionSelected, setOptionSelected] = useState("")

  const toggleShow = () => setShowModal(!showModal);

  const handleOptionSetting = (option: any): void => {
    // throw new Error('Function not implemented.');
    console.log("event - option > ", option)
    setOptionSelected(option)
  }

  const displayOption = () => {
    switch (optionSelected) {
      case SettingsTypes.Banco:
        return <BancoSettings />  
      case SettingsTypes.MediosYConceptos:
        return <MediosYConceptosSetting />
      case SettingsTypes.Proveedores:
        return <ProveedoresSetting />
      case SettingsTypes.Cadenas:
        return <CadenasSetting />
      case SettingsTypes.Clientes:
        return <ClientesSettings />        
      case SettingsTypes.LineasNegocio:
        return <LineasNegocioSettings />     
        case SettingsTypes.CategoriasYSubcategorias:
          return <CategoriasYSubcategoriasSetting />  
        case SettingsTypes.Usuarios:
          return <UsuariosSettings />  
      default:
         return <p></p>
    }
  }

  return (

      <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
        <MDBModalDialog scrollable size='xl'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Configuracion</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <div className="row">
                    <div className="col-4"><LeftMenu handleOptionSetting={handleOptionSetting} selected={optionSelected} /></div>
                    <div className="col-8">
                      { displayOption()}
                    </div>
                </div>
                
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

  );
}

export default Settings;
