const DatosRequisicionesHeader = ({cantidadTotal, totalUntilNow, totalRequisicionInfo, data}: any) => {
  return (
    <>
    <h5>Datos de Requisiciones</h5>
    <div className="text-start p-3">
        <div><label className="fw-bold">Medio:</label> {data?.medioLabel}</div>
        <div><label className="fw-bold">Concepto:</label> {data?.conceptoLabel}</div>
        { cantidadTotal !== undefined && <>
          <div><label className="fw-bold">Requisiciones Cantidad:</label> {Number(data?.cantidad) - cantidadTotal} / {data?.cantidad} </div>
          <div><label className="fw-bold">Costo Total:</label> { totalUntilNow } / { totalRequisicionInfo } </div>
        </> }
    </div>
    </>
  )
}

export default DatosRequisicionesHeader
