import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from "@emotion/styled";
// import { isDisabled } from '@testing-library/user-event/dist/utils';
import { StylesConfig } from 'react-select';

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    marginBottom: '0px', // Ajusta el margen entre el control y el cuadro de opciones
  }),
  menu: (provided) => ({
    ...provided,
    border: 'thin green solid',
    marginTop: '0px', // Ajusta el margen entre el cuadro de opciones y los elementos
    top: 'calc(-30px + 100%)',
  }),
};
console.log("customStyles >> ", customStyles)
interface props {
  valid: boolean;
  required: boolean;
  isDisabled: boolean;
}

const StyleContainerSelect = styled.div<props>`
  position: relative;
  
  ${({valid, required, isDisabled}) => `
    & > div > div {
      border-color: ${ isDisabled ? "#bdbdbd" : valid || !required ? 'green' : '#dc4c64' } !important;
      min-height: 35.5px;
    }
    &> label {
      color: ${ valid || !required ? 'green' : '#dc4c64' } !important;
      font-size: smaller;
    }
    & > div > div > div {
      padding: 0px !important; 
    }
  `}
`;
// .react-select__control {
//   border: solid thin blue;
//   margin-bottom: 0px;
// }
// .react-select__menu {
  
//   margin-top: 0px;
//   top: calc(-25px + 100%);
// }

const StyledContainerLabel = styled.label`
  position: absolute;
  left: 10px;
  top: -10px;
  background-color: white;
  size: 10px;
  padding: 0 5px;
`;

interface IProps {
  options: any;
  defaultValue?: any;
  hiddeLabel?: boolean; 
  onChangeSelect: any;
  name: string;
  label?: string;
  value?: any;
  required?: boolean;
  isDisabled?: boolean;
  customMoreStyles?: any;
}

const SelectCustom = ({options, defaultValue, hiddeLabel=false, onChangeSelect, name, label, required = false, isDisabled = false, customMoreStyles = {}}: IProps) => {
  const [isValid, setIsValid] = useState<boolean>(!!defaultValue);
  const [defaultValueObject, setDefaultValueObject] = useState({});

  useEffect(() => {
    const dd = options.find((option: { value: any; }) => option?.value === defaultValue )

    setDefaultValueObject(defaultValue ? dd : {})
  }, [defaultValue, options])

  const onChange = (data: any) => {
    setIsValid(!!data);
    const { value, label } = data

    onChangeSelect({required, name, value, label, dataObject: data}); // 
  }
  
  //console.log("defaultValueObject > ", defaultValueObject)

  return (
  <StyleContainerSelect valid={isValid} required={required && !defaultValue} style={customMoreStyles} isDisabled={isDisabled} >
    <Select 
    // menuIsOpen={true}
      menuPosition="fixed"
      options={options}
      onChange={onChange}
      placeholder='Seleccionar'
      value={defaultValueObject}
      isDisabled={isDisabled}
      styles={customStyles}
      tabIndex={-1}
    />
    { !hiddeLabel && <StyledContainerLabel className="form-label text-capitalize" htmlFor="formControlLg">{ label || name }</StyledContainerLabel>}
  </StyleContainerSelect>
  );
}
export default SelectCustom;
