/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';
import './styles.css';
import styled from '@emotion/styled';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';

const StyledContainer = styled(MDBContainer)`
  padding: 40px; 
  max-width: fit-content;
`

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const {getUserAuthServer} = useSettingsStore()
  const authServer = useSettingsStore((state) => (state.auth), shallow)
  const [formValue, setFormValue] = useState({ user: "", password: "" })
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if(authServer?.error) {
      setErrorMessage("Usuario y/o contraseña desconocidos")
    }
  },[authServer])
  
  const hadleOnClick = async() => {
    const { user, password } = formValue
    if (!user.trim() || !password.trim()) {
      setErrorMessage("Usuario y/o contraseña invalidos")
      return 
    }
    // console.log("Click!! - login: ", formValue) // {user: "carmen@gmail.com", password: "123456"}
    try {
      getUserAuthServer(formValue)
    } catch (error) {
      setErrorMessage("Usuario y/o contraseña desconocidos")
    }

    // Enviar una petición POST
    // const response = await axios({
    //   method: 'post',
    //   url: '/signin',
    //   data: {user: "carmen2@gmail.com", password: "123456"},
    // });
    // console.log("response-Login > ", response)
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    
    setErrorMessage("")
    setFormValue({ ...formValue, [name]: value });
  };

  return (
        <StyledContainer className=" bg-color">
          <MDBCard>
            <MDBRow className='g-0'>
    
              <MDBCol md='6'>
                <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp' alt="login form" className='rounded-start w-100'/>
              </MDBCol>
              <MDBCol md='6'>
                <MDBCardBody className='d-flex flex-column'>
                  <div className='d-flex flex-row mt-2 align-items-center'>
                    {/* <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }}/> */}
                    <MDBCardImage src='./images/sensorial-logo.jpeg' width="80px" />
                    <span className="h3 fw-bold mb-0">Sensorial Media</span>
                  </div>
    
                  <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Iniciar sesión en su cuenta</h5>
                    <p><label style={{color: "red"}}>{ errorMessage }</label></p>
                    <MDBInput wrapperClass='mb-4' label='Correo' id='formControlLg' type='email' size="lg" name="user" value={formValue.user} onChange={onChange} />
                    <MDBInput wrapperClass='mb-4' label='Contrasena' id='formControlLg' type='password' size="lg" name="password" value={formValue.password} onChange={onChange} />
    
                  <MDBBtn className="mb-4 px-5" color='dark' size='lg' onClick={hadleOnClick}>Iniciar</MDBBtn>
                  <a className="small text-muted" href="#!">Olvido la contraseña?</a>
                  {/* <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}>Don't have an account? <a href="#!" style={{color: '#393f81'}}>Register here</a></p> */}
    
                  <div className='d-flex flex-row justify-content-center'>
                    <a href="#!" className="small text-muted me-1">Terms of use.</a>
                    <a href="#!" className="small text-muted">Privacy policy</a>
                  </div>
    
                </MDBCardBody>
              </MDBCol>
    
            </MDBRow>
          </MDBCard>
    
        </StyledContainer>
      );
};
