import { useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { shallow } from "zustand/shallow";
import { compose, map, split, trim, allPass, all, not, isEmpty, nth, test, either, isNil } from "ramda";
import AddItemModal from "../settings/AddItemModal";
import { convertMoneyToNumber, convertToArrayObject, formatMoney, getCategorias, getColorTransaccion, getCosto } from "../general/utils";
import styled from "@emotion/styled";
import { TableColumn } from "react-data-table-component";
import { ButtonProps } from "mdb-react-ui-kit/dist/types/free/components/Button/types";
import { MDBBtn, MDBCheckbox, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import DataTableCustom from "../general/DataTableCustom";
import SelectCustom from "../general/SelectCustom";
import { useSettingsStore } from "../../store/settingsStore";
import AlertMessage, { IAlertMessage } from "../general/alertMessage";
import moment from "moment";
import { transaccionImportSave, transaccionSave } from "../settings/api";
import { updateProyectoEgreso, updateProyectoIngreso } from "./saveUtilis";
import Loading from "../general/loading";

const StyledBoxCopyPaste = styled.div`
    padding: 90px 0px;
    background-color: #eee;
`
const StyledToolTip = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;

  > a {
    color: black !important;
    font-weight: 600;
  }
`

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

type DataRow = {
  descripcion: string;
  monto: string;
  transaccion: string;
  categoriaLabel: string;
  subcategoriaLabel: string;
  bancoLabel: string;
  fecha: string;
  comentario: string;
  banco: string;
};

type ExtendedDataRow = DataRow & {
  cargo?: string;
  abono?: string;
};


const format = compose(map(compose(map(trim), split("\t"))), split(/(?:\r\n|\r|\n)/), trim)

const moneyRegex: RegExp = /^(?:\$?[\d,]+(?:\.\d{2})?|\$?0(?:\.00)?|\$0(?:\.00)?)$/;
const isNilOrEmpty = either(isNil, isEmpty)
const moneyValidation = (value: any) => !isNilOrEmpty(value) && test(moneyRegex, value);

const getDate = nth(0)
const getTime = nth(1)
const getDescripcion = nth(3)
const getCargos = nth(4)
const getAbonos = nth(5)

const validateDate = all(compose(not, isEmpty, getDate))
const validateTime = all(compose(not, isEmpty, getTime))
const validateDescripcion = all(compose(not, isEmpty, getDescripcion))
const validateCargos = all(compose(moneyValidation, getCargos));
const validateAbonos = all(compose(moneyValidation, getAbonos));

const validateArray = [validateDate, validateTime, validateDescripcion, validateCargos, validateAbonos]

const AddImportarForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const { getProyectosServer } = useSettingsStore()
  const optionsBancos = useSettingsStore((state) => (state.bancosSelect), shallow)
  const optionsCategoriasServer = useSettingsStore((state) => (state.categoriasSelect), shallow)
  const proyectosServer = useSettingsStore((state) => (state.proyectos), shallow)
  const [proyectosGanados, setProyectosGanados] = useState<any[]>([])
  const [proyectosGanadosOptions, setProyectosGanadosOptions] = useState<any[]>([])
  const [optionsCategoriasIngresos, setOptionsCategoriasIngresos] = useState<any[]>([])
  const [optionsCategoriasEgresos, setOptionsCategoriasEgresos] = useState<any[]>([])
  const [arrayData, setArrayData] = useState<any[]>([])
  const [errorImportData, setErrorImportData] = useState(false);
  const [loading, setLoading] = useState(false)
   const [saving, setSaving] = useState(false)
  const [banco, setBanco] = useState({label: "", value: ""})
  const [alert, setAlert] = useState<IAlertMessage | null>(null)

  const getProyectosData = useCallback(async() => {
    getProyectosServer()
  }, [getProyectosServer])

  useEffect(() => {
    getProyectosData()
  }, [getProyectosData])

  useEffect(() => {
    const catIngresosOptions = getCategorias("ingresos", optionsCategoriasServer)
    const catEgresosOptions = getCategorias("egresos", optionsCategoriasServer)

    setOptionsCategoriasIngresos(catIngresosOptions)
    setOptionsCategoriasEgresos(catEgresosOptions)
  }, [optionsCategoriasServer, setOptionsCategoriasIngresos, setOptionsCategoriasEgresos])

  useEffect(() => {
    const newCotizacionesList = proyectosServer.filter((items) => {
      return items.status === 'ganado' && !items.terminado && { label: items.name, uid: items.uid }
    });

    const newProyectosOptions = newCotizacionesList.map(({ name, uid }) => ({ label: name, value: uid }))
    setProyectosGanados(newCotizacionesList)
    setProyectosGanadosOptions(newProyectosOptions)

  }, [proyectosServer])

  const getCurrentProyecto = (idProyecto: any) => {
    const proyectoInfo = proyectosGanados.find(data => data.uid === idProyecto)

    return proyectoInfo
  }

  const getRubrosOptions = (proyectoInfo: any) => {
    return proyectoInfo?.items
      .filter((item: any) => item.requisiciones && item.requisiciones.length > 0)
      .map((item: any) => {
        const requisicionesProcesoPago = (item.requisiciones || [])
          .filter((requisicion: any) => {
            const statusRequisicion = requisicion.statusRequisicion || [];
            const procesoPagoIndex = statusRequisicion.findIndex((status: any) => status.status === "proceso_pago");

            // Solo permitir requisiciones con estado "proceso_pago" y asegurarse de que sea el último estado en el array
            return procesoPagoIndex !== -1 && procesoPagoIndex === statusRequisicion.length - 1;
          })
          .map((requisicion: any) => {
            const { proveedorLabel, medioLabel, conceptoLabel, idRequisicion, cantidadRequisicion, costoRequisicion, repeticion } = requisicion;
            console.log("REQ > ", requisicion);
            return {
              label: `[${formatMoney(costoRequisicion)}] - ${proveedorLabel}(${medioLabel} > ${conceptoLabel})`,
              value: idRequisicion,
              costo: getCosto({ cantidadRequisicion, costoRequisicion, repeticion }),
            };
          });

        return requisicionesProcesoPago;
      })
      .flat()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsCopy: any[] = [
    {
      // disabledCopy: true,
      id: "fecha",
      name: "Fecha",
    },
    {
      id: "hora",
      name: "Hora",
    },
    {
      id: "recibo",
      name: "Recibo",
    },
    {
      id: "descripcion",
      name: "Descripcion",
    },
    {
      id: "cargo",
      name: "Cargo",
      format: (value: any) => convertMoneyToNumber(value).toString(),
    },
    {
      id: "abono",
      name: "Abono",
      format: (value: any) => convertMoneyToNumber(value).toString(),
    },
  ]

  const getTypeTransaccion = (value:number) => value > 0 ? "egresos" : "ingresos"

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns: TableColumn<ExtendedDataRow>[] = [ 
    {
        id: "transaccion",
        name: "Transaccion",
        cell: (row: any) => {
            const { cargo } = row // abono
            const transaccion = getTypeTransaccion(convertMoneyToNumber(cargo)) // convertMoneyToNumber(cargo) > 0 ?  "ingresos" : "egresos"
            const colorStyle: ButtonProps["color"] = getColorTransaccion(transaccion)
            
            return (<MDBBtn rounded style={{ padding: "5px 10px", fontSize: "11px" }} color={colorStyle}>{transaccion}</MDBBtn> )
        },
        sortable: true,
    },
    {
        id: "descripcion",
        name: "Descripcion",
        width: "250px",
        cell: (row) => {
            const { descripcion } = row
            return ( 

              <StyledToolTip>
                <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title={descripcion} placement='bottom'>
                  {descripcion}
                </MDBTooltip>
              </StyledToolTip>

            )
        },
        sortable: true,
    },
    {
        id: "fecha",
        name: "Fecha",
        selector: (row: { fecha: any; }) => row.fecha,
        sortable: true
    },
    {
        id: "monto",
        name: "Monto",
        cell: (row: any) => {
          const {cargo, abono} = row
          const cargoNumber = convertMoneyToNumber(cargo)
          const abonoNumber = convertMoneyToNumber(abono)
          const monto = cargoNumber > 0 ? cargoNumber : abonoNumber
          return <NumericFormat displayType="text" value={monto} prefix="$" thousandSeparator="," decimalScale={2}  /> //formatMoney(monto)
        },
        sortable: true
    },
    // {
    //     id: "comentario",
    //     name: "Comentario",
    //     selector: (row: { comentario: any; }) => row.comentario,
    //     sortable: true
    // },
    {
      id: "categoria",
      name: "Categoria",
      cell: (row: any) => {
        const {categoria = "", cargo, isTraspaso } = row
        const transaccion = getTypeTransaccion(convertMoneyToNumber(cargo)) // convertMoneyToNumber(cargo) > 0 ?  "ingresos" : "egresos"
        // const colorStyle: ButtonProps["color"] = getColorTransaccion(transaccion)
        const catOptions = transaccion === "ingresos" ? optionsCategoriasIngresos : optionsCategoriasEgresos
        return (
          <div style={{width: "160px"}}>
            <SelectCustom options={catOptions} onChangeSelect={(data: any) => onChangeSelect(data, row) } name='categoria' hiddeLabel={true} defaultValue={categoria} required isDisabled={!!isTraspaso} /> 
          </div> 
        )
      },
      sortable: false,
    },
    {
      id: "subcategoria",
      name: "SubCategoria",
      cell: (row: any) => {
        const {transaccion, categoria = "", subcategoria = "", isTraspaso } = row
        const catOptions = transaccion === "ingresos" ? optionsCategoriasIngresos : optionsCategoriasEgresos

        const cat = catOptions.find(({ value }) => value === categoria )
        
        return (
          <div style={{width: "160px"}}>
            <SelectCustom options={cat?.subcategorias || []} onChangeSelect={(data: any) => onChangeSelect(data, row) } name='subcategoria' hiddeLabel={true} defaultValue={subcategoria} required isDisabled={!!isTraspaso} /> 
          </div> 
        )
      },
      sortable: false,
    },
    {
      id: "proyecto",
      name: "Proyecto", 
      width: "340px",
      cell: (row: any) => {
        const { proyecto = "", rubro = "", subcategoria, cargo, isTraspaso } = row
        const transaccion = getTypeTransaccion(convertMoneyToNumber(cargo)) // convertMoneyToNumber(cargo) > 0 ?  "ingresos" : "egresos"
        let rubrosOptions = [];
        
        if (proyecto && transaccion === "egresos") {
          const proyectoInfo = getCurrentProyecto(proyecto)
          rubrosOptions = getRubrosOptions(proyectoInfo)
        }
        
        return (
          subcategoria === "1"
          ? <div className="d-flex flex-row">
              <SelectCustom options={proyectosGanadosOptions || []} onChangeSelect={(data: any) => onChangeSelect(data, row) } name='proyecto' hiddeLabel={true} defaultValue={proyecto} customMoreStyles={{width: "160px"}} required isDisabled={!!isTraspaso} /> 
              { rubrosOptions.length 
                ? <SelectCustom options={rubrosOptions || []} onChangeSelect={(data: any) => onChangeSelect(data, row) } name='rubro' hiddeLabel={true} defaultValue={rubro} customMoreStyles={{width: "160px", marginLeft: "5px"}} required isDisabled={!!isTraspaso} />  
                : <></> }
            </div>
          : <></>
        )
      },
    },
    {
      id: "traspaso",
      name: "Traspaso-Banco", 
      width: "200px",
      cell: (row: any) => {
        const { bancoTraspaso, isTraspaso } = row

        return (
          <>
            <SelectCustom options={optionsBancos} onChangeSelect={(data: any) => onChangeSelect(data, row)} name='bancoTraspaso' defaultValue={bancoTraspaso} hiddeLabel={true} customMoreStyles={{width: "160px", marginRight: "8px" }} required isDisabled={!isTraspaso} />
            <MDBCheckbox name='isTraspaso' value={isTraspaso ? "on": "off"} checked={!!isTraspaso} onChange={(data: any) => handleChangeCheck(data, row) } />
          </>
        )
      }
    }
  ];
  const actionsDataTable = (data: any) => {
    return (
      <div className="w-100 d-flex flex-row align-items-center justify-content-end" >

        {alert && data.id === alert.index && 
          <MDBTooltip tag='a' wrapperProps={{ href: '#' }} title="Hay datos pendientes por seleccionar">
            <MDBIcon fas icon='exclamation-circle' size='sm' className="me-2" style={{color: "red"}} />
          </MDBTooltip>}  
  
        <MDBBtn tag='a' color='none' style={{ color: '#3b5998' }} onClick={() => {}} >
          <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </div>
    )
  }
  const columnAction = [
    {
        name: "",
        width: "60px",
        button: true,
        cell: (row: any) => actionsDataTable(row),
        right: true,
    }
  ]
    
  const importFromClipboard = useCallback(
    async (e: { clipboardData: { getData: (arg0: string) => any; }; }) => {
      setLoading(true)
      setErrorImportData(false);
      let pastedItems

      if (e.clipboardData?.getData) {
        pastedItems = e.clipboardData.getData("text")
      } else if (window.navigator.clipboard?.readText) {
        pastedItems = await window.navigator.clipboard.readText()
      }

      const formattedEntities = format(pastedItems)
      const validate = allPass([ ...validateArray]) //  length,

      if (validate(formattedEntities)) {
          const newArrayData = convertToArrayObject(formattedEntities, columnsCopy)
          
          setArrayData(newArrayData)
      } else {
          console.log("ERROR!!")
          setErrorImportData(true);
      }
      setLoading(false)
    },
    [columnsCopy, setLoading],
  )

  const handleSaveData = async() => {
    setAlert({title: "Espere", message: "Guardando datos...", type: "success" })
    setSaving(true)

    let hasError = null;
    let payload = []

    for (let i = 0; i < arrayData.length; i++) {
      const items = arrayData[i]
      const { cargo, abono, descripcion, fecha, categoria, categoriaLabel, subcategoria, subcategoriaLabel, proyecto, proyectoLabel, rubro, rubroLabel, isTraspaso } = items
      const cargoNumber = convertMoneyToNumber(cargo)
      const abonoNumber = convertMoneyToNumber(abono)
      const monto = cargoNumber > 0 ? cargoNumber : abonoNumber
      const transaccion = getTypeTransaccion(cargoNumber) // cargoNumber > 0 ? "ingresos" : "egresos"
      let extra = {}
      let infoPayload;

      if (!isTraspaso) {
        if (!categoria || !subcategoria ) {
          hasError = i;
          // setAlert({index: i, title: "Error", message: "Hay datos requeridos pendientes", type: "danger" })
          // setSaving(false)
          break;
        }

        if (subcategoria === "1") {
          if(!proyecto || (transaccion === "egresos" && !rubro) ) {
            hasError = i;
            // setAlert({ index: i, title: "Error", message: "Hay dados requeridos pendientes", type: "danger" })
            // setSaving(false)
            break;
          }

          extra = { proyecto, proyectoLabel, ...( transaccion === "egresos" ? {rubro, rubroLabel} : {} ) }
        }

        const formValue = {
          banco: banco.value,
          bancoLabel: banco.label,
          categoria,
          categoriaLabel,
          comentario: "",
          descripcion,
          fecha: moment(fecha, "DD-MMM-YYYY").valueOf() / 1000,
          monto,
          subcategoria,
          subcategoriaLabel,
          transaccion,
        }

        infoPayload = {
          type: transaccion, // ingresos | egresos
          name: banco.value,
          uidMedio: subcategoria,
          uidCategoria: categoria,
          detalles: { ...formValue, ...extra },
        }
      } else {
        const { bancoTraspaso, bancoTraspasoLabel } = items
        if(!bancoTraspaso) {
          setAlert({ index: i, title: "Error", message: "Hay dados requeridos pendientes", type: "danger" })
          setSaving(false)
          break;
        }
        const bancoObj = (transaccion === "ingresos")
          ? {
            banco: banco.value,
            bancoLabel: banco.label,
            bancoOrigen: bancoTraspaso,
            bancoOrigenLabel: bancoTraspasoLabel,
          }
          : {
            banco: bancoTraspaso,
            bancoLabel: bancoTraspasoLabel,
            bancoOrigen: banco.value,
            bancoOrigenLabel: banco.label,
          };
        const formValue = {
          ...bancoObj,
          comentario: "",
          descripcion,
          fecha: moment(fecha, "DD-MMM-YYYY").valueOf() / 1000,
          monto,
          transaccion: "transferencia"
        }
        infoPayload = {
          type: "transferencia", // ingresos | egresos
          name: bancoObj.banco, // banco.value,
          nameOrigen: bancoObj.bancoOrigen,
          detalles: { ...formValue },
        }
      }

      if (!isTraspaso && categoria === "1") {
        const response = await transaccionSave(infoPayload)

        if (transaccion === "ingresos") await updateProyectoIngreso(response?.uid || "", infoPayload.detalles.monto, items.proyectoData) 
        else await updateProyectoEgreso(response?.uid || "", rubro, infoPayload.detalles.monto, items.proyectoData)
      } else {
        payload.push(infoPayload)
      }
    }
    if (hasError === null) {
      console.log("payload > ", payload)
      if (payload.length) {
        // Save the array of requisicines
        await transaccionImportSave(payload)
      }
      handleSave();
      setShowModal(false);
    } else {
      setAlert({index: hasError, title: "Error", message: "Hay datos requeridos pendientes", type: "danger" })
      setSaving(false)
    }
  }

  useEffect(() => {
      const handlePasteAction = (e:any) => {
        importFromClipboard(e)
      }
  
      window.addEventListener("paste", handlePasteAction)
  
      return () => {
        window.removeEventListener("paste", handlePasteAction)
      }
  }, [importFromClipboard])

  const onChangeSelectBanco = (data: any) => {
    const {value, label} = data
    setBanco({value, label})
  }

  const onChangeSelect = (data: any, row: any) => {
    const {value, label, name} = data
    let proyectoData =  {}
    if (name === "proyecto") {
      proyectoData = {proyectoData: getCurrentProyecto(value)}
    }
    const newRow = { ...row, ...proyectoData, [name]: value, [`${name}Label`]: label }
    let newArray = [...arrayData] 
    if(newArray.length > 0) newArray[row.id] = newRow

    setArrayData(newArray)
  }
  const handleChangeCheck = (data: any, row: any) => {
    const { name, value } = data.target;
    const newRow = { ...row, [name]: !(value === "on") }
    let newArray = [...arrayData];

    if(newArray.length > 0) newArray[row.id] = newRow;

    setArrayData(newArray)
  }
  const ImportData = () => {
    return (
      <>
        <p className="text-start">
            <h6>Copiar y Pegar 6 columnas el siguiente Orden: Fecha moviento, Hora, Recibo, Descripcion, Cargos y Abonos </h6>
            <h6> Example: 22-Jan-2023, 10:22:00, No-Recibo, Comision extra, 20/01/2024, $1005.00, 0</h6>
        </p>
        <StyledBoxCopyPaste className="square border w-100 d-inline-block">
            Pegar 6 columnas (CTRL/CMD+V)
        </StyledBoxCopyPaste>
      </>)
    }

    const DisplayData = () => {
        return <>
            <DataTableCustom keyDataTable="table-servicios" columns={[ ...columnAction, ...columns]} data={arrayData} />
        </>
    }

    const constentForm = () => {
      return (
        <>
          <div className="d-flex flex-row mb-3 align-items-center">
            <div className="p-2">Seleccionar Banco:</div>
            <div className="p-2" style={{width: "250px"}}>
              <SelectCustom options={optionsBancos} onChangeSelect={onChangeSelectBanco} name='banco' defaultValue={banco.value} hiddeLabel={true} required />
            </div>
          </div>
          
          { loading 
            ? <Loading />
            : arrayData.length 
              ? <DisplayData />
              : <ImportData />
          }

          {errorImportData && 
            <div className='w-100'>
              <AlertMessage 
                offAutoClose={true} 
                alert={{
                  type: "danger",
                  title: "Error", 
                  message: "Los datos a importar no cuenta con la estructura necesaria, es necesario revisarlos y volverlo a intentar",
                }} 
                setAlert={() => setErrorImportData(false) } />
            </div> }
        </>    
      )
    }

    const reviewSaveButtonStatus = () => {
      return !!banco.value && arrayData.length
    }

  return (
      <AddItemModal
        keyModal="modal-lineadenegocio"
        sizeModa="xl"
        alert={alert}
        typeAction={typeAction}
        showModal={showModal}
        setShowModal={setShowModal}
        handleSave={handleSaveData}
        disabledSaveButton={!reviewSaveButtonStatus()}
        saving={saving}
      >
        { constentForm() }
      </AddItemModal>
    )
}

export default AddImportarForm
