import * as Popover from '@radix-ui/react-popover';
// import { MixerHorizontalIcon, Cross2Icon } from '@radix-ui/react-icons';
import './styles.css';
import { MDBIcon } from 'mdb-react-ui-kit';

interface ToolTipCustomProps {
  // minWidth?: string;
  buttonContent?: React.ReactNode;
  tooltipContent?: React.ReactNode;
  // position?: 'top' | 'bottom' | 'left';
}

export default ({
  // minWidth,
  buttonContent,
  tooltipContent,
  // position = 'bottom',
}: ToolTipCustomProps) => {
    return (
<Popover.Root>
    <Popover.Trigger asChild>
    { buttonContent }
      {/* <button className="IconButton" aria-label="Update dimensions">
       OPEN_HERE!!
      </button> */}
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content className="PopoverContent" sideOffset={5}>
        { tooltipContent }
        <Popover.Close className="PopoverClose" aria-label="Close">
        <MDBIcon fas icon='close' size='sm' />
        </Popover.Close>
        <Popover.Arrow className="PopoverArrow" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
    )
}
/**
 <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <p className="Text" style={{ marginBottom: 10 }}>
            Dimensions
          </p>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="width">
              Width
            </label>
            <input className="Input" id="width" defaultValue="100%" />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="maxWidth">
              Max. width
            </label>
            <input className="Input" id="maxWidth" defaultValue="300px" />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="height">
              Height
            </label>
            <input className="Input" id="height" defaultValue="25px" />
          </fieldset>
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="maxHeight">
              Max. height
            </label>
            <input className="Input" id="maxHeight" defaultValue="none" />
          </fieldset>
        </div>
 */