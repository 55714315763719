import styled from '@emotion/styled';
import { NumericFormat } from 'react-number-format';

interface props {
    valid: boolean;
    required: boolean;
    disabled: boolean;
}

const StyleContainer = styled.div<props>`
  position: relative;
  
  ${({valid, required, disabled}) => `
    & > div > div {
      border-color: ${ valid || !required ? 'green' : '#dc4c64' } !important;
      min-height: 35.5px;
    }
    &> label {
      color: ${ valid || !required ? 'green' : '#dc4c64' } !important;
      font-size: smaller;

      ${ disabled && `
        color: rgba(0, 0, 0, 0.6) !important;
        transition: all .2s linear;
        height: 11px;
      ` }
    }
    & > div > div > div {
      padding: 0px !important; 
    }
  `}
`;
const StyledNumericFormat = styled (NumericFormat)<{empty: boolean}>`
  width: 100%;
  min-height: auto;
  padding: .33em .75em;
  border-radius: .25rem;
  border: thin black solid;
  border-color: ${props => (props.empty ? 'red' : '#14a44d')}; 
  outline: none;

  :focus {
    border-color: ${props => (props.empty ? 'red' : '#14a44d')}; 
    border-width: 2px; /* Aumenta el grosor del borde cuando está enfocado */
  }

  ${props => props.disabled && `
    background-color: #e9ecef;
    border: 1px solid #bdbdbd;
    color: #4f4f4f;
  `}
`;
const StyledContainerLabel = styled.label`
  position: absolute;
  left: 10px;
  top: -10px;
  background-color: white;
  size: 10px;
  padding: 0 5px;
`;

interface IProps {
  name: string;
  value: any;
  required?: boolean;
  handleOnBlur?: (e: any) => void;
  handleOnChange?: (e: any, name: string) => void;
  hiddeLabel?: boolean; 
  label?: string;
  customMoreStyles?: any;
  disabled?: boolean;
}

const NumericFormatCustom = ({ name, value, required=false, handleOnBlur, handleOnChange, hiddeLabel=false, label, customMoreStyles = {}, disabled = false }: IProps) => {
    return (
      <StyleContainer valid={!!value} required={required} style={customMoreStyles} disabled={disabled} >
        <StyledNumericFormat 
            name={name}
            required={required}
            value={value} 
            prefix="$"
            thousandSeparator=","
            decimalScale={2}
            onBlur={handleOnBlur}
            onValueChange={(value) => handleOnChange && handleOnChange(value, name) }
            empty={!value}
            disabled={disabled}
        />
        { !hiddeLabel && <StyledContainerLabel className="form-label text-capitalize" htmlFor="formControlLg">{ label || name }</StyledContainerLabel>}
      </StyleContainer>  
    )
}

export default NumericFormatCustom;
