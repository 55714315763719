import axios from "axios";

const awsURL = process.env.REACT_APP_AWS_URL || "" 
const apiKey = process.env.REACT_APP_X_API_KEY || "" 

const instanceAxios = axios.create({
  baseURL: awsURL,
  headers:  { "x-api-key": apiKey },
});


// instanceAxios.interceptors.response.use(
//   response => response,
//   error => {
//     console.log("error.response.status  > ", error )
//     if (error.response.status === 403) {
//       // window.location.href = '/';
//       console.log("Es 403!!")
//     }
//   });

export default instanceAxios;