import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/login';
import NavBar from './components/navBar';
import Sidebar from './components/sideBar';
import Cotizaciones from './components/cotizaciones';
import styled from '@emotion/styled';
import Cotizacion from './components/cotizaciones/Cotizacion';
import { useSettingsStore } from './store/settingsStore';
import Transacciones from './components/transacciones';
import Requisiciones from './components/requisiciones';
import { shallow } from 'zustand/shallow';
import Presupuestos from './components/presupuestos';

// const StyledContainer = styled.div`
//   display: flex;
//   height: 92vh;
//   overflow: 'scroll initial';
// `

// Estilo para el contenedor principal
const StyledContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px);
`;

// Estilo para el contenido principal
const ContentContainer = styled.div`
  flex: 1; /* Toma todo el espacio disponible */
  overflow-y: auto; /* Permite el desplazamiento vertical si es necesario */
  padding: 20px; /* Ajusta el espacio como desees */
`;

// function App() {
//   return (
//     <div className="App">
//       {<Login></Login> }
//       {/* */}
 
//     </div>
//   );
// }

const App =() => {
  const authServer = useSettingsStore((state) => (state.auth), shallow)
  const isLoggedIn = authServer?.jwt
  
  const {
    getMediosServer, 
    getCadenasServer, 
    getProveedoresServer, 
    getClientesServer, 
    getBancosServer, 
    getLineasNegocioServer,
    getCategoriasServer, 
    getUsersServer,
  } = useSettingsStore()

  useEffect(() => {
    if(!authServer?.jwt) {
      return
    }
    // iniciando datos settings
    getMediosServer()
    getCadenasServer()
    getProveedoresServer()
    getClientesServer()
    getBancosServer()
    getLineasNegocioServer()
    getCategoriasServer()
    getUsersServer()
  }, [authServer])

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          <NavBar />
          <Router>
            <StyledContainer>
              
              <Sidebar />
              <ContentContainer>
                <Routes>
                  <Route path="/" element={<div> </div>} />
                  <Route path="/transacciones" element={<Transacciones />} />
                  <Route path="/requisiciones" element={<Requisiciones />} />
                  <Route path="/presupuestos" element={<Presupuestos />} />
                  <Route path="/cotizacion/:option/:id?" element={<Cotizacion />} />
                  {/* <Route path="/transaccion/:option/:id?" element={<Transaccion />} /> */}
                  <Route path="/cotizaciones" element={<Cotizaciones />} />
                </Routes>
              </ContentContainer>
            </StyledContainer>
          </Router>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
