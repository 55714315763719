import { useEffect, useState } from 'react';
import { TableColumn } from "react-data-table-component";
// import styled from '@emotion/styled';
import { MDBBtn, MDBContainer, MDBIcon, MDBInput, MDBTextArea, MDBValidationItem } from 'mdb-react-ui-kit';
import { StyledContainerConcepto } from "../general/StylesUtils";
import { settings } from './api';
import { useSettingsStore } from '../../store/settingsStore';
import { shallow } from 'zustand/shallow';
import AddItemModal from './AddItemModal';
import DataTableCustom from '../general/DataTableCustom';
import DatosContactoDataTable from '../general/DatosContactoDataTable';
import Loading from '../general/loading';
import { validateRequiredData } from '../general/utils';
import ConfirmDeleteModal from './ConfirmDeleteModal';

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  typeAction: {typeName?: string, option?: string, data?: any }
  handleSave: any;
}

type DataRowContacto = {
  contacto: string;
  telefono: string;
  correo: string;
};

type DataRow = {
  name: string;
  noInterno: string;
};

// let idValue = 1;

const AddNewForm = ({showModal = false, setShowModal, typeAction, handleSave }: IProps) => {
  const [formValue, setFormValue] = useState({
    uid: null,
    name: '',
    noInterno: '',
    detalles: '',
    razonSocial: '',
    rfc: '',
    cfdi: '',
    numeroCuenta: '',
    banco: '',
    contactos: [],
  });
  const [, setRequiredStatus] = useState({
    name: false,
    noInterno: false,
  })

  const [isDatosFiscalesRequired, setIsDatosFiscalesRequired] = useState(false);
  const [isValidateFine, setIsValidateFine] = useState(false)
  const [saving, setSaving] = useState(false)
  const [alert, setAlert] = useState(null)

  const headers = [
    { label: 'Contacto', name: 'contacto', required: true},
    { label: 'Telefono', name: 'telefono', required: true},
    { label: 'Correo', name: 'correo'},
    { label: 'Puesto o Roll', name: 'puesto'},
  ];

  const columns: TableColumn<DataRowContacto>[] = [
      {
        name: "Contacto",
        selector: row => row.contacto,
        sortable: true
      },
      {
        name: "Telefono",
        selector: row => row.telefono,
        sortable: true
      },
      {
        name: "Correo",
        selector: row => row.correo,
        sortable: true
      },
  ];
  
  const showAlert = (data: any) => {
    setAlert(data)

    if (data?.type === "success") {
      setTimeout(() => {
        setAlert(null)
        setShowModal(!showModal)
        handleSave()
      },1500 )
    }
  }

  // const isValidate = (e: { target: any; }) => {
  //   const data = e.target
  //   const { name, value, required } = data

  //   if(!required) return
   
  //   setRequiredStatus((data: any) => {
  //     const isValue = Array.isArray(value) ? value.length  : value.trim()

  //     const newData = {
  //       ...data,
  //       [name]: !!isValue,
  //     }

  //     const validate = Object.values(newData).find((value) => !value )

  //     setIsValidateFine(validate === undefined)
  //     return newData
  //   })
  // }

  useEffect(() => {
    if (!typeAction?.data) {
      return;
    }

    setFormValue({...typeAction.data})
    setIsValidateFine(true)
    setRequiredStatus({
      name: true,
      noInterno: true,
    })
  }, [typeAction.data])

  const handleSaveData = async() => {
    try {
      setSaving(true)
      const { uid, name } = formValue

      const payload = {
        type: "cliente",
        name,
        ...{ uid },
        detalles: { ...formValue },
      }

      await settings(payload)

      showAlert({
        title: "Mensaje", message: "Datos Guardados...", type: "success",
      })
    } catch (e) {
      console.log("error > ", e)
      setSaving(false)
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    
    if(['razonSocial', 'rfc', 'cfdi'].includes(name)) setIsDatosFiscalesRequired(value)

    setFormValue({ ...formValue, [name]: value });
  };

  const onChangeSelect = (data: any) => {
    const {name, value} = data
    setFormValue({ ...formValue, [name]: value });
  }

  const isValidate = (e: any) => {
    validateRequiredData(e, setRequiredStatus, setIsValidateFine)
  }

  const constentForm = () => {
    const { name, noInterno, detalles, razonSocial, rfc, contactos } = formValue

    return (
      <StyledContainerConcepto className="row" style={{padding: "20px"}} isValidated>
        <MDBInput label={`Nombre del ${typeAction.typeName}`} type='text' name='name' value={name} onChange={onChange} onBlur={isValidate} required />

        <MDBInput label="No Interno como Proveedor" type='text' name='noInterno' value={noInterno} onChange={onChange} onBlur={isValidate} required />
        
        <MDBTextArea label='Detalles del cliente' name='detalles' value={detalles} onChange={onChange} rows={4} />        

        <h5>Datos de Contacto</h5>
        <DatosContactoDataTable columns={columns} headers={headers} dataDefault={contactos} handleSaveContactos={onChangeSelect} />

        <h5>Datos de Facturacion</h5>
        <MDBInput label="Razon Social" type='text' name='razonSocial' value={razonSocial} onChange={onChange} { ...{required: isDatosFiscalesRequired } } />
        <MDBInput label="RFC" type='text' name='rfc' value={rfc} onChange={onChange}  { ...{required: isDatosFiscalesRequired } } />
        <MDBValidationItem feedback='Adjuntar archivo CFDI' { ...{invalid: isDatosFiscalesRequired } } >
          <input type='file' name='cfdi' className='form-control' onChange={onChange} { ...{required: isDatosFiscalesRequired } } title='Archivo CFDI' />
        </MDBValidationItem>

      </StyledContainerConcepto>
    )
  }

  return (
    <AddItemModal 
      keyModal="modal-cliente"
      alert={alert}
      typeAction={typeAction}
      showModal={showModal}
      setShowModal={setShowModal}
      handleSave={handleSaveData}
      disabledSaveButton={!isValidateFine}
      saving={saving}
    >
      { constentForm() }
    </AddItemModal>
  )
}

const ClientesSettings = () => {
  const clientesServer = useSettingsStore((state) => (state.clientes), shallow)
  const {getClientesServer} = useSettingsStore()
  
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [typeAction, setTypeAction] = useState({})
  const [clientes, setClientes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    setClientes(clientesServer)
  }, [clientesServer])

  // const headers = [
  //   { label: 'Cliente', name: 'name'},
  //   { label: 'No Interno', name: 'noInterno'},
  // ];

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Cliente",
      selector: (row: { name: any; }) => row.name,
      sortable: true
    },
    {
      name: "No Interno",
      selector: (row: { noInterno: any; }) => row.noInterno,
      sortable: true
    },
    {
      name: "",
      button: true,
      cell: (row: any) => actionsDataTable(row),
      right: true
    }
  ] 

  const actionsDataTable = (data: any) => {
    return (
      <>
        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("cliente", "Edit", data)} >
        <MDBIcon fas icon='edit' size='sm' />
        </MDBBtn>

        <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#3b5998' }} onClick={() => handlItemAction("cliente", "Delete", data)} >
        <MDBIcon fas icon='trash' size='sm' />
        </MDBBtn>
      </>
    )
  }

  const handlItemAction = (typeName: string, option: string, data?: any) => {
    if (option === "Delete") {
      setShowDeleteModal(true);
    } else {
      setShowModal(true);
    }

    setTypeAction({ typeName, option, ...(data && { data }) })
  }

  const handleSave = async() => {
    setLoading(true)
    getClientesServer()
  }

  return (
    <MDBContainer>
      <h4> Clientes </h4>
      { (loading) ? 
          <Loading />
          :
          <>
            <div className="d-flex justify-content-end" style={{ margin: "10px 35px" }}>
              <MDBBtn outline rounded className='mx-2' color='secondary' onClick={() => handlItemAction("cliente", "Nuevo")} >+ Agregar Cliente</MDBBtn>
            </div>
            
            <DataTableCustom keyDataTable="table-clientes" columns={columns} data={clientes} />
        </>
      }
      {showModal && <AddNewForm showModal={showModal} setShowModal={setShowModal} typeAction={typeAction} handleSave={handleSave} ></AddNewForm>}

      {showDeleteModal && <ConfirmDeleteModal keyModal="modal-proveedor-delete" showModal={showDeleteModal} setShowModal={setShowDeleteModal} typeAction={typeAction} handleSubmitDelete={handleSave} />}
    </MDBContainer>
  );
}

export default ClientesSettings;